import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { changePassword } from "../../store/actions/ProfileActions";
import { notify } from "../../store/actions/SystemActions";
import { useInput, useTrans } from "../../hooks";
import {has, get} from "lodash"
import { isOnline } from "../../helpers";
import { OfflineState } from "../../components/errors";

const ChangePassword = () => {
    const dispatch = useDispatch();
    const [validationErrors, setValidationErrors] = useState({})
    const isSystemOnline = useSelector(isOnline)
    const { value: newPassword, bind: bindNewPassword, setValue: setNewPassword } = useInput("");
    const { trans } = useTrans()

    const { value: confirmPassword, bind: bindConfirmPassword, setValue: setConfirmPassword } = useInput("");

    const resetForm = () => {
        setNewPassword("")
        setConfirmPassword("")
        setValidationErrors({})
    }
    const handleSubmit = event => {
        event.preventDefault();
        dispatch(
            changePassword(
                { password: newPassword, "confirm-password": confirmPassword },
                ({ message }) => {
                    resetForm()
                    dispatch(notify(message));
                },
                errors=>{
                    setValidationErrors(errors)
                }
            )
        );
    };

    if(!isSystemOnline){
        return <OfflineState />
    }
    return (
        <div>
            <div className="page-header-container">
                <h3 className="page-title">{trans("general.change_password")}</h3>
            </div>

            <div className="form-container-small">
                <form className="form" onSubmit={handleSubmit}>
                    <div className={`form-group  ${has(validationErrors, "password") && "has-error"}`}>
                        <label className="form-label required" htmlFor="new-password">
                        {trans("general.new_password")}:
                        </label>
                        <input
                            className="form-control"
                            type="password"
                            id="new-password"
                            name="new-password"
                            {...bindNewPassword}
                        />
                        {has(validationErrors, "password") && 
                            <div className="form-error">{get(validationErrors, "password")}</div>
                        }
                    </div>
                    <div className={`form-group  ${has(validationErrors, "confirm-password") && "has-error"}`}>
                        <label
                            className="form-label required"
                            htmlFor="confirm-password"
                        >
                            {trans("general.confirm_password")}:
                        </label>
                        <input
                            className="form-control"
                            type="password"
                            id="confirm-password"
                            name="confirm-password"
                            {...bindConfirmPassword}
                        />
                        {has(validationErrors, "confirm-password") && 
                            <div className="form-error">{get(validationErrors, "confirm-password")}</div>
                        }
                    </div>
                    <div className="form-actions">
                        <button
                            type="submit"
                            className="btn-outline btn-primary"
                        >
                            {trans("general.save")}
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default ChangePassword;
