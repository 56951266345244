import {
    get,
    has,
    isEmpty,
    omit,
} from "lodash"
import PropTypes           from "prop-types"
import React, { Fragment } from "react"
import { useTrans }        from "../../../hooks"
import NameWithHonorific   from "./partials/NameWithHonorific"

const FormFieldName = (props) => {
    const { isPreview, value, name, onChange, errors, locale } = props
    const inputProps = omit(props, ["isPreview", "value", "name", "onChange", "errors", "locale"])
    const { trans } = useTrans()

    if (!isEmpty(errors)) {console.error(errors)}

    return isPreview ? (
        <span className="form-input-view">
            {has(value, "name") && value.name && (
                <Fragment>
                    {has(value, "honorific") && get(value, "honorific") && trans(`general.honorifics.${get(value, "honorific")}`, {locale})}{" "}
                    {has(value, "name") && value.name}
                </Fragment>
            )}
        </span>
    ) : (
        <NameWithHonorific {...inputProps} value={value} name={name} onChange={onChange} errors={errors} locale={locale}/>
    )
}

FormFieldName.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldName
