import {
    get,
    has,
    isEmpty,
} from "lodash"
import React, {
    useEffect,
    useState,
} from "react"
import {
    useDispatch,
    useSelector,
} from "react-redux"
import {Link} from "react-router-dom"
import {ConfirmDialog} from "../../components/confirm-dialog"
import {Can} from "../../components/permissions"
import {ACTIONS} from "../../config/PermissionConfig"
import {auth} from "../../helpers"
import {
    useInput,
    useTrans,
} from "../../hooks"
import {notify} from "../../store/actions/SystemActions"
import {
    changePassword,
    deleteUser,
    fetchUserById,
} from "../../store/actions/UserActions"

import Icon from "./../../components/icon/Icon";

const UserDetail = ({userId, history}) => {
    const [user, setUser] = useState({})
    const [showPasswordChangeForm, setShowPasswordChangeForm] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})

    const currentUser = useSelector(auth.getCurrentUser)

    const {value: password, bind: bindPassword, setValue: setPassword} = useInput("")
    const dispatch = useDispatch()
    const {trans, numberTrans} = useTrans()

    useEffect(() => {
        dispatch(
            fetchUserById(userId, response => {
                setUser(response)
            }),
        )
    }, [dispatch, userId])

    const handlePasswordChange = event => {
        event.preventDefault()

        dispatch(
            changePassword(
                userId,
                password,
                ({message}) => {
                    dispatch(notify(message))
                    resetForm()
                },
                errors => {
                    setValidationErrors(errors)
                },
            ),
        )
    }

    const handleOnDelete = userId => {
        dispatch(
            deleteUser(userId, ({message}) => {
                dispatch(notify(message))
                history.push("/admin/users")
            }),
        )
    }

    const resetForm = () => {
        setShowPasswordChangeForm(false)
        setPassword("")
        setValidationErrors({})
    }

    return (
        <div>
            <div className="page-header-container">
                <h3 className="page-title">{trans("general.user_list")}</h3>
                <Link to="/admin/users/add" className="btn-outline btn-link">
                    + {trans("general.add_user")}
                </Link>
            </div>

            <div className="card card-user-detail">
                {!isEmpty(user) && (
                    <>
                        <div className="card-body">
                            <p className="user-info">
                                <span className="user-info-label">{trans("general.name")}: </span>
                                <span className="user-info-detail">{user.display_name}</span>
                            </p>
                            <p className="user-info">
                                <span className="user-info-label">{trans("general.user_name")}: </span>
                                <span className="user-info-detail">{user.username}</span>
                            </p>
                            <p className="user-info">
                                <span className="user-info-label">{trans("general.email")}: </span>
                                <span className="user-info-detail">{user.email}</span>
                            </p>
                            <p className="user-info">
                                <span className="user-info-label">{trans("general.role")}: </span>
                                <span className="user-info-detail">{trans(`general.roles.${user.role}`)}</span>
                            </p>
                            {user.municipality && (
                                <p className="user-info">
                                <span className="user-info-label">
                                    {trans("municipalities.administrative_division.municipality")}:{" "}
                                </span>
                                    <span className="user-info-detail">
                                    {trans(`municipalities.${user.municipality.name}`)}
                                </span>
                                </p>
                            )}
                            {user.ward && (
                                <p className="user-info">
                                    <span className="user-info-label">{trans("general.ward_no")}: </span>
                                    <span className="user-info-detail">{numberTrans(user.ward)}</span>
                                </p>
                            )}

                        </div>
                        <div className="user-actions">
                            <Link to={`/admin/users/${user.id}/edit`}
                                  className="form-edit btn-primary btn-outline">
                                <Icon icon="edit"/>
                                {trans("general.edit")}
                            </Link>
                            <Can action={ACTIONS.USERS.CHANGE_PASSWORD} condition={{user, currentUser}}>
                                <div className={`change-password ${showPasswordChangeForm && "show-password-form"}`}>
                                    <button
                                        onClick={() => setShowPasswordChangeForm(!showPasswordChangeForm)}
                                        className="btn-password btn-outline-primary"
                                    >
                                        <Icon icon="key"/>
                                        {trans("general.change_password")}
                                    </button>

                                    {showPasswordChangeForm && (
                                        <form onSubmit={handlePasswordChange}>
                                            <div
                                                className={`form-group  ${has(validationErrors, "password") &&
                                                "has-error"}`}
                                            >
                                                <label className="form-label required" htmlFor="password">
                                                    {trans("general.new_password")}:
                                                </label>
                                                <input
                                                    type="text"
                                                    id="password"
                                                    name="password"
                                                    className="form-control"
                                                    {...bindPassword}
                                                />

                                                {has(validationErrors, "password") && (
                                                    <div
                                                        className="form-error">{get(validationErrors, "password")}</div>
                                                )}
                                            </div>
                                            <div className="form-actions">
                                                <button type="submit" className="btn-outline btn-primary">
                                                    {trans("general.save")}
                                                </button>

                                                <p className="btn-link btn-cancel" onClick={resetForm}>
                                                    {trans("general.cancel")}
                                                </p>
                                            </div>
                                        </form>
                                    )}
                                </div>
                            </Can>
                            <Can action={ACTIONS.USERS.DELETE} condition={{user, currentUser}}>
                                <ConfirmDialog
                                    confirmText="Are you sure? You can't undo once confirmed."
                                    onConfirm={() => {
                                        handleOnDelete(user.id)
                                    }}
                                >
                                    <button
                                        className="user-delete btn-outline btn-danger">
                                       <Icon icon="delete"/>
                                        {trans("general.delete")}</button>
                                </ConfirmDialog>
                            </Can>
                        </div>
                    </>
                )}
            </div>
        </div>
    )
}

export default UserDetail
