import {
    apiCall,
    call,
    dbCall,
    log,
}                              from "../../helpers"
import LayoutDBService   from "../../services/layout/LayoutDBService"
import LayoutService from "../../services/layout/LayoutService"
// import { setWardProfile }      from "../reducers/municipality/action"


export const fetchLayoutFromApi = (municipality, ward, onSuccess, onError) => dispatch => {
    if (!municipality || !ward) {
        call(onSuccess, {})
        return
    }

    dispatch(apiCall({
        onRequest: () => LayoutService.fetchLayout(municipality, ward),
        onSuccess: layout => {
            dispatch(setLayoutToDB(layout, () => {
                dispatch(fetchLayout(municipality, ward, onSuccess, onError))
            }))
        },
        onError: error => {
            call(onError, error)
        },
    }))
}

export const setLayoutToApi = (municipality, ward, layout, onSuccess, onError) => dispatch => {
    dispatch(apiCall({
        onRequest: () => LayoutService.saveLayout(layout, { municipality, ward }),
        onSuccess, onError,
    }))
}

export const fetchLayoutFromDB = (municipality, ward, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => LayoutDBService.getBothLayout(municipality, ward),
        onSuccess,
        onError: error => {
            dispatch(log(`Layout fetching from database failed for ${municipality} ${ward}`, error))
            call(onError, error)
        },
    }))
}

export const setLayoutToDB = (layout, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => LayoutDBService.setBothLayout(layout),
        onSuccess, onError,
    }))
}

export const fetchLayout = (municipality, ward, onSuccess, onError) => dispatch => {
    dispatch(fetchLayoutFromDB(municipality, ward, layout => {
        call(onSuccess, layout)
    }))
}
