import React          from "react"
import { electron }   from "../../helpers"
import ElectronRouter from "./ElectronRouter"
import Routes         from "./Routes"
import WebRouter      from "./WebRouter"

const Router = () => {
    const isElectronApp = electron.isElectronApp()

    const RouterComponent = isElectronApp ? ElectronRouter : WebRouter

    return (
        <RouterComponent>
            <Routes/>
        </RouterComponent>
    )
}

export default Router
