import React, { Fragment } from "react"
import { useTrans }        from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldSelect,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                          from "../../fields"
import defaultFormData     from "./formData"

const OrganizationRenew = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    const FormFieldPosition = props => {
        const { value, name, isPreview, onChange, errors } = props
        const options = {
            president: trans("general.president"),
            treasurer: trans("general.treasurer"),
            secretary_general: trans("general.secretary_general"),
            secretary: trans("general.secretary"),
            executive_director: trans("general.executive_director"),
            member: trans("general.member"),
        }

        return (
            <Fragment>
                <FormFieldSelect
                    value={value}
                    name={name}
                    isPreview={isPreview}
                    onChange={onChange}
                    options={options}
                    errors={errors}
                />
            </Fragment>
        )
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="सिफारिस गरिएको सम्बन्धमा ।"
                errors={errors}
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा श्री{" "}
                    <FormFieldText
                        value={formData.organization_name}
                        name="organization_name"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.organization_name")}
                    />{" "}
                    {trans(`municipalities.${staticData.municipality.name}`)}{" "}
                    वडा नं{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    {trans(
                        `municipalities.city.${staticData.municipality.city}`,
                    )}
                    ,{" "}
                    {trans(
                        `municipalities.district.${staticData.municipality.district}`,
                    )}
                    का{" "}
                    <FormFieldPosition
                        value={formData.position}
                        name={"position"}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />{" "}
                    ले मिति{" "}
                    <FormFieldDate
                        value={formData.date}
                        name="date"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    गते दिनु भएको निवेदन अनुसार उक्त संस्थालाई नविकरण गर्नका
                    लागि सम्बन्धित निकायमा सिफारिस गरी पाउँ भनी निवेदनमा
                    उल्लेखित भई आएकोले सो संस्थालाई नियमानुसार नविकरणका लागि
                    सिफारिस गरिएको व्यहोरा अनुरोध गरिन्छ ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                errors={errors}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default OrganizationRenew
