import * as types from "./type"

const initialState = {
    currentMunicipality: null,
    currentWard: null,
    wardProfile: {},
    municipalities: [],
}

const MunicipalityReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_CURRENT_MUNICIPALITY:
            return { ...state, currentMunicipality: payload }

        case types.SET_CURRENT_WARD:
            return { ...state, currentWard: payload }

        case types.SET_WARD_PROFILE:
            return { ...state, wardProfile: payload }

        case types.SET_MUNICIPALITY:
            return { ...state, municipalities: payload }

        default:
            return state
    }
}

export default MunicipalityReducer
