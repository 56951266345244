export default {
    header: {},
    salutation: {},
    footer: {},

    municipality: {},
    ward: "",
    previous_address: {},
    previous_ward: "",
    applicant: {},
    kitta: "",
    establishment_date: "",
    property_status: "",
    electricity_connection_amp: "",
    electricity_connection_category: "",
    propertyList: [{}],
    obligation: "",
}
