import jwtDecode          from "jwt-decode"
import {
    apiCall,
    auth,
    call,
    dbCall,
    isOnline,
    isTokenExpired,
    log,
}                         from "../../helpers"
import {
    AuthDBService,
    AuthService,
}                         from "../../services/auth"
import { UsersDBService } from "../../services/users"
import {
    clearLoginValidationErrors,
    loginStart,
    loginStop,
    setCurrentUser,
    setLoginValidationErrors,
    setToken,
}                         from "../reducers/auth/action"
import {
    setCurrentMunicipality,
    setCurrentWard,
}                         from "../reducers/municipality/action"
import { systemReboot }   from "../reducers/system/action"
import { notify }         from "./SystemActions"

export const loginFromLocalStorage = onSuccess => async (dispatch, getState) => {
    const token = await auth.getToken()

    if (token && !isTokenExpired(token)) {
        if (isOnline(getState())) {
            return dispatch(fetchCurrentUserFromApi(token, onSuccess))
        }

        return dispatch(fetchCurrentUserFromDB(token, onSuccess))
    }

    return call(onSuccess)
}

export const fetchCurrentUserFromApi = (token, onSuccess) => dispatch => {
    dispatch(apiCall({
        onRequest: () => AuthService.fetchCurrentUser(),

        onSuccess: user => {
            dispatch(setCurrentUser(user))
            dispatch(setToken(token))
            dispatch(setCurrentMunicipality(user.municipality))
            dispatch(setCurrentWard(user.ward))
            dispatch(saveCurrentUserToDB(user))

            call(onSuccess)
        },

        onError: err => {
            dispatch(logout(onSuccess))
        },
    }))
}

export const fetchCurrentUserFromDB = (token, onSuccess) => dispatch => {
    dispatch(dbCall({
        onRequest: () => UsersDBService.getCurrentUser(),
        onSuccess: user => {
            const { id } = jwtDecode(token)

            if (id !== user.id) {
                dispatch(logout(onSuccess))
                return
            }

            dispatch(setCurrentUser(user))
            dispatch(setToken(token))
            dispatch(setCurrentMunicipality(user.municipality))
            dispatch(setCurrentWard(user.ward))

            call(onSuccess)
        },
        onError: error => {
            dispatch(log("Current user not found in DB.", error))
            dispatch(logout(onSuccess))
        },
    }))
}

export const saveCurrentUserToDB = (user, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => UsersDBService.setCurrentUser(user),
        onSuccess, onError,
    }))
}

export const removeCurrentUserFromDB = (onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => UsersDBService.removeCurrentUser(),
        onSuccess, onError,
    }))
}

/**
 * TODO: Handle rememberMe logic
 */
export const login = (credentials, onSuccess, onFailed) => (dispatch, getState) => {
    const isSystemOnline = isOnline(getState())

    const successHandler = async user => {
        dispatch(setCurrentUser(user))
        dispatch(setToken(user.token))
        dispatch(setCurrentMunicipality(user.municipality))
        dispatch(setCurrentWard(user.ward))

        await auth.setToken(user.token)
        await UsersDBService.setCurrentUser(user)

        dispatch(clearLoginValidationErrors())
        dispatch(loginStop())

        call(onSuccess)
    }

    const errorHandler = error => {
        dispatch(loginStop())

        if (!error.response) {
            dispatch(notify(error.message, "error"))
            return
        }

        const { status, data: { errors, message } } = error.response

        switch (status) {
            case 422:
                dispatch(setLoginValidationErrors(errors))
                return

            case 401:
                call(onFailed, message)
                return

            default:
                throw error
        }
    }

    dispatch(loginStart())
    dispatch(clearLoginValidationErrors())

    if (isSystemOnline) {
        dispatch(loginViaApi(credentials, successHandler, errorHandler))
        return
    }

    dispatch(loginViaDB(credentials, successHandler, (error) => {
        dispatch(loginStop())
        call(onFailed, error.message)
    }))
}

export const loginViaApi = (credentials, onSuccess, onFailed) => async dispatch => {
    const { username, password } = credentials

    dispatch(apiCall({
        onRequest: () => AuthService.login({ username, password }),
        onSuccess, onError: onFailed,
    }))
}

export const loginViaDB = (credentials, onSuccess, onFailed) => async dispatch => {
    dispatch(dbCall({
        onRequest: () => AuthDBService.login(credentials),
        onSuccess,
        onError: onFailed,
    }))
}

export const logout = callback => async dispatch => {
    await auth.clearToken()

    dispatch(removeCurrentUserFromDB())
    dispatch(setCurrentUser({}))
    dispatch(setToken(null))
    dispatch(systemReboot())

    callback()
}
