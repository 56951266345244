import React, {useContext} from "react"
import {
    useDispatch,
    useSelector,
} from "react-redux"
import {auth} from "../../helpers"
import {sentenceCase} from "../../helpers/StringHelper"
import {notify} from "../../store/actions/SystemActions"
import {FabricContext} from "./../../context/FabricContext"
import LayoutDBService from "../../services/layout/LayoutDBService"

const FabricExport = ({layoutType}) => {
    const municipality = useSelector(auth.getCurrentMunicipality)
    const ward = useSelector(auth.getCurrentWard)
    const {canvas} = useContext(FabricContext)
    const dispatch = useDispatch()

    const handleSave = async () => {
        if (!canvas) {
            return null
        }
        const json = canvas.toJSON(["width", "height", 'selectable'])
        let dataURL = canvas.toDataURL({
            format: "png",
            left: 0,
            top: 0,
        })

        await LayoutDBService.setLayout(
            layoutType || "header",
            {json, dataURL, ward, municipality: municipality.name},
        )
        dispatch(notify((`${sentenceCase(layoutType) || ""} layout is saved.`)))
    }

    return (
        <div>
            <button className="btn-primary" onMouseUp={handleSave}>Save Layout</button>
        </div>
    )
}

export default FabricExport
