import { get }             from "lodash"
import React, {
    Fragment,
    useCallback,
    useLayoutEffect,
    useState,
}                          from "react"
import { useDispatch }     from "react-redux"
import { SifarishForms }   from "../../components/sifarish-forms"
import { apiCall }         from "../../helpers"
import { prepareFormData } from "../../helpers/"
import { useTrans }        from "../../hooks"
import { SifarishService } from "../../services/sifarish"
import { notify }          from "../../store/actions/SystemActions"
import CitizenInfoForm     from "./partials/CitizenInfoForm"

export const PublicSifarishFormContext = React.createContext()

const SifarishFormForPublic = ({ municipality, ward, wardProfile, formName, history }) => {
    const [isPreview, setIsPreview] = useState(false)
    const [formData, setFormData] = useState({})
    const [citizenData, setCitizenData] = useState({})
    const [validationErrors, setValidationErrors] = useState({})
    const { trans } = useTrans()
    const dispatch = useDispatch()

    const handleOnSubmit = useCallback((event) => {
        event.preventDefault()

        const data = {
            "municipality_name": municipality.name,
            "ward": ward,
            "form_name": formName,
            "form_data": formData,
            "citizen": citizenData,
        }

        dispatch(apiCall({
            onRequest: () => SifarishService.submitPublicSifarishForm(prepareFormData(data)),
            onSuccess: (response) => {
                dispatch(notify("Form successfully submitted."))

                const { municipality: { name: municipalityName }, ward: sifarishWard, form_name, token } = response

                window.location.href = `/public/sifarish/${municipalityName}/${sifarishWard}/${form_name}/success/${token}`
                // return history.push(
                //     `/public/sifarish/${municipalityName}/${sifarishWard}/${form_name}/success/${token}`)
            },
            onError: (errors) => {
                if (errors.response && errors.response.status === 422) {
                    setIsPreview(false)
                    setValidationErrors(errors.response.data.errors)
                }
            },
        }))
    }, [citizenData, dispatch, formData, formName, history, municipality.name, ward])

    const handleOnSifarishFormChange = useCallback((data) => {
        setFormData(data)
    }, [])

    const handleOnCitizenFormChange = useCallback((data) => {
        setCitizenData(data)
    }, [])

    useLayoutEffect(() => {
        const header = document.querySelector(".nav")
        const stickyElement = document.querySelector(".sub-header")
        const doc = document.documentElement
        window.onscroll = () => {
            const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
            if (top > header.clientHeight) {
                stickyElement.classList.add("sticky")
            } else {
                stickyElement.classList.remove("sticky")
            }
        }
    }, [])

    return (
        <Fragment>
            <div className="sub-header sifarish-header">
                <div className="container" style={{ display: "flex" }}>
                    {isPreview ? (
                        <Fragment>
                            <div className="" style={{ marginLeft: "auto" }}>
                                <button className="btn-outline btn-secondary btn-submit"
                                        onClick={() => setIsPreview(false)}>
                                    {trans("general.back_to_edit")}
                                </button>
                                <button className="btn-outline btn-success btn-submit"
                                        onClick={handleOnSubmit}>
                                    {trans("general.confirm")}
                                </button>
                            </div>
                        </Fragment>
                    ) : (
                        <button className="btn-outline btn-primary btn-submit"
                                style={{ marginLeft: "auto" }}
                                onClick={() => setIsPreview(true)}>
                            अगाडी बढ्नुहाेस्
                        </button>
                    )}
                </div>
            </div>

            <div>
                <p className="form-error">{get(validationErrors, "citizen")}</p>

                <PublicSifarishFormContext.Provider value={{ isPublic: true }}>
                    <SifarishForms
                        formName={formName}
                        staticData={{ municipality, ward, wardProfile }}
                        formData={formData}
                        isPreview={isPreview}
                        errors={validationErrors}
                        onChange={handleOnSifarishFormChange}/>
                </PublicSifarishFormContext.Provider>

                <CitizenInfoForm formData={citizenData}
                                 onChange={handleOnCitizenFormChange}
                                 errors={validationErrors}/>
            </div>
        </Fragment>
    )
}

export default SifarishFormForPublic
