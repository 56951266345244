export default class FormValidation {
    constructor(formData, formRules) {
        this.formData = formData
        this.formRules = formRules

        this.rules = {
            required: {
                handler: val => this.required(val),
                message: "The :attribute field is required.",
            },
            min: {
                handler: (val, options) => this.min(val, options),
                message: "The :attribute must be at least :min character long.",
                messageFormatter: (message, options) => message.replace(":min", options[0] || ""),
            },
        }
    }

    required(value) {
        return !this.isBlank(value)
    }

    isBlank(value) {
        return typeof value === "undefined" || value === null || this.regex(value, /^[\s]*$/)
    }

    regex(value, pattern) {
        return value.toString()
                    .match(pattern) !== null
    }

    min(value, options) {
        const minLength = Number(options[0] || 0)

        if (!isNaN(Number(value))) {
            return value >= minLength
        }

        return value.length >= minLength
    }

    validate() {
        const errors = Object.keys(this.formRules)
                             .reduce((error, fieldName) => {
                                 error[fieldName] = this.validateField({ fieldName, fieldValue: this.formData[fieldName] }, this.formRules[fieldName])
                                 return error
                             }, {})

        return this.prepareErrors(errors)
    }

    validateField({ fieldName, fieldValue }, ruleString) {
        const rules = ruleString.split("|")

        return rules.map(rawRule => {
                        const { rule, options } = this.getOptions(rawRule)
                        if (!this.rules.hasOwnProperty(rule)) {
                            throw new Error(`No such validation rule "${rule}" exists.`)
                        }

                        const ruleHandler = this.rules[rule]
                        const passed = ruleHandler.handler(fieldValue, options)
                        if (passed) {
                            return null
                        }

                        const message = this.prepareMessage(ruleHandler.message, fieldName)
                        return ruleHandler.hasOwnProperty("messageFormatter") ? ruleHandler.messageFormatter(message, options) : message
                    })
                    .filter(message => message)

    }

    getOptions(rule) {
        const formattedRule = rule.split(":")
        const options = (formattedRule[1] || "").split(",")

        return {
            rule: formattedRule[0],
            options,
        }
    }

    prepareMessage(messageStr, attribute) {
        return messageStr.replace(":attribute", attribute)
    }

    prepareErrors(errors) {
        return Object.keys(errors)
                     .reduce((formattedErrors, field) => {
                         if (errors[field].length > 0) {
                             formattedErrors[field] = errors[field][0]
                         }

                         return formattedErrors
                     }, {})
    }
}
