import * as types from "./type"

const initialState = {
    appInfo: {},
    isInstalled: false,
}

const ElectronReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_APP_INFO:
            return { ...state, appInfo: payload }

        case types.SET_APP_INSTALLED:
            return { ...state, isInstalled: payload }

        default:
            return state
    }
}

export default ElectronReducer
