import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldLocalGovernment,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const TemporaryResidenceForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="अस्थायी बसोबास सिफारिस बारे ।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                        errors={errors}
                    />{" "}
                    <FormFieldLocalGovernment
                        value={formData.address}
                        name="address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    वडा नं{" "}
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    <FormFieldPreviousAddress
                        errors={errors}
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    अन्तर्गत तल उल्लेखित स्थानमा रहेको घरधनि{" "}
                    <FormFieldName
                        value={formData.owner}
                        name="owner"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                        errors={errors}
                    />{" "}
                    को घरमा विगत मिति{" "}
                    <FormFieldDate
                        value={formData.date}
                        name="date"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    देखि अस्थायी बसोबास गदेै आउनु भएको व्यहोरा सिफारिस साथ
                    अनुरोध गरिन्छ ।
                </div>

                <div className="form-text-container">
                    बसोबास गर्नेको ना. प्र. प. नं.{" "}
                    <FormFieldText
                        value={formData.citizenship}
                        name="citizenship"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.citizenship_no")}
                        errors={errors}
                    />{" "}
                    जिल्ला -{" "}
                    <FormFieldText
                        value={formData.citizenshipDistrict}
                        name="citizenshipDistrict"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.district_name")}
                        errors={errors}
                    />{" "}
                    / जारी मितिः{" "}
                    <FormFieldDate
                        value={formData.citizenshipDate}
                        name="citizenshipDate"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                </div>

                <FormTable
                    title="बसोबास स्थान"
                    name="residenceDetail"
                    structure={[
                        {
                            name: "tole",
                            width: "25%",
                            label: "टोल",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "road_name",
                            width: "30%",
                            label: "बाटोको नाम",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "house_number",
                            width: "30%",
                            label: "घर नं.",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                    ]}
                    value={formData.residenceDetail}
                    isPreview={isPreview}
                    onChange={handleOnChange}
                    errors={errors}
                />
            </div>

            <FormFooter
                staticData={staticData}
                isPreview={isPreview}
                value={formData.footer}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default TemporaryResidenceForm
