import React, { Fragment } from "react"
import { useTrans }        from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldSelect,
    FormFieldText,
    FormFooter,
    FormHeader,
    FormTable,
}                          from "../../fields"
import defaultFormData     from "./formData"

const SeniorCitizenAllowance = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }
    const { numberTrans, trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    const FormFieldFiscalYear = props => {
        const { value, name, isPreview, onChange } = props

        const { trans } = useTrans()

        const fiscalYearCollection = ["2073-74", "2074-75", "2075-76"].reduce((collection, fiscalYear) => {
            collection[fiscalYear] = numberTrans(fiscalYear)

            return collection
        }, {})

        const handleOnChange = event => {
            onChange({ name, value: event.value })
        }

        return (
            <Fragment>
                {trans("general.fiscal_year")}{" "}
                <FormFieldSelect
                    name={name}
                    value={value}
                    isPreview={isPreview}
                    options={fiscalYearCollection}
                    onChange={handleOnChange}
                    errors={errors}
                />
            </Fragment>
        )
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="७० वर्ष उमेर पुगेको जेष्ठ नागरिक भत्ता सम्बन्धमा ।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा{" "}
                    <FormFieldFiscalYear
                        value={formData.fiscal_year}
                        name={"fiscal_year"}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    चैत्र मसान्त भित्र ७० वर्ष उमेर पुगेका तपशिल बमोजिमका{" "}
                    व्यक्ति/व्यक्तिहरु लाई{" "}
                    <FormFieldDate
                        value={formData.date}
                        name={"date"}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    गते देखि लागु हुने गरी मासिक उपचार खर्च सहित रु{" "}
                    <FormFieldText
                        value={formData.health_allowance}
                        name={"health_allowance"}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    ।– (
                    <FormFieldText
                        value={formData.allowance}
                        name={"allowance"}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />
                    )का दरले सामाजिक सुरक्षा भत्ता उपलब्ध गराई दिन हुन अनुरोध छ ।
                </div>

                <FormTable
                    title={trans("general.details")}
                    name="allowanceDetails"
                    structure={[
                        {
                            name: "name",
                            width: "20%",
                            label: "नाम,थर",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "address",
                            width: "20%",
                            label: "ठेगाना",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "bank_details",
                            width: "20%",
                            label: "बैँककाे नाम र शाखा",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "band_acc_no",
                            width: "20%",
                            label: "बैकं खाता नं",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "birth_date",
                            width: "20%",
                            label: "जन्म मिति",
                            component: FormFieldDate,
                            defaultValue: "",
                        },
                        {
                            name: "citizenship_no",
                            width: "20%",
                            label: "ना.प्र.नं",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "remarks",
                            width: "20%",
                            label: "कैफियत",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                    ]}
                    value={formData.allowanceDetails}
                    errors={errors}
                    isPreview={isPreview}
                    onChange={handleOnChange}
                />
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default SeniorCitizenAllowance
