import HttpService from "../HttpService"

class UsersService {
    fetchUserList = async (filter) => {
        const { body } = await HttpService.get("/users", filter)

        return body
    }

    create = async user => {
        const { body } = await HttpService.post("/users", user)

        return body.data
    }

    getById = async userId => {
        const { body } = await HttpService.get(`/users/${userId}`)

        return body.data
    }

    update = async (userId, user) => {
        const { body } = await HttpService.patch(`/users/${userId}`, user)

        return body.data
    }

    delete = async userId => {
        const { body } = await HttpService.delete(`/users/${userId}`)

        return body
    }

    changePassword = async (userId, password) => {
        const { body } = await HttpService.post(`/users/${userId}/change-password`, password)

        return body
    }
}

export default (new UsersService())
