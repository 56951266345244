import React, { useContext } from "react"
import {FabricContext} from "../../../context/FabricContext";
import Icon from "../../icon/Icon";

const FabricDelete = props => {
    const { canvas } = useContext(FabricContext)

    const removeObjects = () => {
        canvas.getActiveObjects().forEach((obj) => {
            canvas.remove(obj)
        })
        canvas.discardActiveObject().renderAll()
    }
    return (
            <button className={"btn-delete"} onClick={removeObjects}>
                <Icon icon="cross"/>
                Delete Selected
            </button>
    )
}

export default FabricDelete
