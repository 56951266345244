import { createAction }   from "redux-actions"
import { sifarishPolicy } from "../../../policies"
import * as types         from "./type"

export const setForms = createAction(types.SET_FORMS)
export const setCategories = createAction(types.SET_FORMS_CATEGORIES)

export const setSifarishList = (sifarish) => (dispatch, getState) => {
    dispatch({
        type: types.SET_SIFARISH_LIST,
        payload: sifarish.filter(sifarishItem => sifarishPolicy.view(getState(), sifarishItem)),
    })
}
