import { get }         from "lodash"
import React           from "react"
import {
    FormFieldText,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const NonProfitOrganizationRegisterForm = ({
                                               staticData,
                                               formData,
                                               onChange,
                                               isPreview,
                                               errors,
                                           }) => {
    formData = {
        ...defaultFormData,
        current_ward: get(staticData, "ward"),
        ...formData,
    }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="गैर नाफामुलक संस्था दर्ता प्रमाणपत्र ।"
                onChange={handleOnChange}
                errors={errors}
                salutation=" "
            />

            <div className="body-content">
                <div className="sifarish-info-lists">
                    <div className="body-top">
                        <div className="left">
                            <span
                                style={{
                                    display: "block",
                                    marginBottom: "8px",
                                }}
                            >
                                दर्ता नं.:
                            </span>
                            <span>दर्ता मिति: </span>
                        </div>
                    </div>
                    <div className="box-container">
                        <p>संस्थाको छाप वा फोटो</p>
                        <div className="box"></div>
                    </div>
                    <div>
                        <div>
                            <span className="sifarish-info-list-label">
                                १{")"} संस्थाको नाम:
                            </span>
                            <FormFieldText
                                value={formData.organization_name}
                                name="organization_name"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                ठेगाना:
                            </span>
                            <FormFieldText
                                value={formData.address}
                                name="address"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                स्वदेशी/ विदेशी:
                            </span>
                            <FormFieldText
                                value={formData.organization_region}
                                name="organization_region"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                विषयगत क्षेत्र​:
                            </span>
                            <FormFieldText
                                value={formData.thematic_area}
                                name="thematic_area"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                संस्थाको कारोवार शुरु भएको मिति:
                            </span>
                            <FormFieldText
                                value={formData.start_date}
                                name="start_date"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                ई-मेल:
                            </span>
                            <FormFieldText
                                value={formData.organization_email}
                                name="organization_email"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                सम्पर्क फोन नं:
                            </span>
                            <FormFieldText
                                value={formData.organization_phone}
                                name="organization_phone"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <div>
                            <span className="sifarish-info-list-label">
                                २{")"} संचालक/ अध्यक्ष/ मुख्य व्यक्तिको नाम, थर:
                            </span>
                            <FormFieldText
                                value={formData.owner_fullname}
                                name="owner_fullname"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                ठेगाना:
                            </span>
                            <FormFieldText
                                value={formData.owner_address}
                                name="owner_address"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                ई-मेल:
                            </span>
                            <FormFieldText
                                value={formData.owner_email}
                                name="owner_email"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                सम्पर्क फोन नं:
                            </span>
                            <FormFieldText
                                value={formData.owner_phone}
                                name="owner_phone"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                    </div>
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default NonProfitOrganizationRegisterForm
