import React from "react"

const SystemOffline = () => {
    const handleReload = () =>{
        window.location.reload()
    }
    return (
        <div className="error-installation">
            <div className="error-container">
                <span className="fail-icon"></span>
                <p>Installation failed.</p>
                <p>You need to be online for installation.</p>

                <button onClick={handleReload} className="btn-outline btn-primary reload">Retry</button>
            </div>
        </div>
    )
}

export default SystemOffline
