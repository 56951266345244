import { get }         from "lodash"
import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldKitta,
    FormFieldName,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const GharJaggaNaamsaariForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="सिफारिस गरिएको बारे ।"
                errors={errors}
                onChange={handleOnChange}/>

            <div className="body-content">
                <div className="form-text-container">
                    निवेदक{" "}
                    <FormFieldName value={formData.applicant} name="applicant" isPreview={isPreview} onChange={handleOnChange} placeholder={trans("general.name")} errors={errors}/>को{" "}
                    <FormFieldText value={formData.relationship} name="relationship" isPreview={isPreview} placeholder={trans("general.relationship")} onChange={handleOnChange} errors={errors}/>
                    नाता पर्ने <FormFieldName value={formData.relative} name="relative" isPreview={isPreview} onChange={handleOnChange} placeholder={trans("general.name")} errors={errors}/>को{" "}
                    मिति <FormFieldDate value={formData.deathDate} name="deathDate" isPreview={isPreview} onChange={handleOnChange} errors={errors}/>{" "}
                    मा मृत्यु भएको हुनाले निज मृतकका नाममा दर्ता कायम रहेको तल उल्लेखित विवरणको घरजग्गा नामसारीको
                    लागि {get(formData.applicant, "name") ? (<FormFieldName value={formData.applicant} name="applicant" isPreview={true} onChange={() => {}} errors={{}}/>) : "............"} ले
                    निवेदन दिनु भएकोमा मृतकका हकदारहरु नाता प्रमाणित प्रमाण पत्रमा उल्लेखित भए अनुसार रहेकोले निज मृतकका नाममा रहेको सो घर/जग्गा त्याहाँको नियमानुसार हकदारहरुको नाममा नामसारीको लागि सिफारिस साथ अनुरोध गरिन्छ ।
                </div>

                <FormTable title="मृतकका हकदारको विवरण"
                           name="nominees"
                           structure={[
                               { name: "nomineeName", width: "20%", label: "हकदारको नाम", component: FormFieldName, defaultValue: {} },
                               { name: "relation", width: "10%", label: "नाता", component: FormFieldText, defaultValue: "" },
                               { name: "fatherOrHusband", width: "20%", label: "बाबु / पतिको नाम", component: FormFieldText, defaultValue: "" },
                               { name: "citizenshipNo", width: "10%", label: "नगरिकता नं.", component: FormFieldText, defaultValue: "" },
                               { name: "houseNo", width: "10%", label: "घर नं.", component: FormFieldText, defaultValue: "" },
                               { name: "kittaNo", width: "15%", label: "कित्ता नं.", component: FormFieldKitta, defaultValue: "" },
                               { name: "roadName", width: "10%", label: "बाटोको नाम", component: FormFieldText, defaultValue: "" },
                           ]}
                           value={formData.nominees}
                           isPreview={isPreview}
                           errors={errors}
                           onChange={handleOnChange}/>

                <FormTable title="नामसारी गर्ने घर/जग्गा विवरण"
                           name="propertyDetail"
                           structure={[
                               { name: "ward", width: "10%", label: "वडा", component: FormFieldWard, defaultValue: "" },
                               { name: "seat_no", width: "10%", label: "सिट नं.", component: FormFieldText, defaultValue: "" },
                               { name: "kitta", width: "20%", label: "कित्ता", component: FormFieldText, defaultValue: "" },
                               { name: "area", width: "10%", label: "क्षेत्रफल", component: FormFieldText, defaultValue: "" },
                               { name: "houseNo", width: "10%", label: "घर नं.", component: FormFieldText, defaultValue: "" },
                               { name: "kittaNo", width: "15%", label: "कित्ता नं.", component: FormFieldKitta, defaultValue: "" },
                               { name: "roadName", width: "20%", label: "बाटोको नाम / बाटोको प्रकार", component: FormFieldText, defaultValue: "" },
                               { name: "remarks", width: "10%", label: "कैफियत", component: FormFieldText, defaultValue: "" },
                           ]}
                           value={formData.propertyDetail}
                           isPreview={isPreview}
                           errors={errors}
                           onChange={handleOnChange}/>
            </div>

            <FormFooter staticData={staticData} value={formData.footer} isPreview={isPreview} onChange={handleOnChange} errors={errors}/>
        </div>
    )
}

export default GharJaggaNaamsaariForm
