import React                    from "react"
import { HashRouter as Router } from "react-router-dom"

const ElectronRouter = ({ children }) => {
    return (
        <Router>
            {children}
        </Router>
    )
}

export default ElectronRouter
