import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldLocalGovernment,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const PermanentAddress = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { trans } = useTrans()
    formData = { ...defaultFormData, ...formData }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="स्थायी बसोबास सिफारिस ।"
                errors={errors}
                onChange={handleOnChange}/>

            <div className="body-content">
                <div className="form-text-container">
                    निवेदक{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        placeholder={trans("general.name")}/>ले{" "}
                    पेश गर्नुभएको निबेदनका आधारमा निज{" "}
                    <FormFieldName errors={errors} value={formData.applicant} name="applicant" isPreview={true} onChange={handleOnChange}/>,{" "}
                    <FormFieldLocalGovernment errors={errors} value={formData.address} name="address" isPreview={isPreview} onChange={handleOnChange}/>{" "}
                    वडा नं <FormFieldWard errors={errors} value={formData.ward} name="ward" isPreview={isPreview} onChange={handleOnChange}/>{" "}
                    <FormFieldPreviousAddress errors={errors} value={formData.previous_address} name="previous_address" isPreview={isPreview} onChange={handleOnChange}/>{" "}
                    अन्तर्गत तल उल्लेखित स्थानमा विगत मिति{" "}
                    <FormFieldDate errors={errors} value={formData.resident_date} name={"resident_date"} isPreview={isPreview} onChange={handleOnChange}/>{" "}
                    देखि स्थायी बसोबास गर्दै आउनु भएको व्यहोरा सिफरिस साथ अनुरोध गरिन्छ ।
                </div>
                <div className="form-text-container">
                    बसोबास गर्नेको ना. प्र. प. नं. <FormFieldText errors={errors} value={formData.citizenship} name="citizenship" isPreview={isPreview} onChange={handleOnChange} placeholder={trans("general.citizenship_no")}/>{" "}
                    जिल्ला - <FormFieldText errors={errors} value={formData.citizenshipDistrict} name="citizenshipDistrict" isPreview={isPreview} onChange={handleOnChange} placeholder={trans("general.district_name")}/> /{" "}
                    जारी मितिः <FormFieldDate errors={errors} value={formData.citizenshipDate} name="citizenshipDate" isPreview={isPreview} onChange={handleOnChange}/>{" "}
                </div>

                <FormTable title="बसोबास स्थान"
                           name="residencyDetail"
                           structure={[
                               { name: "tole", width: "25%", label: "टोल", component: FormFieldText, defaultValue: "" },
                               { name: "road_name", width: "30%", label: "बाटोको नाम", component: FormFieldText, defaultValue: "" },
                               { name: "house_number", width: "30%", label: "घर नं.", component: FormFieldText, defaultValue: "" },
                           ]}
                           value={formData.residencyDetail}
                           isPreview={isPreview}
                           errors={errors}
                           onChange={handleOnChange}/>

            </div>

            <FormFooter
                staticData={staticData}
                isPreview={isPreview}
                value={formData.footer}
                errors={errors}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default PermanentAddress
