import {
    apiCall,
    auth,
    call,
    dbCall,
}                             from "../../helpers"
import { AuthDBService }      from "../../services/auth"
import { ElectronApiService } from "../../services/electron"
import { StaticDataService }  from "../../services/static-data"
import { UsersService }       from "../../services/users"
import { API_REQUEST }        from "../reducers/system/type"

export const fetchWardUserListFromApi = ({ municipality, ward }, onSuccess, onError) => dispatch => {
    dispatch(apiCall({
        onRequest: () => ElectronApiService.fetchWardUsersWithCredential(municipality, ward),
        onSuccess: users => {
            dispatch(saveAuthUsersToDB(users, onSuccess, onError))
        },
        onError: error => {
            call(onError, error)
        },
    }))
}

export const saveAuthUsersToDB = (users, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => AuthDBService.saveAuthUsers(users),
        onSuccess, onError,
    }))
}

export const fetchAllUsers = (filter, onSuccess, onError = null) => dispatch => {
    dispatch({
        type: API_REQUEST,
        payload: {
            onRequest: () => UsersService.fetchUserList(filter),

            onSuccess: users => {
                onSuccess(users)
            },

            onError: error => {
                if (onError) {
                    onError(error)
                }
            },
        },
    })
}

export const fetchUserById = (userId, onSuccess, onError) => dispatch => {
    dispatch({
        type: API_REQUEST,
        payload: {
            onRequest: () => UsersService.getById(userId),
            onSuccess: user => {
                onSuccess(user)
            },
            onError: error => {
                if (onError) {
                    onError(error)
                }
            },
        },
    })
}

export const addUser = (userData, onSuccess, onError) => dispatch => {
    dispatch({
        type: API_REQUEST,
        payload: {
            onRequest: () => UsersService.create(userData),

            onSuccess: user => {
                onSuccess(user)
            },

            onError: error => {
                if (error.response.status === 422) {
                    onError(error.response.data.errors)
                }
            },
        },
    })
}

export const updateUser = (userId, userData, onSuccess, onError) => dispatch => {
    dispatch({
        type: API_REQUEST,
        payload: {
            onRequest: () => UsersService.update(userId, userData),
            onSuccess: user => {
                onSuccess(user)
            },
            onError: error => {
                if (error.response.status === 422) {
                    onError(error.response.data.errors)
                }
            },
        },
    })
}

export const deleteUser = (userId, onSuccess, onError = null) => dispatch => {
    dispatch({
        type: API_REQUEST,
        payload: {
            onRequest: () => UsersService.delete(userId),
            onSuccess: response => {
                onSuccess(response)
            },
            onError: error => {
                if (onError) {
                    onError(error)
                }
            },
        },
    })
}

export const changePassword = (userId, password, onSuccess, onError = null) => dispatch => {
    dispatch({
        type: API_REQUEST,
        payload: {
            onRequest: () => UsersService.changePassword(userId, { password }),
            onSuccess: response => {
                onSuccess(response)
            },
            onError: error => {
                if (onError) {
                    onError(error.response.data.errors)
                }
            },
        },
    })
}

export const fetchUserRoles = (onSuccess, onError = null) => (dispatch, getState) => {
    dispatch({
        type: API_REQUEST,
        payload: {
            onRequest: () => StaticDataService.getUserRoles(),

            onSuccess: roles => {
                const currentUser = auth.getCurrentUser(getState())

                onSuccess(roles[currentUser.role] || [])
            },

            onError: error => {
                if (onError) {
                    onError(error)
                }
            },
        },
    })
}
