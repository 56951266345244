import React, { useCallback } from "react"
import { useTrans }           from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldLocalGovernment,
    FormFieldName,
    FormFieldNumber,
    FormFieldSelect,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                             from "../../fields"
import defaultFormData        from "./formData"

const CitizenshipCopy = ({ staticData, formData: data, onChange, isPreview, errors }) => {
    const { ward } = staticData
    const formData = { ...defaultFormData, current_ward: ward, ...data }
    const { trans } = useTrans()

    const handleOnChange = useCallback(({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }, [onChange, formData])

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="सिफारिस सम्बन्धमा ।"
                errors={errors}
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपराेक्त सम्बन्न्धमा{" "}
                    <FormFieldLocalGovernment
                        value={formData.birth_local_government}
                        name="birth_local_government"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> वडा नं.{" "}
                    <FormFieldWard
                        value={formData.birth_ward}
                        name="birth_ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    /> मा मिति{" "}
                    <FormFieldDate
                        value={formData.birth_date}
                        name="birth_date"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> मा जन्म भई हाल{" "}
                    {trans(
                        `municipalities.${staticData.municipality.name}`,
                    )} वडा नं{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    /> मा स्थायी रुपमा बसोबास गर्ने वर्ष{" "}
                    <FormFieldNumber
                        value={formData.age}
                        placeholder={trans("general.age")}
                        name="age"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> काे{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    /> ले नीजकाे जि.प्र.का.{" "}
                    <FormFieldText
                        value={formData.district_office}
                        name="district_office"
                        placeholder={trans("general.district_office")}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> बाट{" "}
                    <FormFieldDate
                        value={formData.issued_date}
                        name="issued_date"
                        placeholder={trans("general.issued_date")}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    /> मा जारी भएकाे ना.प्र.नं.{" "}
                    <FormFieldText
                        value={formData.citizenship_no}
                        name="citizenship_no"
                        placeholder={trans("general.citizenship_no")}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> काे नेपाली नागरिकताकाे{" "}
                    <FormFieldSelect
                        name={"citizenship_type"}
                        options={{
                            original: trans("general.original"),
                            duplicate: trans("general.duplicate"),
                        }}
                        isPreview={isPreview}
                        value={formData.citizenship_type}
                        errors={errors}
                        onChange={handleOnChange}
                    /> प्रमाणपत्र{" "}
                    <FormFieldSelect
                        name={"reason"}
                        options={{
                            lost: trans("general.lost"),
                            damage: trans("general.damage"),
                            damaged_by_fire: trans("general.damaged_by_fire"),
                        }}
                        isPreview={isPreview}
                        errors={errors}
                        value={formData.reason}
                        onChange={handleOnChange}
                    /> ले नेपाली नागरिकता प्रमाणपत्रकाे प्रतिलिपिकाे आवश्यक
                    भएको भनी निवेदन प्राप्त भएकाे हुँदा नीजलाई नियमानुसार नेपाली
                    नागरिकता प्रमाणपत्रकाे प्रतिलिपि दिएमा फरक नपर्ने व्यहोरा
                    सिफारिस गर्दछु।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default CitizenshipCopy
