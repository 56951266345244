export default {
    header: {},
    footer: {},
    salutation: {},

    organization_name: "",
    address: "",
    organization_region: "",
    thematic_area: "",
    start_date: "",
    organization_email: "",
    organization_phone: "",
    owner_fullname: "",
    owner_address: "",
    owner_email: "",
    owner_phone: "",

    previous_address: {},
    name: "",
    current_ward: "",
    landDetails: [{}]
}
