import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldSelect,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const DalitVerification = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { municipality, ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }

    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="दलित प्रमाणित"
                onChange={handleOnChange}
                errors={errors}
            />
            <div className="body-content">
                <div className="form-text-container">
                    {trans(`municipalities.district.${municipality.district}`)}{" "}
                    जिल्ला {trans(`municipalities.${municipality.name}`)} वडा
                    नं.{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    बस्ने{" "}
                    <FormFieldName
                        value={formData.parentName}
                        name="parentName"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />
                    को{" "}
                    <FormFieldSelect
                        name={"relation"}
                        options={{
                            son: trans("general.relation.son"),
                            daughter: trans("general.relation.daughter"),
                        }}
                        isPreview={isPreview}
                        value={formData.relation}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />
                    ले म दलित जातीको भएकोले थर प्रमाणित गरी पाउँ भनी मिति{" "}
                    <FormFieldDate
                        value={formData.applicationDate}
                        name="applicationDate"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    मा यस कार्यालयमा निवेदन दिएकोले नेपाल सरकारबाट निर्णय भै
                    प्रकाशित{" "}
                    <FormFieldText
                        value={formData.surname}
                        name="surname"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={"surname"}
                    />{" "}
                    थर दलित वर्गमा सुचीकृत जात जातीहरुको वर्णानुक्रम अन्तर्गत
                    राखिएको हुँदा मिति{" "}
                    <FormFieldDate
                        value={formData.sifarishDate}
                        name="sifarishDate"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />
                    को सिफारिस बमोजिम उक्त जाती दलित वर्गको भएको व्यहोरा
                    प्रमाणित गरिएको छ।
                </div>
            </div>
            <div className="section-fingerprint">
                <div>
                    <div
                        className="form-fingerprint"
                        style={{ marginBottom: "20px" }}
                    >
                        <div className="fingerprint-right">
                            <span>फाेटाे</span>
                        </div>
                    </div>
                    <p>ल्याप्चे सहिछाप</p>
                    <div className="form-fingerprint">
                        <div className="fingerprint-right">
                            <span>दायाँ</span>
                        </div>
                        <div className="fingerprint-left">
                            <span>बायाँ</span>
                        </div>
                    </div>
                </div>
                <FormFooter
                    staticData={staticData}
                    value={formData.footer}
                    errors={errors}
                    isPreview={isPreview}
                    onChange={handleOnChange}
                />
            </div>
        </div>
    )
}

export default DalitVerification
