import { socket }  from "../services/SocketService"
import * as events from "./events"

class Socket {
    init(dispatch) {
        this.dispatch = dispatch

        this.listen()
    }

    listen() {
        if (!this.dispatch) {
            return
        }
        
        const props = {
            socket: socket,
            dispatch: this.dispatch,
        }

        Object.keys(events)
              .forEach(event => {
                  (new events[event](props)).listener()
              })
    }
}

export default (new Socket())
