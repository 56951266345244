export default {
    header: {},
    footer: {},
    salutation: {},

    current_ward: "",
    organization_name: "",
    position: "",
    applicant: {},
    date: "",
}
