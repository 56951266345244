import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const ThreeGenerationVerification = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }
    const { trans, numberTrans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="तीन पुस्ते प्रमाणित ।"
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा यस{" "}
                    {trans(`municipalities.${staticData.municipality.name}`)}{" "}
                    वडा नं.{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    <FormFieldText
                        value={formData.address}
                        name="address"
                        placeholder={trans("general.current_address")}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    बस्ने{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />
                    ले तीन पुस्ते प्रमाणितका लागि मिति{" "}
                    <FormFieldDate
                        value={formData.date}
                        name="date"
                        placeholder={trans("general.date")}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    गते यस कार्यालयमा निवेदन दिनु भएको हुँदा निवेदकले पेश गरेको
                    कागजातहरुको आधारमा निवेदक{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        errors={errors}
                        isPreview={true}
                        onChange={handleOnChange}
                    />
                    को तीन पुस्ते तपशिलमा उल्लेख भए अनुसार रहेको व्यहोरा
                    प्रमाणितका साथ अनुरोध गरिन्छ ।
                </div>

                <div className="table-responsive">
                    <p className="title">
                        {trans("general.three_generation_info")}
                    </p>

                    <table className="table table-bordered table-sm">
                        <thead>
                            <tr>
                                <th style={{ width: "7%" }}>क्र.सं.</th>
                                <th style={{ width: "20%" }}>नाम</th>
                                <th style={{ width: "5%" }}>नाता</th>
                                <th style={{ width: "20%" }}>नागरिकता न.</th>
                                <th style={{ width: "15%" }}>जारी मिति</th>
                                <th style={{ width: "10%" }}>जिल्ला</th>
                            </tr>
                        </thead>

                        <tbody>
                            <tr>
                                <td style={{ textAlign: "center" }}>
                                    {numberTrans("1")}
                                </td>
                                <td>
                                    <FormFieldName
                                        value={formData.applicant}
                                        name="applicant"
                                        isPreview={true}
                                        errors={errors}
                                        onChange={handleOnChange}
                                    />
                                </td>
                                <td>{trans("general.self")}</td>
                                <td>
                                    <FormFieldText
                                        value={formData.self_citizenship_no}
                                        name={"self_citizenship_no"}
                                        isPreview={isPreview}
                                        onChange={handleOnChange}
                                        errors={errors}
                                        placeholder={trans(
                                            "general.citizenship_no",
                                        )}
                                    />
                                </td>
                                <td>
                                    <FormFieldText
                                        value={
                                            formData.self_citizenship_issued_date
                                        }
                                        name={"self_citizenship_issued_date"}
                                        isPreview={isPreview}
                                        onChange={handleOnChange}
                                        errors={errors}
                                        placeholder={trans(
                                            "general.issued_date",
                                        )}
                                    />
                                </td>
                                <td>
                                    <FormFieldText
                                        value={formData.self_district}
                                        name={"self_district"}
                                        isPreview={isPreview}
                                        onChange={handleOnChange}
                                        errors={errors}
                                        placeholder={trans(
                                            "general.district_name",
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>
                                    {numberTrans("2")}
                                </td>
                                <td>
                                    <FormFieldText
                                        value={formData.father_name}
                                        name={"father_name"}
                                        errors={errors}
                                        isPreview={isPreview}
                                        onChange={handleOnChange}
                                    />
                                </td>
                                <td>{trans("general.father")}</td>
                                <td>
                                    <FormFieldText
                                        value={formData.father_citizenship_no}
                                        name={"father_citizenship_no"}
                                        isPreview={isPreview}
                                        errors={errors}
                                        onChange={handleOnChange}
                                        placeholder={trans(
                                            "general.citizenship_no",
                                        )}
                                    />
                                </td>
                                <td>
                                    <FormFieldText
                                        value={
                                            formData.father_citizenship_issued_date
                                        }
                                        name={"father_citizenship_issued_date"}
                                        errors={errors}
                                        isPreview={isPreview}
                                        onChange={handleOnChange}
                                        placeholder={trans(
                                            "general.issued_date",
                                        )}
                                    />
                                </td>
                                <td>
                                    <FormFieldText
                                        value={formData.father_district}
                                        name={"father_district"}
                                        isPreview={isPreview}
                                        onChange={handleOnChange}
                                        errors={errors}
                                        placeholder={trans(
                                            "general.district_name",
                                        )}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ textAlign: "center" }}>
                                    {numberTrans("3")}
                                </td>
                                <td>
                                    <FormFieldText
                                        value={formData.grand_father_name}
                                        name={"grand_father_name"}
                                        errors={errors}
                                        isPreview={isPreview}
                                        onChange={handleOnChange}
                                    />
                                </td>
                                <td>{trans("general.grand_father")}</td>
                                <td>
                                    <FormFieldText
                                        value={
                                            formData.grand_father_citizenship_no
                                        }
                                        name={"grand_father_citizenship_no"}
                                        isPreview={isPreview}
                                        onChange={handleOnChange}
                                        errors={errors}
                                        placeholder={trans(
                                            "general.citizenship_no",
                                        )}
                                    />
                                </td>
                                <td>
                                    <FormFieldText
                                        value={
                                            formData.grand_father_citizenship_issued_date
                                        }
                                        name={
                                            "grand_father_citizenship_issued_date"
                                        }
                                        isPreview={isPreview}
                                        errors={errors}
                                        onChange={handleOnChange}
                                        placeholder={trans(
                                            "general.issued_date",
                                        )}
                                    />
                                </td>
                                <td>
                                    <FormFieldText
                                        value={formData.grand_father_district}
                                        name={"grand_father_district"}
                                        isPreview={isPreview}
                                        errors={errors}
                                        onChange={handleOnChange}
                                        placeholder={trans(
                                            "general.district_name",
                                        )}
                                    />
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                errors={errors}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default ThreeGenerationVerification
