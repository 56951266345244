import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldKitta,
    FormFieldName,
    FormFieldNumber,
    FormFieldSelect,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const RoadPersist = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { municipality, ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    const FormFieldDirection = formFieldProps => {
        const { value, name, isPreview, onChange } = formFieldProps

        let options = {
            east: trans("general.east"),
            west: trans("general.west"),
            north: trans("general.north"),
            south: trans("general.south"),
        }

        return (
            <FormFieldSelect
                value={value}
                name={name}
                isPreview={isPreview}
                onChange={onChange}
                errors={errors}
                options={options}
            />
        )
    }

    const FormFieldRoadType = formFieldProps => {
        const { value, name, isPreview, onChange } = formFieldProps

        let options = {
            paved_road: trans("general.paved_road"),
            gravel_road: trans("general.gravel_road"),
            small_road: trans("general.small_road"),
        }

        return (
            <FormFieldSelect
                value={value}
                name={name}
                isPreview={isPreview}
                onChange={onChange}
                options={options}
                errors={errors}
            />
        )
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="बाटाे प्रमाणित बारे ।"
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा यस{" "}
                    {trans(`municipalities.${municipality.name}`)} वडा नं.{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    बस्ने{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    ले दिनु भएको निवेदन अनुसार नीजकाे नाममा दर्ता श्रेष्ता रहेको
                    कि.नं.{" "}
                    <FormFieldKitta
                        value={formData.kitta}
                        name="kitta"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    को जग्गाको{" "}
                    <FormFieldDirection
                        value={formData.direction}
                        name="direction"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    पट्टि{" "}
                    <FormFieldNumber
                        value={formData.distance}
                        name="distance"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    फिट{" "}
                    <FormFieldRoadType
                        value={formData.road_type}
                        name="road_type"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    बाटाे भएकोले सोही व्यहोरा प्रमाणितका लागि मिति{" "}
                    <FormFieldDate
                        value={formData.date}
                        name="date"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    गते यस कार्यालयमा निवेदन पेश गरेको हुँदा निवेदकले निवेदन साथ
                    पेश गरेको कागजातहरुको आधारमा निवेदकको नाममा दर्ता श्रेष्ता
                    भएको माथी उल्लेखित कि.नं.{" "}
                    <FormFieldKitta
                        value={formData.kitta}
                        name="kitta"
                        errors={errors}
                        isPreview={true}
                        onChange={handleOnChange}
                    />{" "}
                    को जग्गाको{" "}
                    <FormFieldDirection
                        value={formData.direction}
                        name="direction"
                        isPreview={true}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    पट्टि{" "}
                    <FormFieldNumber
                        value={formData.distance}
                        name="distance"
                        errors={errors}
                        isPreview={true}
                        onChange={handleOnChange}
                    />{" "}
                    फिट{" "}
                    <FormFieldRoadType
                        value={formData.road_type}
                        name="road_type"
                        isPreview={true}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    बाटाे भएको व्यहोरा प्रमाणित गरिन्छ ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                isPreview={isPreview}
                value={formData.footer}
                errors={errors}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default RoadPersist
