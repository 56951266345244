import { useState } from "react"

export default function useTabs(config = {}) {
    const [activeTab, setActiveTab] = useState(0)

    const selectTab = index => {
        if (index !== activeTab) {
            setActiveTab(index)
        }
    }

    return {
        activeTab,
        selectTab,
    }
}
