import {
    apiCall,
    call,
    dbCall,
    log,
}                          from "../../helpers"
import {
    StaticDataDBService,
    StaticDataService,
}                          from "../../services/static-data"
import { setTranslations } from "../reducers/system/action"

export const fetchTranslationFromApi = (onSuccess, onError) => dispatch => {
    dispatch(apiCall({
        onRequest: () => StaticDataService.getTranslation(),
        onSuccess: translations => {
            dispatch(setTranslations(translations))

            dispatch(saveTranslationsToDB(translations, onSuccess))
        },
        onError: error => {
            call(onError, error)
        },
    }))
}

export const saveTranslationsToDB = (translations, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => StaticDataDBService.setTranslations(translations),
        onSuccess, onError,
    }))
}

export const fetchTranslationFromDB = (onSuccess, onError) => async dispatch => {
    dispatch(dbCall({
        onRequest: () => StaticDataDBService.getTranslations(),
        onSuccess: translations => {
            dispatch(setTranslations(translations))

            call(onSuccess)
        },
        onError: error => {
            dispatch(log("No translation in database.", error))

            call(onError, error)
        },
    }))
}
