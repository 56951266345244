import PropTypes           from "prop-types"
import React               from "react"
import { useTrans }        from "../../../hooks"
import { FormFieldSelect } from "./"

const FormFieldGrandChildType = ({ isPreview, value, name, onChange, errors }) => {
    const { trans } = useTrans()

    const children = ["grand_son", "grand_daughter"].reduce((collection, child) => {
        collection[child] = trans("general.grand_children." + child)
        return collection
    }, {})

    const handleOnChange = (event) => {
        onChange({ name, value: event.value })
    }

    return (<FormFieldSelect errors={errors} name={name} value={value} isPreview={isPreview} options={children} onChange={handleOnChange}/>)

}

FormFieldGrandChildType.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldGrandChildType
