export default {
    header: {},
    footer: {},
    salutation: {},

    permanentDistrict: "",
    permanentLocalGovernmentName: {},
    permanentWard: "",
    current_ward: "",
    applicant: "",
    relation: "",
    relative: "",
    ethnicity: "",
    date: "",
}
