import React, {
    Fragment,
    useEffect,
    useLayoutEffect,
    useState,
}                   from "react"
import {
    useDispatch,
    useSelector,
}                   from "react-redux"
import { Link }     from "react-router-dom"
import * as images  from "../../assets/image"
import { auth }     from "../../helpers"
import { useTrans } from "../../hooks"
import { logout }   from "../../store/actions/AuthActions"
import { notify }   from "../../store/actions/SystemActions"

const Header = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const isOnline = useSelector(state => state.system.isOnline)
    const municipality = useSelector(auth.getCurrentMunicipality)
    const isAuthenticated = useSelector(auth.checkIfAuthenticated)
    const currentUser = useSelector(auth.getCurrentUser)
    const [isPublicSifarish, setIsPublicSifarish] = useState(false)

    const { trans } = useTrans()
    const dispatch = useDispatch()

    // const logo = municipality
    //     ? images[`${municipality.name}Logo`]
    //     : images.defaultLogo;
    const logo = images.defaultLogo
    const logoAltText = municipality ? trans(`municipalities.${municipality.name}`) : trans(`general.app_name`)

    const handleLogout = event => {
        event.preventDefault()
        setDropdownVisible(false)
        dispatch(
            logout(() => {
                dispatch(notify("You have logged out from the system."))
            }),
        )
    }

    const showDropdown = event => {
        event.preventDefault()

        setDropdownVisible(!dropdownVisible)
    }

    const hideDropdown = event => {
        setDropdownVisible(false)
    }

    useLayoutEffect(() => {
        document.addEventListener("click", event => {
            const dropdownContainer = event.target.closest(".dropdown-container")
            const dropdownElement = event.target.closest(".dropdown-options")
            if (!dropdownElement && !dropdownContainer) {
                setDropdownVisible(false)
            }
        })
    })

    useEffect(() => {
        let url = new URL(window.location)
        setIsPublicSifarish(url.href.split("/").includes("public"))
    }, [])

    return (
        <nav className="nav no-print">
            <div className="container nav-container">
                {
                    isPublicSifarish ? (
                        <div className="nav-brand">
                            <img src={logo} className="logo" alt={logoAltText}/>
                            {isAuthenticated && municipality && (
                                <span className="logo-title">{trans(`municipalities.${municipality.name}`)}</span>
                            )}
                        </div>
                    ) : (
                        <Link to="/" className="nav-brand">
                            <img src={logo} className="logo" alt={logoAltText}/>
                            {isAuthenticated && municipality && (
                                <span className="logo-title">{trans(`municipalities.${municipality.name}`)}</span>
                            )}
                        </Link>
                    )
                }

                <h2 className="nav-title">{trans("general.app_name")}
                    <span className={`indicator ${isOnline ? "online" : "offline"}`}></span>
                </h2>

                <ul className="nav-list">{isAuthenticated ? <AuthNav/> : <GuestNav/>}</ul>
            </div>
        </nav>
    )

    function AuthNav() {
        return (
            <Fragment>
                <li className="nav-list-item dropdown-container">
                    <Link
                        to="/admin"
                        className={`ic-user ${dropdownVisible && "dropdown-visible"}`}
                        onClick={showDropdown}
                    >
                        {currentUser.display_name}
                    </Link>
                    {dropdownVisible && (
                        <div className="dropdown-options">
                            <ul className="user-dropdown-options">
                                <li className="user-dropdown-edit">
                                    <span className="user-dropdown-item">प्रोफाइल सम्पादन</span>
                                </li>
                                <li className="user-dropdown-password">
                                    <Link
                                        to="/admin/sync"
                                        onClick={hideDropdown}
                                        className="user-dropdown-item"
                                    >
                                        Manual Sync
                                    </Link>
                                </li>
                                <li className="user-dropdown-password">
                                    <Link
                                        to="/admin/profile/change-password"
                                        onClick={hideDropdown}
                                        className="user-dropdown-item"
                                    >
                                        पासवर्ड परिवर्तन
                                    </Link>
                                </li>
                                <li className="user-dropdown-password">
                                    <Link
                                        to="/admin/new-data-dumper"
                                        onClick={hideDropdown}
                                        className="user-dropdown-item"
                                    >
                                        Data dump
                                    </Link>
                                </li>
                                <li className="user-dropdown-logout" onClick={handleLogout}>
                                    <Link to="/" className="user-dropdown-item">
                                        {" "}
                                        {trans("general.logout")}
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    )}
                </li>
            </Fragment>
        )
    }

    function GuestNav() {
        return (
            <Fragment>
                <li className="nav-list-item">
                    <Link to="/contact">{trans("general.contact")}</Link>
                </li>
            </Fragment>
        )
    }
}

export default Header
