export default {
    header: {},
    footer: {},
    salutation: {},

    currentWard: "",
    previous_address: {},
    applicant: {},
    lg_name: "",
    registered_date: "",
    registration_number: "",
    deceased_name: {},
    total_person: "",
    inheritanceDetails: [{}],
}
