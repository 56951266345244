import { isEmpty } from "lodash"
import PropTypes   from "prop-types"
import React       from "react"

const FormFieldSelect = ({ isPreview, value, name, options, onChange, errors }) => {
    if (!isEmpty(errors)) {console.error(errors)}

    return (
        isPreview ? (
            <span className="form-input-view">{options[value] || ""}</span>
        ) : (
            <select name={name} value={value} onChange={event => {
                onChange({ "name": event.target.name, "value": event.target.value })
            }}>
                <option value=""></option>
                {
                    Object.entries(options)
                          .map(([key, label]) => (
                              <option key={key} value={key}>{label}</option>
                          ))}
            </select>
        )
    )
}

FormFieldSelect.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    options: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldSelect
