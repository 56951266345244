export default {
    header: {},
    salutation: {},
    footer: {},

    municipality: {},
    ward: "",
    previous_address: {},
    previous_ward: "",
    applicant: {},
    kitta: "",
    establishment_date: "",
    property_status: "",
    water_connection: "",
    propertyList: [{}],
    obligation: "",
}
