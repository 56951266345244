import PropTypes    from "prop-types"
import React        from "react"
import { useTrans } from "../../../hooks"
import {
    FormFieldLocalGovernment,
    FormFieldText,
    FormFieldWard,
}                   from "./"

const FormFieldCurrentAddress = ({ isPreview, value, name, onChange, errors }) => {
    const { trans } = useTrans()

    const handleOnChange = (event) => {
        onChange({ name, value: { ...value, [event.name]: event.value } })
    }

    return (
        <>
            <FormFieldText
                value={value.address || ""}
                errors={errors}
                name={"address"}
                isPreview={isPreview}
                onChange={handleOnChange}
                placeholder={trans("general.current_address")}
            />,&nbsp;
            <FormFieldLocalGovernment
                value={value.local_government_level || {}}
                errors={errors}
                name={"local_government_level"}
                isPreview={isPreview}
                onChange={handleOnChange}
                placeholder="test"
            />,&nbsp;
              वडा नं&nbsp;
            <FormFieldWard
                value={value.ward || ""}
                errors={errors}
                name={"ward"}
                isPreview={isPreview}
                onChange={handleOnChange}
            />
        </>
    )
}

FormFieldCurrentAddress.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldCurrentAddress
