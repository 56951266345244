import {get} from "lodash"
import React, {
    useLayoutEffect,
    useState,
    useCallback,
} from "react"
import {useSelector} from "react-redux"
import Tabs from "../../components/tabs/Tabs"
import {auth} from "../../helpers"
import {useTrans} from "../../hooks"
import LayoutDBService from "../../services/layout/LayoutDBService"
import HeaderLayoutManager from "./partials/HeaderLayoutManager"
import FooterLayoutManager from "./partials/FooterLayoutManager"

const LayoutBuilder = (props) => {
    const {trans} = useTrans()

    const [layoutData, setLayoutData] = useState({
        "header": null,
        "footer": null,
    })
    const municipality = useSelector(auth.getCurrentMunicipality)
    const ward = useSelector(auth.getCurrentWard)

    const fetchLayoutData = useCallback(async () => {
        const header = await LayoutDBService.getLayout("header", municipality.name, ward)
        const footer = await LayoutDBService.getLayout("footer", municipality.name, ward)

        setLayoutData({...layoutData, "header": header, "footer": footer})

        return {
            "header": header,
            "footer": footer,
        }
    }, [municipality, ward])

    useLayoutEffect(() => {
        const data = fetchLayoutData()
    }, [fetchLayoutData])


    return (
        <div className={"layout-page"}>
            <h2 className="page-title">{trans("general.layout_manager")}</h2>

            <Tabs>
                <HeaderLayoutManager title={trans("general.header_layout_manager")}
                                     headerData={get(layoutData, "header")}/>
                <FooterLayoutManager title={trans("general.footer_layout_manager")}
                                     footerData={get(layoutData, "footer")}/>
            </Tabs>
        </div>
    )
}

export default LayoutBuilder
