import jwt                 from "jsonwebtoken"
import jwtDecode           from "jwt-decode"
import { config }          from "../config"
import {
    addLoaderMessage,
    completeLoaderMessage,
    failedLoaderMessage,
}                          from "../store/reducers/system/action"
import {
    API_REQUEST,
    DB_REQUEST,
    LOG,
}                          from "../store/reducers/system/type"
import * as authHelper     from "./AuthHelper"
import * as electronHelper from "./ElectronHelper"

export const isArray = data => Array.isArray(data)
export const isObject = data => typeof data === "object" && !isArray(data)

export const sortArray = (arr, key) => arr.sort((a, b) => ((b[key] || "") - (a[key] || "")))

export const pushToArray = (arr, data) => [...arr, data]
export const removeFromArrayByIndex = (arr, index) => [
    ...arr.slice(0, index),
    ...arr.slice(index + 1),
]
export const removeFromArrayByKey = (arr, { key, value }) => {
    const clonedArray = [...arr]

    return clonedArray.reduce((resultArray, item) => {
        if (item[key] === value) {
            return [...resultArray]
        }

        return [...resultArray, item]
    }, [])
}
export const updateObjectByIndex = (arr, index, updatedKeyValue) => {
    const clonedArray = [...arr]

    clonedArray[index] = { ...clonedArray[index], ...updatedKeyValue }

    return clonedArray
}
/**
 * Update an array of object by a key
 * @param {array} arr
 * @param {string} key
 * @param {string} value
 * @param {object} updatedKeyValue
 * @return {array}
 */
export const updateObjectByKey = (arr, { key, value }, updatedKeyValue) => {
    const clonedArray = [...arr]

    return clonedArray.map((item) => {
        if (item[key] === value) {
            return { ...item, ...updatedKeyValue }
        }

        return item
    })
}

function * rangeGenerator(begin, end, interval = 1) {
    for (let i = begin; i <= end; i += interval) {
        yield i
    }
}

export const range = (start, end, interval = 1) => {
    const data = []

    for (let r of rangeGenerator(start, end, interval)) {
        data.push(r)
    }

    return data
}

export const log = (...contents) => {
    return {
        type: LOG,
        payload: contents,
    }
}

export const apiCall = ({ onRequest, onSuccess, onError }) => {
    return {
        type: API_REQUEST,
        "function": onRequest.toString(),
        payload: { onRequest, onSuccess, onError },
    }
}

export const dbCall = ({ onRequest, onSuccess, onError }) => {
    return {
        type: DB_REQUEST,
        "function": onRequest.toString(),
        payload: { onRequest, onSuccess, onError },
    }
}

export const call = (handler, ...data) => {
    if (handler) {
        handler(...data)
    }
}

export const isOnlineChecked = state => state.system.checkedOnline
export const isOnline = state => state.system.isOnline
export const isBooted = state => state.system.isBooted
export const checkIfSystemLoading = state => state.system.loadingMessages.length > 0
export const isTokenExpired = token => {
    const { exp } = jwtDecode(token)
    const now = Math.floor((new Date()).getTime() / 1000)

    return now >= exp
}
export const generateJWT = (payload) => {
    const now = (new Date()).getTime()
    const expirationDate = new Date(now)
    expirationDate.setTime(now + config("app.tokenExpiration") * 1000)

    return jwt.sign({
        ...payload,
        exp: parseInt((expirationDate.getTime() / 1000).toString(), 10),
    }, config("app.jwtSecret"))
}

export const dispatcherWithLoader = async (dispatch, loadingMessage, dispatcherFunction) => new Promise((resolve, reject) => {
    dispatch(addLoaderMessage(loadingMessage))

    dispatch(dispatcherFunction(() => {
        dispatch(completeLoaderMessage(loadingMessage))
        resolve()
    }, error => {
        dispatch(failedLoaderMessage(loadingMessage))
        reject(error)
    }))
})

export const auth = authHelper
export const electron = electronHelper

export const prepareFormData = (data) => {
    let formData = new FormData()

    function appendFormData(data, label) {
        label = label || ""
        if (data instanceof File) {
            formData.append(label, data)
        } else if (Array.isArray(data)) {
            for (let i = 0; i < data.length; i++) {
                appendFormData(data[i], label + "[]")
            }
        } else if (typeof data === "object" && data) {
            for (let key in data) {
                if (data.hasOwnProperty(key)) {
                    if (label === "") {
                        appendFormData(data[key], key)
                    } else {
                        appendFormData(data[key], label + "[" + key + "]")
                    }
                }
            }
        } else {
            if (data !== null && typeof data !== "undefined") {
                formData.append(label, data)
            }
        }
    }

    appendFormData(data)

    return formData
}
