import React, {
    useCallback,
    useEffect,
    useState,
}                                           from "react"
import ReactPaginate                        from "react-paginate"
import {
    useDispatch,
    useSelector,
}                                           from "react-redux"
import {
    Link,
    NavLink,
}                                           from "react-router-dom"
import { NoResult }                         from "../../components/empty-state"
import { Loading }                          from "../../components/loading"
import { Can }                              from "../../components/permissions"
import {
    MUNICIPALITY_ADMIN,
    SUPER_ADMIN,
}                                           from "../../config/Constants/UserRoles"
import { ACTIONS }                          from "../../config/PermissionConfig"
import { auth }                             from "../../helpers"
import { useTrans }                         from "../../hooks"
import { sifarishManualSync }               from "../../store/actions/ManualSyncActions"
import { fetchSifarishPaginatedListFromDB } from "../../store/actions/SifarishActions"
import { notify }                           from "../../store/actions/SystemActions"
import SifarishFilter                       from "./../../components/SifarishFilter"
import SifarishListRow                      from "./SifarishListRow"

const SifarishList = ({ location }) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [advancedFilter, setAdvancedFilter] = useState(false)
    const [pageFilter, setPageFilter] = useState({ page: 1 })
    const [pagination, setPagination] = useState({})
    const [refreshList, setRefreshList] = useState(true)
    const [sifarishList, setSifarishList] = useState([])

    const currentUser = useSelector(auth.getCurrentUser)

    const dispatch = useDispatch()
    const { trans } = useTrans()

    const handlePageChange = pagination => {
        const page = pagination.selected + 1
        setPageFilter({ ...pageFilter, page })
        setRefreshList(true)
    }

    useEffect(() => {
        if (refreshList) {
            dispatch(
                fetchSifarishPaginatedListFromDB(pageFilter.page, ({ data, pagination }) => {
                    setSifarishList(data)
                    setPagination(pagination)
                    setIsLoaded(true)
                }),
            )
            setRefreshList(false)
        }
    }, [location, refreshList, dispatch, pageFilter.page])

    const manualSync = useCallback(() => {
        dispatch(sifarishManualSync(message => {
            dispatch(notify(message))
        }, () => {
            dispatch(notify("Refreshed."))
            window.location.reload()
        }, () => {
            dispatch(notify("Failed."))
        }))
    }, [dispatch])

    if (!isLoaded) {
        return <Loading/>
    }

    const showAdvanceFilter = () => {
        setAdvancedFilter(!advancedFilter)
    }

    return (
        <div>
            <div className="page-header-container">

                <h3 className="page-title">{trans("general.submitted_forms")}</h3>
                <div>
                    <Can action={ACTIONS.SIFARISH.ADD}>
                        <NavLink to="/admin/sifarish/forms" activeClassName="active"
                                 className="btn-link">
                            + नया फारम भर्नुहोस्
                        </NavLink>
                        <span style={{ marginLeft: "5px", marginRight: "2px" }}>|</span>
                    </Can>

                    <button className="btn-link" onClick={manualSync}>
                        Refresh
                    </button>
                </div>

                <Can action={ACTIONS.SIFARISH.FILTER}>
                    <div className="search-container">
                        <div className="form-search">
                            <input
                                type="text"
                                placeholder="शब्दहरूबाट खोजी गर्नुहोस्"
                                name="search"
                                className="field-search"
                            />
                            <button className="btn-search-submit">खोज्नुहोस</button>
                        </div>
                        <div className="filter-button">
                            <button className="btn-filter" onClick={showAdvanceFilter}>
                                एडभांस फिल्टर
                            </button>
                        </div>
                    </div>
                </Can>
            </div>

            <div className="table-responsive">
                {sifarishList.length === 0 ? (
                    <NoResult
                        text={trans("general.empty_states.no_form_submitted")}
                        action={
                            <Can action={ACTIONS.SIFARISH.ADD}>
                                <Link to="/admin/sifarish/forms" className="btn-outline btn-primary">
                                    {trans("general.add_form")}
                                </Link>
                            </Can>
                        }
                    />
                ) : (
                    <>
                        <table className="table form-list table-striped table-hover">
                            <thead>
                                <tr>
                                    <th style={{ width: "7%" }}>{trans("general.s_n")}</th>
                                    <th style={{ width: "12%" }}>{trans("general.date")}</th>
                                    <th style={{ width: "15%" }}>{trans("general.form")}</th>
                                    <th style={{ width: "15%" }}>{trans("general.applicant")}</th>
                                    {currentUser.role === SUPER_ADMIN && (
                                        <th style={{ width: "16%" }}>
                                            {trans("municipalities.administrative_division.municipality")}
                                        </th>
                                    )}
                                    {(currentUser.role === SUPER_ADMIN || currentUser.role === MUNICIPALITY_ADMIN) && (
                                        <th style={{ width: "8%" }}>{trans("general.ward_no")}</th>
                                    )}
                                    <th style={{ width: "14%" }}>{trans("general.submitted_on")}</th>
                                    <th style={{ width: "14%" }}></th>
                                </tr>
                            </thead>

                            <tbody>
                                {sifarishList.map((sifarish, index) => (
                                    <SifarishListRow
                                        key={index}
                                        sifarish={sifarish}
                                        index={index + 1}
                                        pagination={pagination}
                                        currentUser={currentUser}
                                    />
                                ))}
                            </tbody>
                        </table>

                        {pagination.total > pagination.per_page && (
                            <div className="pagination-container">
                                <ReactPaginate
                                    previousLabel={"prev"}
                                    nextLabel={"next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    // initialPage={0}
                                    // pageCount={3}
                                    // onPageChange={handlePageChange}
                                    initialPage={pageFilter.page - 1}
                                    pageCount={pagination.total_pages}
                                    onPageChange={handlePageChange}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    containerClassName={"pagination-items"}
                                    pageClassName={"pagination-item"}
                                    previousClassName={"pagination-item prev"}
                                    nextClassName={"pagination-item next"}
                                    activeClassName={"active"}
                                />
                            </div>
                        )}
                    </>
                )}
            </div>
            <Can action={ACTIONS.SIFARISH.FILTER}>
                {advancedFilter && <SifarishFilter filterToggle={showAdvanceFilter}/>}
            </Can>
        </div>
    )
}

export default SifarishList
