import React                       from "react"
import { BrowserRouter as Router } from "react-router-dom"

const WebRouter = ({ children }) => {
    return (
        <Router>
            {children}
        </Router>
    )
}

export default WebRouter
