import { get }               from "lodash"
import appConfig             from "./AppConfig"
import * as permissionConfig from "./PermissionConfig"

const configs = {
    app: appConfig,
    permission: permissionConfig,
}

export const config = (key) => get(configs, key, key)
