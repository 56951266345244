export default {
    header: {},
    footer: {},
    salutation: {},

    fiscal_year: "",
    date: "",
    allowance: "",
    health_allowance: "",
    allowanceDetails: [{}],
}
