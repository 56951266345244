const localstorage = window.localStorage

class LocalStorageService {
    /**
     * @param {string} key
     * @param {string} value
     */
    set = async (key, value) => {
        await new Promise(resolve => {
            localstorage.setItem(key, value)

            resolve()
        })
    }

    /**
     * @param {string} key
     * @param {object} json
     */
    setJson = async (key, json) => {
        await this.set(key, JSON.stringify(json))
    }

    /**
     * @param {string} key
     * @return {Promise}
     */
    get = async key => await new Promise(resolve => {
        setTimeout(() => {
            const value = localstorage.getItem(key)

            resolve(value)
        }, 0)
    })

    /**
     * @param {string} key
     *
     * @return {object}
     */
    getJson = async key => {
        const value = await this.get(key)

        return JSON.parse(value)
    }

    /**
     * @param {string} key
     */
    remove = async key => {
        await new Promise(resolve => {
            localstorage.removeItem(key)

            resolve()
        })
    }

    /**
     * Clears the entire storage of all records
     */
    removeAll = async () => {
        await new Promise(resolve => {
            localstorage.clear()

            resolve()
        })
    }

    /**
     * @param {number} index
     * @return {string}
     */
    getByIndex = async index => await new Promise(resolve => {
        const value = localstorage.key(index)

        resolve(value)
    })
}

export default (new LocalStorageService())
