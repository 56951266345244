import React, { Fragment } from "react"
import {
    Redirect,
    Route,
    Switch,
}                          from "react-router-dom"
import { usePermission }   from "../../hooks"
import routes              from "../../routes"
import {
    Header,
    SubHeader,
}                          from "../layouts"
import PrivateRoute        from "./PrivateRoute"

const Routes = () => {
    const { check } = usePermission()

    return (
        <Fragment>
            <Header/>

            {window.location.pathname.includes("index.html") && <Redirect to="/"/>}

            <div className="container body-content">
                <Switch>
                    {routes.map((route, index) => {
                        if (!route.hasSubHeader) {
                            return null
                        }

                        return (<Route key={index} exact={route.exact} path={route.path} component={SubHeader}/>)
                    })}
                </Switch>

                <Switch>
                    {routes.map((route, index) => {
                        if (route.can) {
                            const can = route.can.map(action => check(action))
                                             .reduce((isAllowed, check) => isAllowed ? check : false, true)

                            if (!can) {
                                return null
                            }
                        }

                        if (route.redirect) {
                            return (<Redirect key={index} exact={route.exact} from={route.from} to={route.to}/>)
                        }

                        if (route.auth) {
                            return (<PrivateRoute key={index} exact={route.exact} path={route.path} component={route.component}/>)
                        }

                        return (<Route key={index} exact={route.exact} path={route.path} component={route.component}/>)
                    })}
                </Switch>
            </div>
        </Fragment>
    )
}

export default Routes
