import { createAction } from "redux-actions"
import * as types       from "./type"

export const setOnline = createAction(types.SET_ONLINE)
export const setOffline = createAction(types.SET_OFFLINE)

export const systemBooting = createAction(types.SYSTEM_BOOTING)
export const systemBooted = createAction(types.SYSTEM_BOOTED)
export const systemBootFailed = createAction(types.SYSTEM_BOOT_FAILED)
export const systemReboot = createAction(types.SYSTEM_REBOOT)

export const apiStart = createAction(types.API_START)
export const apiSuccess = createAction(types.API_SUCCESS)
export const apiFailed = createAction(types.API_FAILED)

export const dbStart = createAction(types.DB_START)
export const dbSuccess = createAction(types.DB_SUCCESS)
export const dbFailed = createAction(types.DB_FAILED)

export const startLoader = createAction(types.LOADER_START)
export const stopLoader = createAction(types.LOADER_STOP)
export const addLoaderMessage = createAction(types.LOADER_ADD_MESSAGE)
export const completeLoaderMessage = createAction(types.LOADER_COMPLETE_MESSAGE)
export const failedLoaderMessage = createAction(types.LOADER_FAILED_MESSAGE)
export const clearLoaderMessage = createAction(types.LOADER_CLEAR_MESSAGE)
export const clearLoaderMessages = arrayOfMessages => dispatch => {
    arrayOfMessages.forEach(message => {
        dispatch(clearLoaderMessage(message))
    })
}

export const addNotification = createAction(types.NOTIFICATION_ADD)
export const removeNotification = createAction(types.NOTIFICATION_REMOVE)
export const clearAllNotification = createAction(types.NOTIFICATION_CLEAR_ALL)

export const setTranslations = createAction(types.TRANSLATION_SET)
export const clearTranslations = createAction(types.TRANSLATION_CLEAR)
export const setLocale = createAction(types.SET_LOCALE)
