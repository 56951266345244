import { isEmpty }         from "lodash"
import PropTypes           from "prop-types"
import React, { Fragment } from "react"
import { useTrans }        from "../../../hooks"

const FormSalutation = ({ value: inputData, onChange, isPreview, errors, locale }) => {
    const honorifics = ["mr", "ms", "mrs"]
    const { trans } = useTrans()

    if (!isEmpty(errors)) {console.error(errors)}

    inputData = {
        specific: { honorific: "mr", name: "", address: "" },
        type: "specific",
        ...inputData,
    }

    const handleOnSpecificChange = event => {
        const data = { ...inputData.specific }
        data[event.target.name] = event.target.value

        onChange({
            name: "salutation",
            value: { ...inputData, specific: { ...data } },
        })
    }

    const handleSalutationChange = event => {
        onChange({
            name: "salutation",
            value: { ...inputData, type: event.target.value },
        })
    }

    return (
        <Fragment>
            {!isPreview && (
                <div className="salutation-select no-print">
                    <p className="salutation-title">{trans("general.select_salutation", {locale})}</p>
                    <div className="salutation-options">
                        <div className="salutation-option">
                            <input
                                type="radio"
                                id="salutation-specific"
                                name="salutation"
                                value="specific"
                                checked={inputData.type === "specific"}
                                onChange={handleSalutationChange}
                            />
                            <label htmlFor="salutation-specific">
                            {trans("general.specific_salutation", {locale})}
                            </label>
                        </div>

                        <div className="salutation-option">
                            <input
                                type="radio"
                                id="salutation-general"
                                name="salutation"
                                value="general"
                                checked={inputData.type === "general"}
                                onChange={handleSalutationChange}
                            />
                            <label htmlFor="salutation-general">{trans("general.general_salutation",{locale})}</label>
                        </div>
                    </div>
                </div>
            )}

            <div className="content-header">
                {inputData.type === "general" ? (
                    <p className="title bold underline">{trans("general.salutation_concern", {locale})}</p>
                ) : (
                    <Fragment>
                        {isPreview ? (
                            <Fragment>
                                <p>
                                    <span className="form-input-view">
                                        {trans(
                                            `general.honorifics.${inputData.specific.honorific}`, {locale}
                                        )}
                                    </span>
                                    &nbsp;
                                    {inputData.specific.name && (
                                        <span className="form-input-view">
                                            {inputData.specific.name}
                                        </span>
                                    )}
                                    ,
                                </p>
                                {inputData.specific.address && (
                                    <span className="form-input-view">
                                        {inputData.specific.address} ।
                                    </span>
                                )}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <p>
                                    <select
                                        name="honorific"
                                        value={inputData.specific.honorific}
                                        onChange={handleOnSpecificChange}
                                    >
                                        <option value=""></option>
                                        {honorifics.map(
                                            (honorific, honorificIndex) => (
                                                <option
                                                    key={`honorific-${honorificIndex}`}
                                                    value={honorific}
                                                >
                                                    {trans(
                                                        `general.honorifics.${honorific}`, {locale}
                                                    )}
                                                </option>
                                            ),
                                        )}
                                    </select>
                                    <input
                                        className="form-input"
                                        type="text"
                                        name="name"
                                        value={inputData.specific.name}
                                        onChange={handleOnSpecificChange}
                                    />
                                </p>
                                <p>
                                    <input
                                        className="form-input"
                                        type="text"
                                        name="address"
                                        value={inputData.specific.address}
                                        onChange={handleOnSpecificChange}
                                        style={{ width: "240px" }}
                                    />
                                </p>
                            </Fragment>
                        )}
                    </Fragment>
                )}
            </div>
        </Fragment>
    )
}

FormSalutation.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormSalutation
