import { groupBy }                   from "lodash"
import { PermissionDeniedException } from "../../exceptions"
import {
    apiCall,
    call,
    dbCall,
    log,
}                                    from "../../helpers"
import { sifarishPolicy }            from "../../policies"
import {
    SifarishDBService,
    SifarishService,
}                                    from "../../services/sifarish"
import { socket }                    from "../../services/SocketService"
import { setSifarishList }           from "../reducers/sifarish/action"

export const syncSifarishListWithSocket = ({ municipality, ward, sifarish }, onSuccess) => () => {
    socket.emit("syncSifarishList", { municipality, ward, sifarish }, response => {
        call(onSuccess, response)
    })
}

export const fetchSifarishListFromApi = (filter, onSuccess, onError = null) => dispatch => {
    dispatch(apiCall({
        onRequest: () => SifarishService.getList(filter),
        onSuccess: ({ data: sifarish }) => {
            call(onSuccess, sifarish)
        },
        onError: error => {
            call(onError, error)
        },
    }))
}

export const pushSifarishToApi = (sifarish, onSuccess, onError) => dispatch => {
    const data = {
        "id": sifarish.id,
        "municipality_name": sifarish.municipality_name,
        "ward": sifarish.ward,
        "form_name": sifarish.form_name,
        "app_index": sifarish.app_index,
        "form_data": { ...sifarish.form_data , "parent_sifarish_id" : sifarish.parent_sifarish_id },
        "citizen": { ...sifarish.citizen },
    }

    dispatch(apiCall({
        onRequest: () => SifarishService.saveSifarishForm(data),
        onSuccess, onError,
    }))
}

export const fetchSifarishListFromDB = (filter, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => SifarishDBService.getSifarishList(filter),
        onSuccess: sifarish => {
            dispatch(setSifarishList(sifarish))
            call(onSuccess, sifarish || [])
        },
        onError: error => {
            dispatch(log("Fetching sifarish list from DB failed.", error))

            call(onError, error)
        },
    }))
}

export const setSifarishListToDB = (sifarish, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => SifarishDBService.saveSifarishList(sifarish, true),
        onSuccess, onError,
    }))
}

export const createSifarishInDB = (data, onSuccess, onError) => dispatch => {
    const sifarishId = (new Date()).getTime()
    const sifarishData = {
        citizen: {
            citizenship: data.citizen.citizenship,
            citizenship_district: data.citizen.citizenship_district,
            id: (new Date()).getTime(),
            metadata: {
                address: data.citizen.address,
                contact: data.citizen.contact,
                email: data.citizen.email,
                documents: data.citizen.documents,
            },
            name: data.citizen.name,
        },
        id: sifarishId,
        parent_sifarish_id :data.parent_sifarish_id || null,
        form_data: data.form_data,
        form_name: data.form_name,
        municipality: data.municipality,
        municipality_name: data.municipality.name,
        ward: data.ward,
        is_synced: false,
        app_index: sifarishId,
        created_at: (new Date()).toISOString(),
        updated_at: (new Date()).toISOString(),
    }
    dispatch(dbCall({
        onRequest: () => SifarishDBService.createSifarish(sifarishData),
        onSuccess: sifarish => {
            dispatch(log("Sifarish created.", sifarish))
            call(onSuccess, sifarishData)
        },
        onError: error => {
            dispatch(log(`Sifarish create failed.`, error))
            call(onError, error)
        },
    }))
}

export const updateSifarishInDB = ({ sifarish, rev }, onSuccess, onError = null) => dispatch => {
    dispatch(dbCall({
        onRequest: () => SifarishDBService.updateSifarish(sifarish, rev),
        onSuccess, onError,
    }))
}

export const fetchSifarishById = (sifarishId, onSuccess, onError = null) => (dispatch, getState) => {
    const response = (state, sifarish, onSuccess, onError) => {
        if (sifarishPolicy.view(state, sifarish)) {
            call(onSuccess, sifarish)
            return
        }

        call(onError, new PermissionDeniedException())
    }
    const sifarishFromStore = getState()
        .sifarish
        .sifarishList
        .data
        .find(sifarish => sifarish.id === sifarishId)

    if (sifarishFromStore) {
        response(getState(), sifarishFromStore, onSuccess, onError)
        return
    }

    dispatch(dbCall({
        onRequest: () => SifarishDBService.getSifarishById(sifarishId),
        onSuccess: sifarish => {
            response(getState(), sifarish, onSuccess, onError)
        },
        onError: error => {
            dispatch(log(`Fetching sifarish from DB with id: ${sifarishId} failed.`, error))
            call(onError, error)
        },
    }))
}

export const sifarishStatsByForm = (onSuccess, onError) => (dispatch, getState) => {
    dispatch(dbCall({
        onRequest: () => SifarishDBService.getSifarishList(),
        onSuccess: sifarishList => {
            const filteredSifarishList = sifarishList.filter(sifarish => sifarishPolicy.view(getState(), sifarish))

            const sifarishByForms = groupBy(filteredSifarishList, "form_name")
            const stats = Object.entries(sifarishByForms)
                                .reduce((statsByForm, [formName, sifarishList]) => {
                                    statsByForm[formName] = {
                                        count: sifarishList.length,
                                    }

                                    return statsByForm
                                }, {})
            call(onSuccess, stats)
        },
        onError: error => {
            dispatch(log("Error occurred while fetching sifarish list for stats.", error))
            call(onError, error)
        },
    }))
}

export const fetchSifarishPaginatedListFromDB = (pageNo, onSuccess, onError) => (dispatch, getState) => {
    dispatch(dbCall({
        onRequest: () => SifarishDBService.getSifarishPaginatedList(getState(), pageNo),
        onSuccess: sifarish => {
            dispatch(setSifarishList(sifarish.data))
            call(onSuccess, sifarish || [])
        },
        onError: error => {
            dispatch(log("Fetching sifarish list from DB failed.", error))

            call(onError, error)
        },
    }))
}

