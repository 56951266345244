import {
    MUNICIPALITY_ADMIN,
    OFFICERS,
    SUPER_ADMIN,
    WARD_ADMIN,
} from "./Constants/UserRoles"

export const ACTIONS = {
    DASHBOARD: {
        MODULES: "dashboard/modules",
    },

    SIFARISH: {
        MODULES: "sifarish/modules",
        VIEW: "sifarish/view",
        ADD: "sifarish/add",
        FILTER: "sifarish/filter",
    },

    USERS: {
        MODULES: "users/modules",
        EDIT: "users/edit",
        DELETE: "users/delete",
        CHANGE_PASSWORD: "users/change-password",
    },

    WARD_PROFILE: {
        MODULES: "ward-profile/modules",
    },
    LAYOUT: {
        MODULES: "layout/modules",
        VIEW: "layout/view",
        EDIT: "layout/edit",
    },

}

const globalPolicies = {
    static: [ACTIONS.SIFARISH.VIEW],
    dynamic: {
        [ACTIONS.USERS.EDIT]: ({ user, currentUser }) => {
            return currentUser.role !== user.role
        },

        [ACTIONS.USERS.DELETE]: ({ user, currentUser }) => {
            return currentUser.role !== user.role
        },

        [ACTIONS.USERS.CHANGE_PASSWORD]: ({ user, currentUser }) => {
            return currentUser.role !== user.role
        },
    },
}

const superAdminPolicies = {
    static: [ACTIONS.USERS.MODULES],
    dynamic: {},
}

const municipalityAdminPolicies = {
    static: [ACTIONS.USERS.MODULES],
    dynamic: {},
}

const wardAdminPolicies = {
    static: [
        ACTIONS.SIFARISH.ADD,
        ACTIONS.USERS.MODULES,
        ACTIONS.WARD_PROFILE.MODULES,
        ACTIONS.LAYOUT.VIEW,
        ACTIONS.LAYOUT.EDIT],
    dynamic: {},
}

const wardOfficersPolicies = {
    static: [ACTIONS.SIFARISH.ADD, ACTIONS.WARD_PROFILE.MODULES, ACTIONS.LAYOUT.VIEW],
    dynamic: {},
}

export const POLICIES = {
    GLOBAL: globalPolicies,
    [SUPER_ADMIN]: superAdminPolicies,
    [MUNICIPALITY_ADMIN]: municipalityAdminPolicies,
    [WARD_ADMIN]: wardAdminPolicies,
    [OFFICERS]: wardOfficersPolicies,
}
