export default {
    header: {},
    footer: {},
    salutation: {},

    applicant: {},
    address: {},
    ward: "",
    previous_address: {},
    owner: {},
    date: "",
    citizenship: "",
    citizenshipDistrict: "",
    citizenshipDate: "",
    residenceDetail: [{}],
}
