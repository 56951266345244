import {
    COMPLETE,
    FAILED,
    PENDING,
}                 from "../../../config/Constants/StatusTypes"
import {
    pushToArray,
    removeFromArrayByIndex,
    removeFromArrayByKey,
    updateObjectByKey,
}                 from "../../../helpers"
import * as types from "./type"

const initialState = {
    isOnline: false,
    checkedOnline: false,

    isBooted: false,

    apiCalls: 0,
    dbCalls: 0,

    isLoading: false,

    notifications: [],
    translations: {
        en: {},
        ne: {},
    },
    currentLocale: "ne",

    loadingMessages: [
        {
            message: "loading",
            show: false,
            status: PENDING,
        },
    ],
}

const connectionReducer = (state, { type }) => {
    switch (type) {
        case types.SET_ONLINE:
            return { ...state, isOnline: true, checkedOnline: true }

        case types.SET_OFFLINE:
            return { ...state, isOnline: false, checkedOnline: true }

        default:
            return state
    }
}

const bootReducer = (state, { type }) => {
    switch (type) {
        case types.SYSTEM_BOOTING:
            return { ...state, isBooted: false }

        case types.SYSTEM_BOOTED:
            return { ...state, isBooted: true }

        case types.SYSTEM_BOOT_FAILED:
            return { ...state, isBooted: false }

        default:
            return state
    }
}

const apiReducer = (state, { type }) => {
    switch (type) {
        case types.API_START:
            return {
                ...state,
                apiCalls: state.apiCalls + 1,
                isLoading: state.apiCalls >= 0,
            }

        case types.API_SUCCESS:
            return {
                ...state,
                apiCalls: state.apiCalls - 1,
                isLoading: state.apiCalls >= 1,
            }

        case types.API_FAILED:
            return {
                ...state,
                apiCalls: state.apiCalls - 1,
                isLoading: state.apiCalls >= 1,
            }

        default:
            return state
    }
}

const dbReducer = (state, { type }) => {
    switch (type) {
        case types.DB_START:
            return {
                ...state,
                dbCalls: state.dbCalls + 1,
                isLoading: state.dbCalls >= 0,
            }

        case types.DB_SUCCESS:
            return {
                ...state,
                dbCalls: state.dbCalls - 1,
                isLoading: state.dbCalls >= 1,
            }

        case types.DB_FAILED:
            return {
                ...state,
                dbCalls: state.dbCalls - 1,
                isLoading: state.dbCalls >= 1,
            }

        default:
            return state
    }
}

const loaderReducer = (state, { type, payload }) => {
    switch (type) {
        case types.LOADER_START:
            return {
                ...state, loadingMessages: pushToArray(state.loadingMessages, {
                    message: "loading",
                    status: PENDING,
                    show: false,
                    created_at: (new Date()).getTime(),
                }),
            }

        case types.LOADER_STOP:
            return {
                ...state, loadingMessages: removeFromArrayByKey(
                    state.loadingMessages,
                    { key: "message", value: "loading" },
                ),
            }

        case types.LOADER_ADD_MESSAGE:
            return {
                ...state, loadingMessages: pushToArray(state.loadingMessages, {
                    message: payload,
                    status: PENDING,
                    show: true,
                    created_at: (new Date()).getTime(),
                }),
            }

        case types.LOADER_COMPLETE_MESSAGE:
            return {
                ...state, loadingMessages: updateObjectByKey(
                    state.loadingMessages,
                    { key: "message", value: payload },
                    { status: COMPLETE },
                ),
            }

        case types.LOADER_FAILED_MESSAGE:
            return {
                ...state, loadingMessages: updateObjectByKey(
                    state.loadingMessages,
                    { key: "message", value: payload },
                    { status: FAILED },
                ),
            }

        case types.LOADER_CLEAR_MESSAGE:
            return {
                ...state, loadingMessages: removeFromArrayByKey(
                    state.loadingMessages,
                    { key: "message", value: payload },
                ),
            }

        default:
            return state
    }
}

const notificationReducer = (state, { type, payload }) => {
    switch (type) {
        case types.NOTIFICATION_ADD:
            return { ...state, notifications: pushToArray(state.notifications, payload) }

        case types.NOTIFICATION_REMOVE:
            return { ...state, notifications: removeFromArrayByIndex(state.notifications, payload) }

        case types.NOTIFICATION_CLEAR_ALL:
            return { ...state, notifications: [] }

        default:
            return state
    }
}

const translationReducer = (state, { type, payload }) => {
    switch (type) {
        case types.TRANSLATION_SET:
            return {
                ...state, translations: {
                    en: payload.en,
                    ne: payload.ne,
                },
            }

        case types.TRANSLATION_CLEAR:
            return {
                ...state, translations: {
                    en: {},
                    ne: {},
                },
            }

        case types.SET_LOCALE:
            return { ...state, currentLocale: payload }

        default:
            return state
    }
}

const systemReducers = (state = initialState, action) => {
    state = connectionReducer(state, action)
    state = bootReducer(state, action)
    state = apiReducer(state, action)
    state = dbReducer(state, action)
    state = loaderReducer(state, action)
    state = notificationReducer(state, action)
    state = translationReducer(state, action)

    return state
}

export default systemReducers
