import React           from "react"
import { useSelector } from "react-redux"

const Loading = () => {
    const loadingMessages = useSelector(state => state.system.loadingMessages)

    return (
        <div className="loader-container">
            <div className="loader">
                <div className="dot"/>
                <div className="dot"/>
                <div className="dot"/>
                <div className="dot"/>
                <div className="dot"/>
            </div>
            <ul className="loading-message">
                {loadingMessages.map((loadingMessage, index) => {
                    if (!loadingMessage.show) {
                        return ""
                    }

                    return (
                        <li key={index} className={`${loadingMessage.status} loading-animation`}>{loadingMessage.message}</li>
                    )
                })}
            </ul>
        </div>
    )
}

export default Loading
