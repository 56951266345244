import PouchDB from "pouchdb"

class StaticDataDBService {
    constructor() {
        this.db = new PouchDB("static-data", { adapter: "idb" })
    }

    async getTranslations() {
        return await this.db.get("translations")
    }

    async setTranslations({ en, ne }) {
        let oldTrans = null

        try {
            oldTrans = await this.getTranslations()
        } catch (error) {
            console.error("No translations in database.", error)
        }

        return await this.db.put({
            _id: "translations",
            _rev: oldTrans ? oldTrans._rev : null,
            en, ne,
        })
    }

    async getStaticData() {
        return await this.db.get("static-data")
    }

    async setStaticData(staticData) {
        let dataInDB = null

        try {
            dataInDB = await this.getStaticData()
        } catch (error) {
            console.error("No static data in database.", error)
        }

        return await this.db.put({
            _id: "static-data",
            _rev: dataInDB ? dataInDB._rev : null,
            ...staticData,
        })
    }
}

export default (new StaticDataDBService())
