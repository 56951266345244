import PouchDB     from "pouchdb"
import PouchDBFind from "pouchdb-find"

class LayoutDBService {
    constructor() {
        PouchDB.plugin(PouchDBFind)
        this.db = new PouchDB("layout", { adapter: "idb" })
    }

    async getLayout(type, municipality, ward) {
        try {
            return await this.db.get(`${type}_layout_${municipality}_ward${ward}`)
        } catch (e) {
            console.error(e)
            return null
        }
    }

    async setLayout(type, layoutData) {
        const { municipality, ward } = layoutData
        const data = {
            ...layoutData,
            _id: `${type}_layout_${municipality}_ward${ward}`,
        }

        try {
            const oldLayout = await this.getLayout(type, municipality, ward)
            data["_rev"] = oldLayout._rev
            data["created_at"] = oldLayout["created_at"]
            data["updated_at"] = Math.floor(Date.now() / 1000)
            data["layout_updated_at"] = (new Date()).toISOString()
        } catch (error) {
            data["created_at"] = Math.floor(Date.now() / 1000)
        }

        return await this.db.put(data)
    }

    async getBothLayout(municipality, ward) {
        try {
            let checkHeaderData = await this.checkDocumentInDb(`header_layout_${municipality}_ward${ward}`)
            let checkFooterData = await this.checkDocumentInDb(`footer_layout_${municipality}_ward${ward}`)

            let data = { header: checkHeaderData, footer: checkFooterData }       
        
            return data
        } catch (e) {
            console.error(e)
            return null
        }
    }

    async setBothLayout(layoutData) {
        layoutData.map(async (layout)=>{
            const {municipality,ward,type} = layout
            const data = {
            ...layout,
            _id: `${type}_layout_${municipality}_ward${ward}`,
        }
        try {
            const oldLayout = await this.getLayout(type, municipality, ward)
            if(oldLayout){
                data["_rev"] = oldLayout._rev
                data["created_at"] = oldLayout["created_at"]
                data["updated_at"] = Math.floor(Date.now() / 1000)
                data["layout_updated_at"] = (new Date()).toISOString()
            }
        } catch (error) {
            data["created_at"] = Math.floor(Date.now() / 1000)
        }
        return await this.db.put(data,{"force":true}) 
        
        })
    }

    async checkDocumentInDb(docId){
        try{
            let document = await this.db.get(docId)
            if(document){
                return this.db.get(docId)
            }
        }
        catch(error){
            return null;
        }

    }
}

export default (new LayoutDBService())
