import {
    isEmpty,
    omit,
} from "lodash"
import PropTypes from "prop-types"
import React     from "react"

const FormFieldText = (props) => {
    const { isPreview, value, name, onChange, errors, hideBlank } = props

    const inputProps = omit(props, ["isPreview", "value", "name", "onChange", "errors", "hideBlank"])

    if (!isEmpty(errors)) {console.error(errors)}

    return isPreview ? (
        <span className="form-input-view">{value || (hideBlank ? "" : "..............")}</span>
    ) : (
        <input {...inputProps} className="form-input form-input-lg" type="text" name={name} value={value || ""} onChange={event => onChange({ name: event.target.name, value: event.target.value })}/>
    )
}

FormFieldText.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldText
