export default {
    header: {},
    salutation: {},
    footer: {},

    ward: "",
    previous_address: {},
    previous_ward: "",
    company_name: "",
}
