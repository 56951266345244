export default {
    header: {},
    footer: {},
    salutation: {},

    applicant_ward: "",
    previous_address: {},
    applicant: {},
    propertyDetails: [{}],
}
