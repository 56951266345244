import {
    applyMiddleware,
    compose,
    createStore,
} from "redux"

import thunk    from "redux-thunk"
import {
    apiMiddleware,
    dbMiddleware,
    logMiddleware,
}               from "./middleware"
import reducers from "./reducers"

const initialStore = {}
const middleware = []
const enhancers = []

middleware.push(thunk)
middleware.push(logMiddleware)
middleware.push(apiMiddleware)
middleware.push(dbMiddleware)

const composedEnhancers = compose(
    applyMiddleware(...middleware),
    ...enhancers,
)

export default createStore(reducers, initialStore, composedEnhancers)
