export default {
    header: {},
    footer: {},
    salutation: {},

    father: "",
    mother: "",
    child_type: "",
    current_ward: "",
    previous_address: {},
    name: {},
    birth_date: "",
    location: "",
}
