import { get }         from "lodash"
import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldKitta,
    FormFieldLandArea,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const KittakaatForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = {
        ...defaultFormData,
        current_ward: get(staticData, "ward"),
        ...formData,
    }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="कित्ताकाट सिफारिस।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    {trans(`municipalities.${staticData.municipality.name}`)}{" "}
                    वडा नं{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    अन्तर्गत{" "}
                    <FormFieldName
                        value={formData.name}
                        name="name"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                        errors={errors}
                    />
                    को नाममा श्रेस्ता दर्ता कायम रहेको तल उल्लेखित विवरणको
                    घर-जग्गामध्य{" "}
                    <FormFieldText
                        value={formData.direction}
                        name="direction"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.location")}
                        errors={errors}
                    />{" "}
                    तर्फबाट{" "}
                    <FormFieldLandArea
                        value={formData.area}
                        name="area"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    क्षेत्रफल जग्गा कित्ताकाट/प्लट मिलान गर्न प्राविधिक निरीक्षण
                    गर्दा मापदण्ड अनुसार मिल्ने देखिएको हुनाले सोको लागि सिफारिस
                    गरिन्छ ।
                </div>

                <FormTable
                    title={trans("general.description_of_the_land")}
                    name="landDetails"
                    structure={[
                        {
                            name: "sheet",
                            width: "20%",
                            label: "सिट नं",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "kitta",
                            width: "20%",
                            label: "कित्ता नं",
                            component: FormFieldKitta,
                            defaultValue: "",
                        },
                        {
                            name: "area",
                            width: "20%",
                            label: "क्षेत्रफल",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                    ]}
                    value={formData.landDetails}
                    isPreview={isPreview}
                    onChange={handleOnChange}
                    errors={errors}
                />
                <div className="sifarish-info-lists">
                    <p className="title bold underline">
                        कित्ताकाट सिफारिस फिल्ड निरीक्षण प्रतिवेदन
                    </p>
                    <div>
                        <div>
                            <span className="sifarish-info-list-label">
                                घर बनेको जग्गाको क्षेत्रफल:
                            </span>
                            <FormFieldText
                                value={formData.land_area}
                                name="land_area"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                घर जम्मा क्षेत्रफल:
                            </span>
                            <FormFieldText
                                value={formData.total_area}
                                name="total_area"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                घरको भुई तल्लाको क्षेत्रफल:
                            </span>
                            <FormFieldText
                                value={formData.ground_floor_area}
                                name="ground_floor_area"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                पाउने फार:
                            </span>
                            <FormFieldText
                                value={formData.location}
                                name="location"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                सिफारिस दिन मिल्ने कारण:
                            </span>
                            <FormFieldText
                                value={formData.sifarish_reason}
                                name="sifarish_reason"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <p style={{ marginBottom: 0 }}>सिफारिस गर्ने:</p>
                        <div>
                            <span className="sifarish-info-list-label">
                                प्राबिधिकको नाम:
                            </span>
                            <FormFieldText
                                value={formData.engineer_name}
                                name="engineer_name"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            />
                        </div>
                        <div>
                            <span className="sifarish-info-list-label">
                                प्राबिधिकको हस्ताक्षर:
                            </span>
                            {/* <FormFieldText
                                value={formData.engineer_signature}
                                name="engineer_signature"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                            /> */}
                        </div>
                    </div>
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default KittakaatForm
