export default {
    header: {},
    footer: {},
    salutation: {},

    partner_name: {},
    ownership_type: "",
    current_ward: "",
    previous_address: {},
    kitta: "",
    area: "",
    ward: "",
    previous_ward: "",
    applicant: {},
    citizenship_no: "",
    issued_date: "",
    father_name: "",
    grand_father_name: "",
}
