import HttpService from "../HttpService"

class MunicipalityService {
    fetchWardProfile = async (municipality, ward) => {
        const { body } = await HttpService.get(`/municipalities/${municipality}/wards/${ward}/profile`)

        return body.data
    }

    saveWardProfile = async (profile, { municipality, ward }) => {
        const { body } = await HttpService.post(`/municipalities/${municipality}/wards/${ward}/profile`, profile)

        return body
    }
}

export default (new MunicipalityService())
