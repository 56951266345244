import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldLocalGovernment,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const ScholarshipForPoorForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }
    const { trans } = useTrans()
    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="विपन्नको सिफारिस सम्बन्धमा ।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त विषयमा{" "}
                    <FormFieldLocalGovernment
                        value={formData.municipality}
                        name="municipality"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    वडा नं.{" "}
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />
                    <FormFieldPreviousAddress
                        errors={errors}
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    निवासी{" "}
                    <FormFieldText
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                        errors={errors}
                    />
                    ले मेरो आर्थिक स्थिती
                    नाजुक भई उच्च शिक्षाको खर्च जुटाउन
                    असमर्थ भएकोले सो खुलाई सिफारिस पाउँ भनी यस कार्यालयमा निवेदन
                    पेश गरेकोले, सो सम्बन्धमा बुझ्दा जानेबुझे सम्म व्यहोरा मनासिव
                    भएकोले निजलाई विपन्न व्यक्तिका लागि आरक्षित गरिएको स्थानमा
                    सहभागी हुन पाउने व्यवस्था गरी दिनुहुन सिफारिस गरिएको छ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default ScholarshipForPoorForm
