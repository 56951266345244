import { isEmpty }  from "lodash"
import PropTypes    from "prop-types"
import React        from "react"
import {
    removeFromArrayByIndex,
    updateObjectByIndex,
}                   from "../../../helpers"
import { useTrans } from "../../../hooks"

const FormTable = (props) => {
    const { name: tableName, title, structure, value: data, isPreview, onChange, errors, locale } = props
    const { trans, numberTrans } = useTrans()

    if (!isEmpty(errors)) {console.error(errors)}

    const addRow = (event) => {
        event.preventDefault()

        onChange({ name: tableName, value: [...data, {}] })
    }

    const removeRow = index => event => {
        event.preventDefault()

        onChange({ name: tableName, value: removeFromArrayByIndex(data, index) })
    }

    const handleRowInputChange = (index, fieldName, value) => {
        onChange({ name: tableName, value: updateObjectByIndex(data, index, { [fieldName]: value }) })
    }

    return (
        <div className="table-responsive">
            <p className="title bold">{title}</p>

            <table className="table table-bordered table-sm">
                <thead>
                    <tr>
                        <th style={{ width: "7%" }}>{trans("general.s_n", {locale})}</th>
                        {structure && structure.length && structure.map((header, index) => (
                            <th key={index} style={{ width: header.width }}>{header.label}</th>
                        ))}
                        {!isPreview && <th style={{ width: "4%" }}/>}
                    </tr>
                </thead>

                <tbody>
                    {data && data.length && data.map((rowData, rowIndex) => (
                        <tr key={rowIndex}>
                            <td style={{ textAlign: "center" }}>{numberTrans(rowIndex + 1, {locale})}</td>

                            {structure && structure.length && structure.map((col, colIndex) => {
                                const Component = col.component

                                return (
                                    <td key={colIndex}>
                                        {Component && (
                                            <Component
                                                name={col.name}
                                                value={data[rowIndex][col.name] || col.defaultValue}
                                                onChange={({ name, value }) => handleRowInputChange(rowIndex, name, value)}
                                                errors={{}}
                                                isPreview={isPreview}/>
                                        )}
                                    </td>
                                )
                            })}

                            {!isPreview && (
                                <td style={{ textAlign: "center" }}>
                                    {data.length > 1 && (
                                        <button className="btn btn-sm btn-danger" onClick={removeRow(rowIndex)} style={{ padding: "1px 2px", lineHeight: "0.875rem" }}>&times;</button>
                                    )}
                                </td>
                            )}
                        </tr>
                    ))}
                </tbody>
            </table>

            {!isPreview && (
                <button className="btn btn-sm btn-secondary" onClick={addRow}>{trans("general.add_another", {locale})}</button>
            )}
        </div>
    )
}

FormTable.propTypes = {
    name: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    structure: PropTypes.array.isRequired,
    value: PropTypes.array.isRequired,
    isPreview: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormTable
