import { get }                 from "lodash"
import React, {
    Fragment,
    useCallback,
    useEffect,
    useState,
}                              from "react"
import {
    useDispatch,
    useSelector,
}                              from "react-redux"
import { Link }                from "react-router-dom"
import { useTrans }            from "../../hooks"
import { sifarishStatsByForm } from "../../store/actions/SifarishActions"
import FormCategory            from "./partials/FormCategory"

const SifarishFormList = () => {
    const [selectedCategory, setSelectedCategory] = useState("all")
    const [filteredForms, setFilteredForms] = useState([])
    const [statsByForm, setStatsByForm] = useState({})

    const forms = useSelector(state => state.sifarish.forms)
    const dispatch = useDispatch()
    const { trans } = useTrans()

    useEffect(() => {
        dispatch(sifarishStatsByForm(stats => {
            setStatsByForm(stats)
        }))
    }, [dispatch])

    useEffect(() => {
        if (selectedCategory === "all") {
            setFilteredForms(forms)
        } else {
            setFilteredForms(forms.filter(form => form.category === selectedCategory))
        }
    }, [forms, selectedCategory])

    const handleOnCategorySelect = category => {
        setSelectedCategory(category)
    }

    const getStatsByFormName = useCallback(formName => {
        return get(statsByForm, `${formName}.count`, 0)
    }, [statsByForm])

    return (
        <Fragment>
            <h2 className="page-title">{trans("sifarish.sifarish_forms")}</h2>

            <div className="main-container">
                <FormCategory selectedCategory={selectedCategory} onChange={handleOnCategorySelect}/>

                <div className="content">
                    <ul className="list-container">
                        {filteredForms && filteredForms.map((form, formIndex) => (
                            <li key={formIndex} className="list-item">
                                <div className="list-item-wrapper card">
                                    <Link to={`/admin/sifarish/forms/${form.name}/create`}>{trans(`sifarish.forms.${form.name}`)}</Link>
                                    <div className="total-item-indicator">
                                        {getStatsByFormName(form.name)}
                                    </div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </Fragment>
    )
}

export default SifarishFormList
