import React          from "react"
import Municipalities from "../../../config/Municipalities"

const FormLayoutFooter = ({ staticData, layoutData }) => {
    const { municipality, wardProfile } = staticData
    const municipalityData = Municipalities[municipality.name]
    const slogan = wardProfile.slogan || null

    if (layoutData && layoutData.footer) {
        return (
            <div className="sifarish-form-footer sifarish-form-footer-print">
                <div>
                    <img src={layoutData.footer} className="" alt="" style={{ "width": "100%" }}/>
                </div>
            </div>
        )
    }

    if (!municipalityData) {
        return ""
    }

    return (
        <div className="sifarish-form-footer sifarish-form-footer-print">
            <div>
                {municipalityData.phoneNo && <span>फोन नं.: {municipalityData.phoneNo}</span>}
                {municipalityData.faxNo && <span>फ्याक्स नं.: {municipalityData.faxNo}</span>}
                {municipalityData.email && <span>ईमेल: {municipalityData.email}</span>}
                {municipalityData.website && <span>वेबसाइट: {municipalityData.website}</span>}
            </div>
            <div>
                {slogan}
            </div>
        </div>
    )
}

export default FormLayoutFooter
