import React            from "react"
import { ACTIONS }      from "../../config/PermissionConfig"
import { PageNotFound } from "../../pages/errors"
import {
    UserCreate,
    UserDetail,
    UserEdit,
    UserList,
}                       from "../../pages/users"

export default prefix => ([
    {
        auth: true,
        path: `${prefix}/`,
        exact: true,
        component: UserList,
        hasSubHeader: true,
        can: [ACTIONS.USERS.MODULES],
    },

    {
        auth: true,
        path: `${prefix}/add`,
        exact: true,
        component: UserCreate,
        hasSubHeader: true,
        can: [ACTIONS.USERS.MODULES],
    },

    {
        auth: true,
        path: `${prefix}/:id/edit`,
        exact: true,
        component: (props) => {
            const userId = props.match.params.id
            if (!userId || isNaN(userId)) {
                return <PageNotFound/>
            }

            return (
                <UserEdit {...props} userId={userId}/>
            )
        },
        hasSubHeader: true,
        can: [ACTIONS.USERS.MODULES],
    },

    {
        auth: true,
        path: `${prefix}/:id`,
        exact: true,
        component: (props) => {
            const userId = props.match.params.id
            if (!userId || isNaN(userId)) {
                return <PageNotFound/>
            }

            return (
                <UserDetail {...props} userId={userId}/>
            )
        },
        hasSubHeader: true,
        can: [ACTIONS.USERS.MODULES],
    },
])
