import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const RecommendationForClassroomOperation = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { municipality } = staticData
    const { trans } = useTrans()

    formData = {
        ...defaultFormData,
        salutation: {
            type: "specific",
            specific: {
                honorific: "mr",
                name: "शिक्षा शाखा",
                address: `${trans(
                    `municipalities.${municipality.name}`,
                )} को कार्यालय`,
            },
        },
        ...formData,
    }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="सिफारिस सम्बन्धमा ।"
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="body-top">
                    <div className="form-text-container">
                        उपरोक्त सम्बन्धमा यस{" "}
                        {trans(`municipalities.${municipality.name}`)} वडा नं{" "}
                        <FormFieldWard
                            value={formData.ward}
                            name="ward"
                            isPreview={isPreview}
                            errors={errors}
                            onChange={handleOnChange}
                        />{" "}
                        बस्ने{" "}
                        <FormFieldName
                            value={formData.applicant}
                            name="applicant"
                            isPreview={isPreview}
                            errors={errors}
                            onChange={handleOnChange}
                        />{" "}
                        ले दिनु भएको निवेदन अनुसार वडा नं{" "}
                        <FormFieldWard
                            value={formData.school_ward}
                            name="school_ward"
                            isPreview={isPreview}
                            errors={errors}
                            onChange={handleOnChange}
                        />{" "}
                        मा रहेको श्री{" "}
                        <FormFieldText
                            value={formData.school_name}
                            name="school_name"
                            isPreview={isPreview}
                            errors={errors}
                            placeholder={trans("general.school_name")}
                            onChange={handleOnChange}
                        />{" "}
                        स्कुलले कक्षा{" "}
                        <FormFieldText
                            value={formData.school_class}
                            name="school_class"
                            isPreview={isPreview}
                            errors={errors}
                            placeholder={trans("general.school_class")}
                            onChange={handleOnChange}
                        />{" "}
                        सञ्‍चालनका लागि पर्याप्त भौतिक सुविधा संरचना, खेल मैदान र
                        उपयुक्त ठाँउ भएकोले सो विद्यालयलाई{" "}
                        <FormFieldText
                            value={formData.school_class}
                            name="school_class"
                            errors={errors}
                            isPreview={true}
                            placeholder={trans("general.school_class")}
                            onChange={handleOnChange}
                        />{" "}
                        कक्षा सञ्‍चालन गर्नका लागि अनुमतिको आवश्यकता परेको हुदा
                        सम्बन्धित निकायमा सिफारिस गरी पाउँ भनी मिति{" "}
                        <FormFieldDate
                            value={formData.date}
                            name="date"
                            isPreview={isPreview}
                            errors={errors}
                            onChange={handleOnChange}
                        />{" "}
                        गते यस कार्यालयमा निवेदन दिनु भएको हुदा निवेदकको माग
                        बमोजिम थप कक्षा अनुमतीका लागि सिफारिस साथ अनुरोध छ ।
                    </div>
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                isPreview={isPreview}
                errors={errors}
                value={formData.footer}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default RecommendationForClassroomOperation
