import { useState } from "react"

const useInput = initialValue => {
    const [value, setValue] = useState(initialValue)

    const handleOnChange = event => {
        const element = event.target

        if (element.type === "checkbox") {
            return setValue(element.checked)
        }

        return setValue(element.value)
    }

    return {
        value,
        setValue,
        reset: () => setValue(""),
        bind: {
            value,
            onChange: handleOnChange,
        },
    }
}

export default useInput
