import { get }         from "lodash"
import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const CourtFeeForm = ({
                          staticData,
                          formData,
                          onChange,
                          isPreview,
                          errors,
                      }) => {
    formData = {
        ...defaultFormData,
        current_ward: get(staticData, "ward"),
        ...formData,
    }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="सिफारिस सम्बन्धमा ।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा{" "}
                    {trans(`municipalities.${staticData.municipality.name}`)}{" "}
                    वडा नं{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    बस्ने{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                        errors={errors}
                    />
                    ले{" "}
                    <FormFieldName
                        value={formData.name}
                        name="name"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                        errors={errors}
                    />{" "}
                    सँग श्री <FormFieldText
                    value={formData.court_name}
                    name="court_name"
                    isPreview={isPreview}
                    onChange={handleOnChange}
                    placeholder="अदालतको नाम"
                    errors={errors}
                /> जिल्ला अदालतमा{" "}
                    <FormFieldText
                        value={formData.case_type}
                        name="case_type"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder="केसको प्रकार"
                        errors={errors}
                    />{" "}
                    मुद्दा चलिरहेकोमा निजको आयश्रोत केही नभई
                    आर्थिक अवस्था कमजोर भई कोर्ट-फि राख्न असमर्थ भ​एकोले
                    तत्कालको लागि कोर्ट-फि नराखी पछि मुद्दा फैसला भ​एपछि उक्त
                    कोर्ट-फि लिने गरी आवश्यक कारवाहीको लागि " सिफारिस गरी पाउँ"
                    भनी यस वडा कार्यालयमा निवेदन दिनुभएको हुँदा सो सम्बन्धमा
                    मिति{" "}
                    <FormFieldDate
                        value={formData.date}
                        name="date"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                        errors={errors}
                    />{" "}
                    मा गरिएको सर्जमिन अनुसार व्यहोरा मनासिव बुझिएकोले
                    त्यहाको नियमानुसार गरिदिनुहुन सिफारिस गरिन्छ ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default CourtFeeForm
