import * as types from "./type"

const initialState = {
    currentUser: {},
    token: null,
    isAuthenticated: false,

    login: {
        isLoading: false,
        validationErrors: {},
    },
}

const LoginReducer = (state, { type, payload }) => {
    switch (type) {
        case types.AUTH_LOGIN_START:
            return { ...state, isLoading: true }

        case types.AUTH_LOGIN_STOP:
            return { ...state, isLoading: false }

        case types.AUTH_LOGIN_SET_VALIDATIONS_ERRORS:
            return { ...state, validationErrors: payload }

        case types.AUTH_LOGIN_CLEAR_VALIDATIONS_ERRORS:
            return { ...state, validationErrors: {} }

        default:
            return state
    }
}

const AuthReducer = (state = initialState, { type, payload }) => {
    state = { ...state, login: LoginReducer(state, { type, payload }) }

    switch (type) {
        case types.AUTH_SET_USER:
            return { ...state, currentUser: payload }

        case types.AUTH_SET_TOKEN:
            return { ...state, token: payload, isAuthenticated: !!payload }

        default:
            return state
    }
}

export default AuthReducer
