import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { fetchUserById, updateUser } from "../../store/actions/UserActions"
import UserForm from "./partials/UserForm"
import {useTrans} from "../../hooks"

const UserEdit = ({ userId, history }) => {
    const [user, setUser] = useState([])
    const [validationErrors, setValidationErrors] = useState({})

    const {trans} = useTrans()
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(
            fetchUserById(userId, userData => {
                setUser(userData)
            }),
        )
    }, [dispatch, userId])

    const handleFormSubmit = userData => {
        dispatch(
            updateUser(
                userId,
                userData,
                () => {
                    history.push(`/admin/users/${userId}`)
                },
                errors => {
                    setValidationErrors(errors)
                },
            ),
        )
    }

    return (
        <div>
            <div className="user-page-header">
                <h3 className="page-title">{trans("general.edit_user")}</h3>
            </div>

            <UserForm userData={user} onSubmit={handleFormSubmit} errors={validationErrors} isEdit={true} />
            <div></div>
        </div>
    )
}

export default UserEdit
