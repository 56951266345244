import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldLocalGovernment,
    FormFieldNumber,
    FormFieldSelect,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const InternalMigration = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { municipality, ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="आन्तरिक बसाई सराई ।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    तपाई{" "}
                    <FormFieldText
                        value={formData.houseOwner}
                        name="houseOwner"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                        errors={errors}
                    />{" "}
                    <FormFieldSelect
                        isPreview={isPreview}
                        name="migrationType"
                        value={formData.migrationType}
                        onChange={handleOnChange}
                        errors={errors}
                        options={{
                            single: trans("general.single"),
                            with_family: trans("general.with_family"),
                        }}
                    />{" "}
                    मिति{" "}
                    <FormFieldDate
                        value={formData.migrationDate}
                        name="migrationDate"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    देखि{" "}
                    <FormFieldText
                        value={formData.exDistrict}
                        name="exDistrict"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.district_name")}
                        errors={errors}
                    />{" "}
                    जिल्ला{" "}
                    <FormFieldLocalGovernment
                        isPreview={isPreview}
                        value={formData.exLocalGovernment}
                        name="exLocalGovernment"
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    वडा नं{" "}
                    <FormFieldWard
                        value={formData.exWard}
                        name="exWard"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.Ward")}
                        errors={errors}
                    />
                    बाट यस{" "}
                    <span className="font-weight-bold">
                        {trans(`municipalities.${municipality.name}`)}
                    </span>{" "}
                    वडा नं{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    अन्तर्गत{" "}
                    <FormFieldText
                        value={formData.currentAddress}
                        name="currentAddress"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.location")}
                        errors={errors}
                    />{" "}
                    मा बसाई सरी आउनुभएको व्यहोरा प्रमाणित गरिन्छ ।
                </div>

                {formData.migrationType === "with_family" && (
                    <FormTable
                        name="familyDetails"
                        title=""
                        structure={[
                            {
                                name: "full_name",
                                width: "25%",
                                label: "नाम थर",
                                component: FormFieldText,
                                defaultValue: "",
                            },
                            {
                                name: "relation_with_applicant",
                                width: "15%",
                                label: "निवेदक संगको नाता",
                                component: FormFieldText,
                                defaultValue: "",
                            },
                            {
                                name: "identification_number",
                                width: "15%",
                                label: "ना.प्र.नं. / जन्मदर्ता नं.",
                                component: FormFieldText,
                                defaultValue: "",
                            },
                            {
                                name: "house_no",
                                width: "10%",
                                label: "घर नं.",
                                component: FormFieldText,
                                defaultValue: "",
                            },
                            {
                                name: "name_of_road",
                                width: "10%",
                                label: "बाटोको नाम",
                                component: FormFieldText,
                                defaultValue: "",
                            },
                            {
                                name: "age",
                                width: "10%",
                                label: "उमेर",
                                component: FormFieldNumber,
                                defaultValue: "",
                            },
                        ]}
                        value={formData.familyDetails}
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />
                )}
            </div>

            <FormFooter
                staticData={staticData}
                isPreview={isPreview}
                value={formData.footer}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default InternalMigration
