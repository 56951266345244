import HttpService from "../HttpService"

class ElectronApiService {
    registerApp = async (data) => {
        const { body } = await HttpService.post(`/electron/register`, data)

        return body.data
    }

    fetchWardUsersWithCredential = async (municipality, ward) => {
        const { body } = await HttpService.get(`/electron/municipalities/${municipality}/wards/${ward}/users`)

        return body.data
    }

    fetchWardProfile = async (municipality, ward) => {
        const { body } = await HttpService.get(`/electron/municipalities/${municipality}/wards/${ward}/profile`)

        return body.data
    }
}

export default (new ElectronApiService())
