import Callback from "../pages/callback/callback"
import { PageNotFound } from "../pages/errors"
import adminRoutes from "./AdminRoutes"
import authRoutes from "./AuthRoutes"
import publicRoutes from "./PublicRoutes"
import React, { Component } from "react"

export default [
    {
        redirect: true,
        from: "/",
        to: "/login",
        exact: true,
    },

    ...authRoutes,
    ...adminRoutes("/admin"),
    ...publicRoutes("/public"),
    {
        path: "/sso/callback",
        auth: false,
        exact: true,
        component: props => {
            return <Callback {...props} />
        },
    },
    {
        path: "*",
        component: PageNotFound,
    },
]
