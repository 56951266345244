import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldKitta,
    FormFieldLandArea,
    FormFieldName,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const HomeCollapsedCertified = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }

    const { municipality } = staticData
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="घर पाताल प्रमाणित"
                errors={errors}
                onChange={handleOnChange}
            />


            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा {trans(`municipalities.${municipality.name}`)} वडा नं.{" "}
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> स्थित{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    को नाममा रहेको कि.नं.{" "}
                    <FormFieldKitta
                        value={formData.kitta}
                        name="kitta"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> क्षेत्रफल{" "}
                    <FormFieldLandArea
                        value={formData.area}
                        name="area"
                        isPreview={isPreview}
                        errors={errors}
                        placeholder={trans("general.area")}
                        onChange={handleOnChange}
                    /> मा बनेको घर भत्किन गई पाताल भएको सोही अनुसारको सिफारिस गरी पाउँ भनी यस वडा कार्यालयमा पर्न आएको निवेदन सम्बन्धमा उल्लेखित कि.नं.{" "}
                    <FormFieldKitta
                        value={formData.kitta}
                        name="kitta"
                        isPreview={true}
                        errors={errors}
                        onChange={handleOnChange}
                    /> मा बनेको घर पाताल भएको व्यहोराको सिफारिस गरिन्छ |
                </div>
            </div>

            <FormFooter staticData={staticData} errors={errors} isPreview={isPreview} value={formData.footer} onChange={handleOnChange}/>
        </div>
    )
}

export default HomeCollapsedCertified
