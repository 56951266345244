import React, {
    Fragment,
    useCallback,
    useEffect,
}                 from "react"
import {
    useDispatch,
    useSelector,
}                 from "react-redux"
import { auth }   from "../../helpers"
import {
    useInput,
    useTrans,
}                 from "../../hooks"
import {
    fetchWardProfile,
    saveWardProfile,
}                 from "../../store/actions/MunicipalityActions"
import { notify } from "../../store/actions/SystemActions"

const WardProfileForm = () => {
    const {
        value: chairman,
        bind: bindChairman,
        setValue: setChairman,
    } = useInput("")
    const {
        value: actingChairman,
        bind: bindActingChairman,
        setValue: setActingChairman,
    } = useInput("")
    const {
        value: secretary,
        bind: bindSecretary,
        setValue: setSecretary,
    } = useInput("")
    const {
        value: address,
        bind: bindAddress,
        setValue: setAddress,
    } = useInput("")
    const {
        value: slogan,
        bind: bindSlogan,
        setValue: setSlogan,
    } = useInput("")

    const wardProfile = useSelector(state => state.municipality.wardProfile)
    const municipality = useSelector(auth.getCurrentMunicipality)
    const ward = useSelector(auth.getCurrentWard)

    const { trans, numberTrans } = useTrans()
    const dispatch = useDispatch()

    const setFormData = useCallback(profile => {
        setChairman(profile.chairman || "")
        setActingChairman(profile.acting_chairman || "")
        setSecretary(profile.secretary || "")
        setAddress(profile.address || "")
        setSlogan(profile.slogan || "")
    }, [setChairman, setActingChairman, setSecretary, setAddress, setSlogan])

    useEffect(() => {
        dispatch(fetchWardProfile(municipality.name, ward))
    }, [dispatch, municipality, ward])

    useEffect(() => {
        setFormData(wardProfile)
    }, [wardProfile, setFormData])

    const handleSave = useCallback(event => {
        event.preventDefault()
        const updated_at = (new Date()).toISOString()
        const profile = { chairman, acting_chairman: actingChairman, secretary, address, slogan ,updated_at}
        dispatch(saveWardProfile(profile, { municipality: municipality.name, ward }, ({ message }) => {
            dispatch(notify(message))
        }))

    }, [dispatch, chairman, actingChairman, secretary, municipality, ward, address, slogan])

    return (
        <Fragment>
            <div className="page-header-container">
                <h3 className="page-title">{trans("general.ward_profile")}</h3>
            </div>
            <div className="form-container-small">
                <form className="form" onSubmit={handleSave}>
                    <div className="form-group">
                        <label className="form-label" htmlFor="ward_no">{trans("general.ward_no")}:</label>
                        <span style={{ marginLeft: "20px" }}>
                            {numberTrans(ward)}
                        </span>
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="chairman">
                            {trans("general.officers.chairman")}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="chairman"
                            name="chairman"
                            {...bindChairman}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="actingChairman">
                            {trans("general.officers.acting_chairman")}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="actingChairman"
                            name="actingChairman"
                            {...bindActingChairman}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="secretary">
                            {trans("general.officers.secretary")}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="secretary"
                            name="secretary"
                            {...bindSecretary}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="address">
                            {trans("general.address")}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="address"
                            name="address"
                            {...bindAddress}
                        />
                    </div>
                    <div className="form-group">
                        <label className="form-label" htmlFor="slogan">
                            {trans("general.slogan")}
                        </label>
                        <input
                            className="form-control"
                            type="text"
                            id="slogan"
                            name="slogan"
                            {...bindSlogan}
                        />
                    </div>

                    <div className="form-actions">
                        <button
                            type="submit"
                            className="btn-outline btn-primary"
                        >
                            {trans("general.save")}
                        </button>
                    </div>
                </form>
            </div>
        </Fragment>
    )
}

export default WardProfileForm
