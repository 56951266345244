import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldChildType,
    FormFieldCurrentAddress,
    FormFieldDate,
    FormFieldGrandChildType,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const MarriageCertified = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="विवाह प्रमाणित"
                errors={errors}
                onChange={handleOnChange}/>

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा श्री{" "}
                    <FormFieldText
                        errors={errors}
                        value={formData.first_party_grand_father}
                        name="first_party_grand_father"
                        placeholder={trans("general.grand_father_name")}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>को{" "}
                    <FormFieldGrandChildType
                        errors={errors}
                        value={formData.first_party_grand_child_type}
                        name={"first_party_grand_child_type"}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>,{" "}
                    श्री{" "}
                    <FormFieldText
                        errors={errors}
                        value={formData.first_party_father}
                        name="first_party_father"
                        placeholder={trans("general.father_name")}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    तथा श्रीमती{" "}
                    <FormFieldText
                        errors={errors}
                        value={formData.first_party_mother}
                        name="first_party_mother"
                        placeholder={trans("general.mother_name")}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>को{" "}
                    <FormFieldChildType
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        value={formData.first_party_child_type}
                        name={"first_party_child_type"}/>,{" "}
                    <FormFieldCurrentAddress
                        errors={errors}
                        value={formData.first_party_address}
                        name={"first_party_address"}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    <FormFieldPreviousAddress
                        errors={errors}
                        value={formData.first_party_previous_address}
                        name={"first_party_previous_address"}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    निवासी{" "}
                    <FormFieldName
                        errors={errors}
                        value={formData.first_party}
                        name="first_party"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}/>{" "}
                    र श्री{" "}
                    <FormFieldText
                        errors={errors}
                        value={formData.second_party_grand_father}
                        name="second_party_grand_father"
                        placeholder={trans("general.grand_father_name")}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>को{" "}
                    <FormFieldGrandChildType
                        errors={errors}
                        value={formData.second_party_grand_child_type}
                        name={"second_party_grand_child_type"}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>,{" "}
                    <FormFieldText
                        errors={errors}
                        value={formData.second_party_father}
                        name="second_party_father"
                        placeholder={trans("general.father_name")}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    तथा श्रीमती{" "}
                    <FormFieldText
                        errors={errors}
                        value={formData.second_party_mother}
                        name="second_party_mother"
                        placeholder={trans("general.mother_name")}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>को{" "}
                    <FormFieldChildType
                        errors={errors}
                        value={formData.second_party_child_type}
                        name={"second_party_child_type"}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>,{" "}
                    <FormFieldCurrentAddress
                        errors={errors}
                        value={formData.second_party_current_address}
                        name={"second_party_current_address"}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    <FormFieldPreviousAddress
                        errors={errors}
                        value={formData.second_party_previous_address}
                        name={"second_party_previous_address"}
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    निवासी{" "}
                    <FormFieldName
                        errors={errors}
                        value={formData.second_party}
                        name="second_party"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}/>{" "}
                    बीच मिति{" "}
                    <FormFieldDate
                        errors={errors}
                        value={formData.marriage_date}
                        name={"marriage_date"}
                        isPreview={isPreview}
                        onChange={handleOnChange}/> मा सामाजिक परम्परा अनुसार विवाह भएको{" "}
                    व्यहोरा प्रमाणित गरिन्छ ।
                </div>
            </div>

            <FormFooter
                errors={errors}
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}/>
        </div>
    )
}

export default MarriageCertified
