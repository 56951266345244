import React   from "react"
import useTabs from "../../hooks/useTabs"

const Tabs = props => {
    const { activeTab, selectTab } = useTabs(2)

    return (
        <div className='tabs-container'>
            <div className='tabs'>
                {props.children.map((child, i) => (
                    <span
                        className={`tab ${(activeTab === i && 'tab-active')}`}
                        children={child.props.title}
                        onClick={() => selectTab(i)}
                        key={`tab_${i}`}
                    />
                ))}
            </div>
            <div className='tabs-content'>
                {props.children.filter((child, i) => activeTab === i)}
            </div>
        </div>
    )
}

export default Tabs
