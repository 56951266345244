import { ACTIONS }         from "../config/PermissionConfig"
import { DataDumper }      from "../pages/data-dumper"
import { PageNotFound }    from "../pages/errors"
import { LayoutBuilder }   from "../pages/layout-builder"
import { ManualSync }      from "../pages/manual-sync"
import { WardProfileForm } from "../pages/ward-profile"
import dashboardRoutes     from "./admin/DashboardRoutes"
import profileRoutes       from "./admin/ProfileRoutes"
import sifarishRoutes      from "./admin/SifarishRoutes"
import userRoutes          from "./admin/UserRoutes"
import { NewDataDumper }      from "../pages/data-dumper"

export default prefix => ([
    {
        redirect: true,
        exact: true,
        from: prefix,
        to: `${prefix}/dashboard`,
    },

    ...dashboardRoutes(`${prefix}/dashboard`),
    ...sifarishRoutes(`${prefix}/sifarish`),
    ...userRoutes(`${prefix}/users`),
    ...profileRoutes(`${prefix}/profile`),

    {
        auth: true,
        path: `${prefix}/ward-profile`,
        exact: true,
        component: WardProfileForm,
        hasSubHeader: true,
        can: [ACTIONS.WARD_PROFILE.MODULES],
    },

    {
        auth: true,
        path: `${prefix}/data-dumper`,
        exact: true,
        component: DataDumper,
        hasSubHeader: false,
    },

    {
        auth: true,
        path: `${prefix}/sync`,
        exact: true,
        component: ManualSync,
        hasSubHeader: true,
    },

    {
        auth: true,
        path: `${prefix}/layout-builder`,
        exact: true,
        component: LayoutBuilder,
        hasSubHeader: true,
        can: [ACTIONS.LAYOUT.VIEW],
    },

    {
        auth: true,
        path: `${prefix}/new-data-dumper`,
        exact: true,
        component: NewDataDumper,
        hasSubHeader: false,
    },

    {
        auth: true,
        path: `${prefix}/*`,
        component: PageNotFound,
    },
])
