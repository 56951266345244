import React             from "react"
import * as images       from "../../../assets/image"
import { useTrans }      from "../../../hooks"

const FormLayoutHeader = ({ staticData, locale, formdata }) => {
    const { municipality, ward } = staticData
    const { trans, numberTrans } = useTrans()

    return (
        <div className={`sifarish-form-header sifarish-form-header-print ${locale ? locale : ""}`}>
            {
                staticData.layoutData && staticData.layoutData.header ?
                <>
                    <img src={staticData.layoutData.header} className="" alt="Form header" style={{ "width": "100%" }}/>
                         </>
                :
                <>
                    <img src={images.govLogo} className="gov-logo" alt=""/>
                    {municipality.name === 'mellekh' && <img src={images.mellekhLogo} className="visit-nepal" alt=""/>}

                    <p className="municipality">{trans(`municipalities.${municipality.name}`, { locale })}</p>
                    <p className="ward">
                        {numberTrans(ward, { locale })} {trans("general.no_ward_office", { locale })}
                    </p>
                    <p className="address">
                        {formdata.header && formdata.header.address ? formdata.header.address : "............."}, {" "}
                        {municipality.name !== 'mellekh' && (trans(`municipalities.city.${municipality.name}`, { locale }) + ", ")}
                        {trans(`municipalities.district.${municipality.district}`, { locale })}
                    </p>
                    <p className="address">
                        {trans(`municipalities.province_${municipality.province}`, { locale })},{" "}
                        {trans(`municipalities.country_${municipality.country}`, { locale })}
                    </p>
                </>
            }
            <div className={`letterpad-info ${locale}`}>
                {locale === "en" ? (
                    <p>Reference No:</p>
                ) : (
                    <>
                        <p>प.सं.:</p>
                        <p>च.नं.:</p>
                    </>
                )}
            </div>
        </div>
    )
}

export default FormLayoutHeader
