export default {
    header: {},
    footer: {},
    salutation: {},

    permanent_address: {},
    ward: "",
    current_address: "",
    former_address: "",
    applicant_name: {},
    relations: [{}],
}
