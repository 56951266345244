import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldKitta,
    FormFieldLocalGovernment,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldSelect,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormObligation,
    FormTable,
} from "../../fields"
import defaultFormData from "./formData"

const DharaJadanForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="धारा जडान सिफारिस"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div>
                    <div className="form-text-container">
                        <FormFieldLocalGovernment
                            value={formData.municipality}
                            name="municipality"
                            isPreview={isPreview}
                            onChange={handleOnChange}
                            errors={errors}
                        />{" "}
                        वडा नं.{" "}
                        <FormFieldWard
                            value={formData.ward}
                            name="ward"
                            isPreview={isPreview}
                            onChange={handleOnChange}
                            errors={errors}
                        />{" "}
                        <FormFieldPreviousAddress
                            errors={errors}
                            value={formData.previous_address}
                            name="previous_address"
                            isPreview={isPreview}
                            onChange={handleOnChange}/>{" "}
                        बस्ने{" "}
                        <FormFieldName
                            value={formData.applicant}
                            name="applicant"
                            isPreview={isPreview}
                            onChange={handleOnChange}
                            placeholder={trans("general.name")}
                            errors={errors}
                        />{" "}
                        को नाममा दर्ता कायम रहेको कि.नं.{" "}
                        <FormFieldKitta
                            value={formData.kitta}
                            name="kitta"
                            isPreview={isPreview}
                            onChange={handleOnChange}
                            errors={errors}
                        />{" "}
                        को जग्गामा मिति{" "}
                        <FormFieldDate
                            name="establishment_date"
                            value={formData.establishment_date}
                            isPreview={isPreview}
                            onChange={handleOnChange}
                            errors={errors}
                        />{" "}
                        मा भवन निर्माण स्वीकृति लिनु भई{" "}
                        <FormFieldSelect
                            name={"property_status"}
                            options={{ partial: trans("general.partial"), complete: trans("general.complete") }}
                            isPreview={isPreview}
                            value={formData.property_status}
                            onChange={handleOnChange}
                            errors={errors}
                        />{" "}
                        रुपमा निर्माण सम्पन्न गर्नुभएकाे वा अभिलेखीकरण गर्नुभएकाे हुँदा निजलाई{" "}
                        <FormFieldText
                            value={formData.water_connection}
                            name="water_connection"
                            isPreview={isPreview}
                            onChange={handleOnChange}
                            errors={errors}
                        />{" "}
                        खानेपानी लाईन जडान गरिदिनु हुन सिफारिससाथ अनुराेध गरिन्छ ।
                    </div>

                    <FormTable
                        title="कित्ता नं काे विवरण"
                        name="propertyList"
                        structure={[
                            {
                                name: "kitta_no",
                                width: "30%",
                                label: "कित्ता नं.",
                                component: FormFieldText,
                                defaultValue: "",
                            },
                        ]}
                        value={formData.propertyList}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                isPreview={isPreview}
                value={formData.footer}
                errors={errors}
                onChange={handleOnChange}
            />

            <div className="footer-note">
                <FormObligation
                    value={formData.obligation}
                    isPreview={isPreview}
                    onChange={handleOnChange}
                    errors={errors}
                />
            </div>
        </div>
    )
}

export default DharaJadanForm
