import {
    get,
    has,
    isEmpty,
}                           from "lodash"
import PropTypes            from "prop-types"
import React, { Fragment }  from "react"
import { useTrans }         from "../../../hooks"
import LocalGovernmentLevel from "./partials/LocalGovernmentLevel"

const FormFieldLocalGovernment = ({ isPreview, value, name, onChange, errors, locale, isPrevious }) => {
    const { trans } = useTrans()

    if (!isEmpty(errors)) {console.error(errors)}

    return isPreview ? (
        <span className="form-input-view">
            {has(value, "name") && value.name && (
                <Fragment>
                    {has(value, "name") && value.name}{" "}
                    <span className="form-input-view">{has(value, "level") && trans(`municipalities.administrative_division.${get(value, "level")}`, {locale})}</span>
                </Fragment>
            )}
        </span>
    ) : (
        <LocalGovernmentLevel
            inputData={value}
            name={name}
            onChange={onChange}
            locale={locale}
            isPrevious={isPrevious}
        />
    )
}

FormFieldLocalGovernment.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldLocalGovernment
