export const LOG = "system/log"

export const SET_ONLINE = "system/set_online"
export const SET_OFFLINE = "system/set_offline"

export const SYSTEM_BOOTING = "system/booting"
export const SYSTEM_BOOTED = "system/booted"
export const SYSTEM_BOOT_FAILED = "system/boot_failed"
export const SYSTEM_REBOOT = "system/reboot"

export const API_REQUEST = "system/api_request"
export const API_START = "system/api_start"
export const API_SUCCESS = "system/api_success"
export const API_FAILED = "system/api_failed"

export const DB_REQUEST = "system/db_request"
export const DB_START = "system/db_start"
export const DB_SUCCESS = "system/db_success"
export const DB_FAILED = "system/db_failed"

export const LOADER_START = "system/start_loader"
export const LOADER_STOP = "system/stop_loader"
export const LOADER_ADD_MESSAGE = "system/loader_add_message"
export const LOADER_COMPLETE_MESSAGE = "system/loader_complete_message"
export const LOADER_FAILED_MESSAGE = "system/loader_failed_message"
export const LOADER_CLEAR_MESSAGE = "system/loader_clear_message"

export const NOTIFICATION_ADD = "system/notification_add"
export const NOTIFICATION_REMOVE = "system/notification_remove"
export const NOTIFICATION_CLEAR_ALL = "system/notification_clear_all"

export const TRANSLATION_SET = "system/translation_set"
export const TRANSLATION_CLEAR = "system/translation_clear"
export const SET_LOCALE = "system/set_locale"
