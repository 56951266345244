import {
    get,
    has,
} from "lodash"
import React, {
    useCallback,
    useEffect,
    useState,
} from "react"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css" // This only needs to be imported once in your app

const defaultFormData = {
    name: "",
    citizenship: "",
    citizenship_district: "",
    email: "",
    contact: "",
    address: "",
    documents: []
}

const CitizenInfoForm = ({ formData: citizenData, onChange, errors, isPreview }) => {
    const [images, setImages] = useState([]);
    const formData = { ...defaultFormData, ...citizenData }
    const currentDocuments = get(citizenData, 'metadata.documents') || [];
    const [isInitialRender, setIsInitialRender] = useState(true);
    const validImageMimes = ["image/png", "image/jpg", "image/jpeg"]
    const [img, setImg] = useState({ index: 0, isOpen: false });

    const readImage = (image) => {
        let reader = new FileReader();

        try {
            reader.readAsDataURL(image)
            reader.onload = event => {
                setImages(previousImages => [...previousImages, event.target.result])
            };
        } catch (e) {
            console.error(e)
        }
    }

    useEffect(() => {
        if (isInitialRender) {
            for (let i = 0; i < currentDocuments.length; i++) {
                readImage(currentDocuments[i])
            }
            setIsInitialRender(false)
        }
    }, [currentDocuments])

    const removeImage = (index) => {
        const documents = formData.documents.filter((document, key) => index !== key)
        setImages(previousImages => previousImages.filter((image, key) => index !== key))
        onChange({ ...formData, documents })
    }

    const handleOnChange = useCallback(event => {
        const { name, value } = event.target

        if (event.target.files) {
            let files = event.target.files;

            for (let i = 0; i < files.length; i++) {
                if (validImageMimes.includes(files[i].type)) {
                    readImage(files[i])
                    formData.documents.push(files[i])
                }
            }

            onChange({ ...formData })

        } else {
            onChange({ ...formData, [name]: value })
        }
    }, [formData, onChange])

    const showImg = (i) => {
        console.log('img')
        setImg({
            index: i,
            isOpen: false
        })
    }

    return (
        <div className="sifarish-form-container no-print">
            {!isPreview && <h3>नागरिकको जानकारी (कार्यालय प्रयोजनको लागि मात्र)</h3>}
            <p className="form-error">{get(errors, "citizen")}</p>

            {!isPreview && <form className="form row">
                <div className={`form-group col-xs-12 col-md-6 ${has(errors, "citizen.name") && "has-error"}`}>
                    <label htmlFor="citizen-name" className="form-label">नाम:</label>
                    <input type="text" className="form-control " id="citizen-name" name="name" value={formData.name}
                           onChange={handleOnChange} readOnly={isPreview}/>
                    <span className="form-error">{get(errors, "citizen.name")}</span>
                </div>

                <div className={`form-group col-xs-12 col-md-6 ${has(errors, "citizen.email") && "has-error"}`}>
                    <label htmlFor="citizen-email" className="form-label">ईमेल:</label>
                    <input type="email" className="form-control " id="citizen-email" name="email" value={formData.email}
                           onChange={handleOnChange} readOnly={isPreview}/>
                    <span className="form-error">{get(errors, "citizen.email")}</span>
                </div>

                <div className={`form-group col-xs-12 col-md-6 ${has(errors, "citizen.citizenship") && "has-error"}`}>
                    <label htmlFor="citizen-citizenship" className="form-label">नागरिकता नम्बर :</label>
                    <input type="text" className="form-control " id="citizen-citizenship" name="citizenship"
                           value={formData.citizenship} onChange={handleOnChange} readOnly={isPreview}/>
                    <span className="form-error">{get(errors, "citizen.citizenship")}</span>
                </div>

                <div className={`form-group col-xs-12 col-md-6 ${has(errors, "citizen.citizenship_district") &&
                "has-error"}`}>
                    <label htmlFor="citizen-citizenship-district" className="form-label">जारी गरिएको जिल्ला:</label>
                    <input type="text" className="form-control " id="citizen-citizenship-district"
                           name="citizenship_district" value={formData.citizenship_district} onChange={handleOnChange}
                           readOnly={isPreview}/>
                    <span className="form-error">{get(errors, "citizen.citizenship_district")}</span>
                </div>

                <div className={`form-group col-xs-12 col-md-6 ${has(errors, "citizen.contact") && "has-error"}`}>
                    <label htmlFor="citizen-contact" className="form-label">सम्पर्क नम्बर:</label>
                    <input type="text" className="form-control " id="citizen-contact" name="contact"
                           value={formData.contact} onChange={handleOnChange} readOnly={isPreview}/>
                    <span className="form-error">{get(errors, "citizen.contact")}</span>
                </div>

                <div className={`form-group col-xs-12 col-md-6 ${has(errors, "citizen.address") && "has-error"}`}>
                    <label htmlFor="citizen-address" className="form-label">ठेगाना:</label>
                    <input className="form-control" id="citizen-address" name="address" value={formData.address}
                           onChange={handleOnChange} readOnly={isPreview}/>
                    <span className="form-error">{get(errors, "citizen.address")}</span>
                </div>

                <div className={`form-group form-group-img-upload col-xs-12`}>
                    <div className="col-md-6">
                        <label htmlFor="citizen-documents" className="form-label">कागजात:</label>
                        {isPreview &&
                        <b className="form-label" style={{ margin: "2px" }}>{images.length} documents attached.</b>
                        }
                        {!isPreview &&
                        <div className={'file-input-block'}>
                            <div className="file-input-wrap">
                                <span className="file-input-layer">
                                    अपलोड
                                </span>

                                <input type={"file"} className="form-control" id="citizen-documents"
                                       name="documents"
                                       onChange={handleOnChange} multiple={true} readOnly={isPreview}/>
                            </div>
                            <span className="form-label"
                                  style={{ color: '#FF7F50' }}><em>* Upload image files only.</em></span>
                        </div>
                        }
                    </div>
                    <div className={`form-group col-xs-12`}>
                        {
                            images.map((image, index) => (
                                <template key={index} className="img-wrap">
                                    {!isPreview &&
                                    <span className="close" onClick={() => removeImage(index)}>&times;</span>
                                    }
                                    <img src={image} alt="document" height={"100px"}
                                         style={{ margin: "4px" }}/>
                                </template>
                            ))
                        }
                    </div>
                </div>
            </form>}

            {isPreview &&
            <div className="citizen-info">
                <h3>नागरिकको जानकारी</h3>
                <div className="info-block">
                    <span className={'info-label'}>
                        नाम :
                    </span>
                    <span className={'info-value'}>
                      {formData.name}
                    </span>
                </div>
                <div className="info-block">
                    <span className={'info-label'}>
                        ईमेल :
                    </span>
                    <span className={'info-value'}>
                      {formData.email}
                    </span>
                </div>
                <div className="info-block">
                    <span className={'info-label'}>
                        नागरिकता नम्बर :
                    </span>
                    <span className={'info-value'}>
                     {formData.citizenship}
                    </span>
                </div>
                <div className="info-block">
                    <span className={'info-label'}>
                    जारी गरिएको जिल्ला :
                    </span>
                    <span className={'info-value'}>
                     {formData.citizenship_district}
                    </span>
                </div>
                <div className="info-block">
                    <span className={'info-label'}>
                   सम्पर्क नम्बर :
                    </span>
                    <span className={'info-value'}>
                    {formData.contact}
                    </span>
                </div>

                <div className="info-block info-block-document">
                    <div>
                        <label className="form-label">कागजात :</label>
                        <b className="form-label">  {images.length ? images.length : "No"} documents attached.</b>
                    </div>
                    <div>

                        {
                            images.map((image, index) => (
                                <div key={index} className="img-wrap">
                                    <img src={image} alt="document" height={"100px"}
                                         onClick={() => setImg({ index: index, isOpen: true })}
                                         style={{ margin: "4px" }}/>
                                </div>
                            ))
                        }
                        <div>
                            {img.isOpen && (
                                <Lightbox
                                    mainSrc={images[img.index]}
                                    nextSrc={images[(img.index + 1) % images.length]}
                                    prevSrc={images[(img.index + images.length - 1) % images.length]}
                                    onCloseRequest={() => setImg({ ...img, isOpen: false })}
                                    onMovePrevRequest={() =>
                                        setImg({
                                            ...img,
                                            index: (img.index + images.length - 1) % images.length,
                                        })
                                    }
                                    onMoveNextRequest={() =>
                                        setImg({
                                            ...img,
                                            index: (img.index + 1) % images.length,
                                        })
                                    }
                                />
                            )}
                        </div>
                    </div>
                </div>
            </div>
            }
        </div>
    )
}

export default CitizenInfoForm
