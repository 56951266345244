import React, {useEffect, useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Link} from "react-router-dom"
import {Loading} from "../../components/loading"
import {Can} from "../../components/permissions"
import {ACTIONS} from "../../config/PermissionConfig"
import {auth, isOnline} from "../../helpers"
import {useTrans} from "../../hooks"
import {fetchAllUsers} from "../../store/actions/UserActions"
import {SUPER_ADMIN, MUNICIPALITY_ADMIN} from "../../config/Constants/UserRoles"
import ReactPaginate from 'react-paginate'
import {OfflineState} from "../../components/errors"

const UserList = () => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [users, setUsers] = useState([])
    const isSystemOnline = useSelector(isOnline)
    const [pagination, setPagination] = useState({})
    const [refreshList, setRefreshList] = useState(true)
    const [filter, setFilter] = useState({
        page: 1,
    })

    const currentUser = useSelector(auth.getCurrentUser)

    const {numberTrans, trans} = useTrans()
    const dispatch = useDispatch()

    const handlePageChange = pagination => {
        const page = pagination.selected + 1
        setFilter({...filter, page})
        setRefreshList(true)
    }

    useEffect(() => {
        if (refreshList && isSystemOnline) {
            dispatch(
                fetchAllUsers(filter, response => {
                    setUsers(response.data)
                    setPagination(response.metadata.pagination)
                    setIsLoaded(true)
                }),
            )
            setRefreshList(false)
        }
    }, [dispatch, refreshList, isSystemOnline, filter])

    if (!isSystemOnline) {
        return <OfflineState/>
    }

    if (!isLoaded) {
        return <Loading/>
    }

    return (
        <div>
            <div className="page-header-container">
                <h3 className="page-title">{trans("general.user_list")}</h3>

                <Link to="/admin/users/add" className="btn-outline btn-link">
                    + {trans("general.add_user")}
                </Link>
            </div>

            <div className="table-responsive">
                <table className="table form-list table-striped table-hover">
                    <thead>
                    <tr>
                        <th style={{width: "7%"}}>{trans("general.s_n")}</th>
                        <th style={{width: "20%"}}>{trans("general.full_name")}</th>
                        <th style={{width: "20%"}}>{trans("general.user_name")}</th>
                        <th style={{width: "15%"}}>{trans("general.role")}</th>

                        {currentUser.role === SUPER_ADMIN && (
                            <th style={{width: "20%"}}>
                                {trans("municipalities.administrative_division.municipality")}
                            </th>
                        )}
                        {(currentUser.role === SUPER_ADMIN || currentUser.role === MUNICIPALITY_ADMIN) && (
                            <th style={{width: "8%"}}>{trans("general.ward_no")}</th>
                        )}
                        <th style={{width: "10%"}}></th>
                    </tr>
                    </thead>

                    <tbody>
                    {users.map((user, index) => (
                        <tr key={index}>
                            <td>{numberTrans((filter.page - 1) * pagination.per_page + index + 1)}.</td>
                            <td>
                                {user.display_name}
                            </td>
                            <td>{user.username}</td>
                            <td>{trans(`general.roles.${user.role}`)}</td>

                            {currentUser.role === SUPER_ADMIN && (
                                <td>
                                    {user.municipality ? trans(`municipalities.${user.municipality.name}`) : "-"}
                                </td>
                            )}
                            {(currentUser.role === SUPER_ADMIN || currentUser.role === MUNICIPALITY_ADMIN) && (
                                <td>{user.ward ? numberTrans(user.ward) : "-"}</td>
                            )}
                            <td>
                                <Link to={`/admin/users/${user.id}`} className={"btn-link btn-view-form"}> प्रोफाइल
                                    हेर्नुहोस्</Link>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            {pagination.total > pagination.per_page && (
                <div className="pagination-container">
                    <ReactPaginate
                        previousLabel={"prev"}
                        nextLabel={"next"}
                        breakLabel={"..."}
                        breakClassName={"break-me"}
                        initialPage={filter.page - 1}
                        pageCount={pagination.total_pages}
                        onPageChange={handlePageChange}
                        marginPagesDisplayed={2}
                        pageRangeDisplayed={5}
                        containerClassName={"pagination-items"}
                        pageClassName={"pagination-item"}
                        previousClassName={"pagination-item prev"}
                        nextClassName={"pagination-item next"}
                        activeClassName={"active"}
                    />
                </div>
            )}
        </div>
    )
}

export default UserList
