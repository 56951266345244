import React from "react"
import { useTrans } from "../../../../hooks"
import {
    FormFieldChildType,
    FormFieldName,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormFieldLocalGovernment,
    FormTable,
    FormFieldText,
} from "../../fields"
import defaultFormData from "./formData"

const IncomeVerificationForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    const {ward } = staticData
    const { trans } = useTrans()

    formData = {
        ...defaultFormData,
        current_ward: ward,
        ...formData,
    }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="Verification of Annual Income/ Sources of Income"
                onChange={handleOnChange}
                locale="en"
            />

            <div className="body-content">
                <div className="form-text-container">
                    This is to certify that{" "}
                    <FormFieldName
                        value={formData.applicant_name}
                        name="applicant_name"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={trans("general.name", { locale: "en" })}
                    />{" "}
                    <FormFieldChildType
                        value={formData.child_type}
                        name="child_type"
                        errors={errors}
                        locale="en"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    of{" "}
                    <FormFieldName
                        value={formData.father}
                        name="father"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={trans("general.name", { locale: "en" })}
                    />{" "}
                    and{" "}
                    <FormFieldName
                        value={formData.mother}
                        name="mother"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={trans("general.name", { locale: "en" })}
                    />{" "}
                    permanent resident of{" "}
                    <FormFieldLocalGovernment
                        value={formData.permanent_address}
                        name="permanent_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={"permanent address"}
                    /> Ward No.
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                    />, (Former{" "}
                    <FormFieldText
                        value={formData.former_address}
                        name="former_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder="address"
                    />
                    ){" "}
                    <FormFieldText
                        value={formData.current_address}
                        name="current_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder="address"
                    />
                    , Nepal has the following sources of income:
                    <div className="table-without-title">
                        <FormTable
                            title=""
                            name="incomeSource"
                            structure={[
                                {
                                    name: "source_of_income",
                                    width: "20%",
                                    label: "Source of Income",
                                    component: FormFieldText,
                                    defaultValue: "",
                                },
                                {
                                    name: "earned_by",
                                    width: "20%",
                                    label: "Earned By",
                                    component: FormFieldText,
                                    defaultValue: "",
                                },
                                {
                                    name: "yearly_income",
                                    width: "20%",
                                    label: "Yearly Income(Nrs)",
                                    component: FormFieldText,
                                    defaultValue: "",
                                },
                                {
                                    name: "remarks",
                                    width: "20%",
                                    label: "Remarks",
                                    component: FormFieldText,
                                    defaultValue: "",
                                },
                            ]}
                            value={formData.incomeSource}
                            isPreview={isPreview}
                            onChange={handleOnChange}
                            errors={errors}
                            locale="en"
                        />
                    </div>
                    Total Annual Income in Nepalese Rupee:{" "}
                    <FormFieldText
                        value={formData.total_annual_income}
                        name="total_annual_income"
                        isPreview={isPreview}
                        errors={errors}
                        locale="en"
                        onChange={handleOnChange}
                    />
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                errors={errors}
                isPreview={isPreview}
                onChange={handleOnChange}
                locale="en"
            />
        </div>
    )
}

export default IncomeVerificationForm
