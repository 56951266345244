import axios                      from "axios"
import qs                         from "qs"
import { config as systemConfig } from "../config"
import { auth }                   from "../helpers"
import DataCacheService           from "./DataCacheService"

const cache = new DataCacheService()
const Http = axios.create({
    baseURL: `${systemConfig("app.baseUrl")}/api`,
})

class HttpService {
    get = async (endpoint, params = {}, caching = true) => {
        caching = systemConfig("app.apiCache") ? caching : false

        const config = {
            headers: {
                "Content-Type":
                    "application/x-www-form-urlencoded;charset=utf-8",
            },
            params: params,
        }

        await this.setAuthHeaders()

        let response = {}
        const key = encodeURIComponent(endpoint + JSON.stringify(params))

        try {
            if (caching && cache.has(key)) {
                response = cache.get(key)
            } else {
                response = await Http.get(endpoint, config)
                cache.set({ key, value: response })
            }
        } catch (error) {
            return this.onError(error)
        }

        return this.onSuccess(response)
    }

    post = async (endpoint, params = {}, processParams = false, contentType = null) => {
        let config = {
            headers: {
                "Content-Type": contentType || "application/json",
            },
        }

        await this.setAuthHeaders()

        try {
            let response = await Http.post(endpoint, processParams ? qs.stringify(params) : params, config)

            return this.onSuccess(response)
        } catch (error) {
            return this.onError(error)
        }
    }

    patch = async (endpoint, params = {}, processParams = false, contentType = null) => {
        let config = {
            headers: {
                "Content-Type": contentType || "application/json",
            },
        }

        await this.setAuthHeaders()

        try {
            let response = await Http.patch(endpoint, processParams ? qs.stringify(params) : params, config)

            return this.onSuccess(response)
        } catch (error) {
            return this.onError(error)
        }
    }

    delete = async endpoint => {
        await this.setAuthHeaders()

        try {
            const response = await Http.delete(endpoint)

            return this.onSuccess(response)
        } catch (error) {
            return this.onError(error)
        }
    }

    onError = (error) => {
        throw error
    }

    onSuccess = ({ data, status, headers }) => ({
        body: data,
        status,
        headers,
    })

    setAuthHeaders = async () => {
        const bearerToken = await auth.getToken()

        if (bearerToken) {
            Http.defaults.headers.common["Authorization"] = `Bearer ${bearerToken}`
        }
    }

    clearCache = () => {
        cache.clear()
    }
}

export default (new HttpService())
