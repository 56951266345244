import React, {
    Fragment,
    useState,
}                      from "react"
import { useDispatch } from "react-redux"
import { useTrans } from "./../../hooks"
import { addUser }     from "../../store/actions/UserActions"
import UserForm        from "./partials/UserForm"

const UserCreate = ({ history }) => {
    const [validationErrors, setValidationErrors] = useState({})
    const dispatch = useDispatch()
    const { trans } = useTrans()

    const handleAddUser = userData => {
        dispatch(addUser(userData, () => {
            history.push("/admin/users")
        }, errors => {
            setValidationErrors(errors)
        }))
    }

    return (
        <Fragment>
            <div>
                <div className="user-page-header">
                    <h3 className="page-title">{trans("general.add_user")}</h3>
                </div>

                <div>
                    <UserForm
                        onSubmit={handleAddUser}
                        errors={validationErrors}
                    />
                </div>
            </div>
        </Fragment>
    )
}

export default UserCreate
