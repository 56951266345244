export default {
    header: {},
    footer: {},
    salutation: {},

    previous_address: {},
    ward: "",
    applicant: {},
    tax_type: "",
    propertyDetails: [{}],
}
