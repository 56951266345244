export default {
    header: {},
    footer: {},
    salutation: {},

    previous_address: {},
    name: {},
    current_ward: "",
    landDetails: [{}],
    direction: "",
    location: "",
    area: "",
    land_area: "",
    total_area: "",
    ground_floor_area: "",
    sifarish_reason: "",
    engineer_name: "",
}
