import { get }         from "lodash"
import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const RoomEvacuateForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = {
        ...defaultFormData,
        current_ward: get(staticData, "ward"),
        ...formData,
    }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="सिफारिस सम्बन्धमा ।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा{" "}
                    {trans(`municipalities.${staticData.municipality.name}`)}{" "}
                    वडा नं{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    बस्ने{" "}
                    <FormFieldName
                        value={formData.name}
                        name="name"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                        errors={errors}
                    />
                    ले " कोठा खाली गर्न ३५ दिने सुचना जारी गरी पाउँ" भनी यस वडा
                    कार्यालयमा निवेदन दिनुभ​एकोमा सो सम्बन्धमा ३५ दिने सुचना
                    जारी गरी आवश्यक कारवाहीको लागि यसै साथ संलग्न गरी सिफारिस
                    गरी पठाइएको व्यहोरा अनुरोध गरिन्छ ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default RoomEvacuateForm
