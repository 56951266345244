import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldKitta,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldSelect,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const HomePersist = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }

    const { municipality } = staticData
    const { trans } = useTrans()
    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    const FormFieldHomeTaxType = (formFieldProps) => {
        const { value, name, isPreview, onChange } = formFieldProps

        let options = {
            "real_estate_tax": trans("general.real_estate_tax"),
            "integrated_property_tax": trans("general.integrated_property_tax"),
        }

        return (
            <FormFieldSelect value={value} errors={errors} name={name} isPreview={isPreview} onChange={onChange} options={options}/>
        )
    }

    return (
        <div className="form-style">
            <FormHeader staticData={staticData} value={formData.header} errors={errors} isPreview={isPreview} subject="घर कायम सिफारिस।" onChange={handleOnChange}/>

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा{" "}
                    {trans(`municipalities.${municipality.name}`)} वडा नं.{" "}
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    /> अन्तर्गत{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />को नाममा त्यस कार्यालयमा श्रेस्ता दर्ता कायम रहेकोमा निम्न उल्लेखित जग्गामा घर निर्माण गरी यस वडा कार्यालयमा निजले चालु आर्थिक वर्ष सम्मकाे{" "}
                    <FormFieldHomeTaxType
                        value={formData.tax_type}
                        name="tax_type"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}/>{" "}चुक्ता गरिसकेकाे हुनाले निजकाे जग्गा धनी प्रमाणपुर्जामा घर कायम गरिदिनुहुन सिफारिससाथ अनुराेध गरिन्छ ।
                </div>

                <FormTable title={trans("general.description_of_the_land_to_be_maintained")} name="propertyDetails"
                           structure={[
                               {
                                   name: "ward",
                                   width: "20%",
                                   label: trans("general.Ward"),
                                   component: FormFieldWard,
                                   defaultValue: "",
                               },
                               {
                                   name: "kitta",
                                   width: "20%",
                                   label: "सिट नं/कि नं",
                                   component: FormFieldKitta,
                                   defaultValue: "",
                               },
                               {
                                   name: "area",
                                   width: "20%",
                                   label: "क्षेत्रफल",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                               {
                                   name: "house_number",
                                   width: "20%",
                                   label: "घर नं.",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                               {
                                   name: "road_name",
                                   width: "20%",
                                   label: "बाटाेकाे नाम",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                               {
                                   name: "authorised_date",
                                   width: "20%",
                                   label: "घर निर्माण भएकाे साल/अनुमति लिएकाे",
                                   component: FormFieldDate,
                                   defaultValue: "",
                               },
                           ]}
                           value={formData.propertyDetails}
                           isPreview={isPreview}
                           errors={errors}
                           onChange={handleOnChange}
                />
            </div>

            <FormFooter staticData={staticData} errors={errors} isPreview={isPreview} value={formData.footer} onChange={handleOnChange}/>
        </div>
    )
}

export default HomePersist
