export default {
    header: {},
    footer: {},
    salutation: {},

    applicant: {},
    relative: {},
    deathDate: "",

    nominees: [{}],
    propertyDetail: [{}],
    relationship: "",
}
