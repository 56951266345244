import { get }                from "lodash"
import React, {
    Fragment,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
}                             from "react"
import {
    useDispatch,
    useSelector,
}                             from "react-redux"
import { Link }               from "react-router-dom"
import { SifarishForms }      from "../../components/sifarish-forms"
import { auth }               from "../../helpers"
import { useTrans }           from "../../hooks"
import LayoutDBService        from "../../services/layout/LayoutDBService"
import { fetchWardProfile }   from "../../store/actions/MunicipalityActions"
import { createSifarishInDB } from "../../store/actions/SifarishActions"
import { notify }             from "../../store/actions/SystemActions"
import CitizenInfoForm        from "./partials/CitizenInfoForm"

const SifarishCreateForm = ({ formName, history }) => {
    const municipality = useSelector(auth.getCurrentMunicipality)
    const ward = useSelector(auth.getCurrentWard)
    const wardProfile = useSelector(state => state.municipality.wardProfile)

    const [formData, setFormData] = useState({})
    const [citizenData, setCitizenData] = useState({})
    const [isPreview, setIsPreview] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})
    const [layoutData, setLayoutData] = useState({
        header: null,
        footer: null,
    })

    const dispatch = useDispatch()
    const { trans } = useTrans()

    const fetchLayoutData = async () => {
        const header = await LayoutDBService.getLayout("header", municipality.name, ward)
        const footer = await LayoutDBService.getLayout("footer", municipality.name, ward)

        // if (!header || !footer) {
        //     dispatch(notify("Form Header and/or Footer layout has not been properly set. Please set layouts first.", 'error'))
        //     history.push('/admin/layout-builder')
        // }

        setLayoutData({ ...layoutData, header: get(header, "dataURL", null), footer: get(footer, "dataURL", null) })

        return layoutData
    }

    // eslint-disable-next-line
    useEffect(() => { const data = fetchLayoutData()}, [municipality, ward])

    useEffect(() => {
        dispatch(fetchWardProfile(municipality.name, ward))
    }, [dispatch, municipality, ward])

    const handleOnSubmit = useCallback(event => {
        event.preventDefault()

        dispatch(createSifarishInDB({
            municipality,
            ward,
            form_name: formName,
            form_data: formData,
            citizen: citizenData,
        }, sifarish => {
            dispatch(notify("Form successfully submitted."))

            return history.push(`/admin/sifarish/${sifarish.id}`)
        }, errors => {
            setValidationErrors(errors)
        }))
    }, [dispatch, formData, citizenData, formName, history, municipality, ward])

    const handleOnSifarishFormChange = useCallback(data => {
        setFormData(d => ({ ...formData, ...data }))
    }, [formData])

    const handleOnCitizenFormChange = useCallback(data => {
        setCitizenData(data)
    }, [])

    useLayoutEffect(() => {
        const header = document.querySelector(".nav")
        const stickyElement = document.querySelector(".sub-header")
        const doc = document.documentElement
        window.onscroll = () => {
            const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
            if (top > header.clientHeight) {
                stickyElement.classList.add("sticky")
            } else {
                stickyElement.classList.remove("sticky")
            }
        }
    }, [])

    return (
        <>
            <div className="sub-header sifarish-header">
                <div className="container">
                    <Link to="/admin/sifarish/forms" className="btn btn-link">{trans("general.back")}</Link>

                    {isPreview ? (
                        <Fragment>
                            <div className="">
                                <button className="btn-outline btn-secondary btn-submit"
                                        onClick={() => setIsPreview(false)}>{trans("general.back_to_edit")}</button>
                                <button className="btn-outline btn-success btn-submit" onClick={handleOnSubmit}>{trans(
                                    "general.confirm")}</button>
                            </div>
                        </Fragment>
                    ) : (
                        <button className="btn-outline btn-primary btn-submit" onClick={() => setIsPreview(true)}>अगाडी
                                                                                                                  बढ्नुहाेस्</button>
                    )}
                </div>
            </div>
            <div>

                <SifarishForms
                    formName={formName}
                    staticData={{ municipality, ward, wardProfile, layoutData }}
                    formData={formData}
                    layoutData={layoutData}
                    isPreview={isPreview}
                    errors={validationErrors}
                    onChange={handleOnSifarishFormChange}
                />

                <CitizenInfoForm
                    formData={citizenData}
                    isPreview={isPreview}
                    onChange={handleOnCitizenFormChange}
                    errors={validationErrors}
                />

                {/* {isPreview ? (
                <Fragment>
                    <div className="btn-submit-container">
                        <button className="btn btn-info btn-submit" onClick={() => setIsPreview(false)}>Back to edit</button>
                        <button className="btn btn-success btn-submit" onClick={handleOnSubmit}>Confirm, please submit</button>
                    </div>
                </Fragment>
            ) : (
                <button className="btn btn-primary btn-submit btn-submit-fixed" onClick={() => setIsPreview(true)}>Proceed to submit</button>
            )} */}
            </div>
        </>
    )
}

export default SifarishCreateForm
