import {
    get,
    has,
    isEmpty,
}                                   from "lodash"
import React, {
    useCallback,
    useMemo,
    useState,
}                                   from "react"
import {
    useDispatch,
    useSelector,
}                                   from "react-redux"
import {
    Link,
    Redirect,
}                                   from "react-router-dom"
import { auth }                     from "../../helpers"
import {
    useInput,
    useTrans,
}                                   from "../../hooks"
import { FormValidation }           from "../../lib/FormValidation"
import { login }                    from "../../store/actions/AuthActions"
import { notify }                   from "../../store/actions/SystemActions"
import { setLoginValidationErrors } from "../../store/reducers/auth/action"

const Login = ({ location }) => {
    const [errorMessage, setErrorMessage] = useState("")
    const { value: username, bind: bindUsername } = useInput("")
    const { value: password, bind: bindPassword } = useInput("")
    const { value: rememberMe, bind: bindRememberMe } = useInput(false)
    const validationRules = useMemo(() => ({
        username: "required",
        password: "required",
    }), [])

    const isAuthenticated = useSelector(auth.checkIfAuthenticated)
    const validationErrors = useSelector(state => state.auth.login.validationErrors)

    const { trans } = useTrans()
    const dispatch = useDispatch()

    const validationClass = useCallback(name => validationErrors && has(validationErrors, name) ? "has-error" : "", [validationErrors])

    const handleLogin = useCallback(event => {
        event.preventDefault()
        setErrorMessage("")
        const formData = { username, password, rememberMe }

        const errors = (new FormValidation(formData, validationRules)).validate()
        if (!isEmpty(errors)) {
            dispatch(setLoginValidationErrors(errors))
            return
        }

        dispatch(login({ username, password, rememberMe }, () => {
            dispatch(notify("Login successfully."))

            location.pathname = "/admin"
        }, error => {
            setErrorMessage(error)
        }))
    }, [validationRules, dispatch, location, username, password, rememberMe])

    if (isAuthenticated) {
        return <Redirect to="/admin"/>
    }

    return (
        <div className="login-form">
            <div className="login-form-container">
                <div className="login-form-header header">
                    <h1 className="login-form-title">{trans("general.login")}</h1>
                </div>

                <div className="login-form-body">
                    <form onSubmit={handleLogin}>
                        {errorMessage && (
                            <div
                                className="alert alert-danger error-message"
                                role="alert"
                            >
                                {errorMessage}
                            </div>
                        )}

                        <div className={`form-group ${validationClass("username")}`}>
                            <label htmlFor="username" className="mb-1">{trans("general.user_name")}</label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                {...bindUsername}
                            />
                            <span className="form-error">{get(validationErrors, "username")}</span>
                        </div>

                        <div className={`form-group ${validationClass("password")}`}>
                            <label htmlFor="password" className="mb-1">{trans("general.password")}</label>
                            <input
                                type="password"
                                className="form-control"
                                id="password"
                                {...bindPassword}
                            />
                            <span className="form-error">{get(validationErrors, "password")}</span>
                        </div>

                        <div className="form-group form-check">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="remember_me"
                                {...bindRememberMe}
                            />
                            <label className="form-check-label" htmlFor="remember_me">
                                {trans("general.remember_me")}
                            </label>
                        </div>

                        <input style={{ display: "none" }} type="submit"/>
                    </form>
                </div>

                <div className="login-form-footer">
                    <div className="login-action">
                        <Link to="/login" className="btn-link">पासवर्ड बिर्सनुभयो ?</Link>

                        <button
                            type="submit"
                            onClick={handleLogin}
                            value="Login"
                            className="btn-outline btn-primary"
                        >
                            {trans("general.login")}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login
