import PouchDB                    from "pouchdb"
import PouchDBFind                from "pouchdb-find"
import {
    getCurrentMunicipality,
    getCurrentWard,
} from "../../helpers/AuthHelper"

class SifarishDBService {
    constructor() {
        PouchDB.plugin(PouchDBFind)
        this.db = new PouchDB("sifarish", { adapter: "idb" })
    }

    async getSifarishList(query) {
        const data = await this.db.allDocs({ include_docs: true })

        if (!data) {
            return []
        }

        return data.rows.map(row => row.doc || null)
    }

    async getSifarishById(id) {
        return await this.db.get(`sifarish_${id}`)
    }

    /**
     * @param {Array} sifarishList
     * @param {Boolean} isSynced
     * @return {Promise<void>}
     */
    async saveSifarishList(sifarishList, isSynced = false) {
        const sifarishListToSave = []

        for (let sifarish of sifarishList) {
            let sifarishInDB = null
            let sifarishData = { ...sifarish }
            const dbId = `sifarish_${sifarishData.app_index}`

            try {
                sifarishInDB = await this.getSifarishById(sifarishData.app_index)
            } catch (error) {
                console.log(error)
            }

            if (isSynced) {
                sifarishData = { ...sifarishData, is_synced: isSynced }
            }

            sifarishListToSave.push({
                ...sifarishData,
                _rev: sifarishInDB ? sifarishInDB._rev : null,
                _id: dbId,
                is_synced: isSynced,
            })
        }

        return await this.db.bulkDocs(sifarishListToSave)
    }

    async updateSifarish(sifarish, rev) {
        try {
            await this.db.put({
                ...sifarish,
                _id: `sifarish_${sifarish.app_index}`,
                _rev: rev,
            })
        } catch (error) {
            console.error(error)
            throw new Error("Sifarish update failed.")
        }
    }

    async createSifarish(sifarish) {
        const documents = sifarish.citizen.metadata.documents ? sifarish.citizen.metadata.documents.reduce(
            (acc, document) => {
                const fileName = Date.now()
                const documentObj = {
                    [fileName]: {
                        content_type: document.type,
                        data: document,
                    },
                }
                return { ...acc, ...documentObj }
            }, {}) : null

        return await this.db.put({
            ...sifarish,
            _id: `sifarish_${sifarish.id}`,
            _attachments: documents,
        })
    }

    async getSifarishPaginatedList(state, pageNo, limit = 15) {
        const municipality = getCurrentMunicipality(state)
        const ward = getCurrentWard(state)

        await this.db.createIndex({
            index: { fields: ["created_at"] },
        })

        const options = {
            limit,
            skip: (pageNo * limit) - limit,
            include_docs: true,
            selector: { parent_sifarish_id: null, id: { $gt: null }, created_at: { $gt: null } },
            sort: [{ "created_at": "desc" }],
            descending: true,
        }

        const data = await this.db.query(function(doc, emit) {
            if (!doc.parent_sifarish_id && doc.municipality_name === municipality.name && doc.ward === ward) {
                emit([doc.created_at])
            }
        }, options)
        const totalDocumentsCount = data.total_rows

        let formattedSifarish = []
        for (let sifarish of data.rows) {
            if (sifarish.doc?.form_data) {
                formattedSifarish.push({
                    ...sifarish.doc,
                    children: await this.db.find({
                        sort: [{ "_id": "desc" }],
                        selector: { parent_sifarish_id: sifarish.doc.app_index?.toString(), id: { $gt: null } },
                    }),
                })
            }
        }

        if (!formattedSifarish) {
            return []
        }

        return {
            data: formattedSifarish.map(sifarishData => {
                const { children: childrenData, ...sifarish } = sifarishData

                const children = childrenData.docs
                children.push(sifarish)

                return { ...children.shift(), children }
            }),
            pagination: {
                total: totalDocumentsCount,
                per_page: limit,
                page: pageNo,
                total_pages: Math.ceil(totalDocumentsCount / limit),
            },
        }
    }
}

export default (new SifarishDBService())
