export default {
    header: {},
    footer: {},
    salutation: {},

    current_ward: "",
    previous_address: {},
    name: {},
    disability_type: "",
}
