import React        from "react"
import FormNotFound from "./FormNotFound"
import formList     from "./forms"

const SifarishForms = (props) => {
    const { formName, staticData, onChange, type, formData, isPreview, errors } = props
    const form = formList[formName]
    const pageType = type || "create"

    if (!form) {
        return <FormNotFound/>
    }

    if (pageType === "print") {
        return form.print({ staticData, formData, isPreview: true, errors: {} })
    }

    return form.form({ staticData, onChange, formData, isPreview, errors })
}

export default SifarishForms
