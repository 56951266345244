import { get }  from "lodash"
import {
    MUNICIPALITY_ADMIN,
    OFFICERS,
    SUPER_ADMIN,
    WARD_ADMIN,
}               from "../config/Constants/UserRoles"
import { auth } from "../helpers"

export const view = (state, sifarish) => {
    const currentUser = auth.getCurrentUser(state)
    const role = currentUser.role
    const municipalityName = get(currentUser, "municipality.name")
    const ward = get(currentUser, "ward")

    if (role === SUPER_ADMIN) {
        return true
    }

    if (role === MUNICIPALITY_ADMIN && sifarish.municipality_name === municipalityName) {
        return true
    }

    return [WARD_ADMIN, OFFICERS].includes(role)
        && sifarish.municipality_name === municipalityName
        && sifarish.ward === ward
}
