import {
    get,
    isEmpty,
}                                    from "lodash"
import React, {
    Fragment,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
}                                    from "react"
import {
    useDispatch,
    useSelector,
}                                    from "react-redux"
import { Link }                      from "react-router-dom"
import Icon                          from "../../components/icon/Icon"
import { Loading }                   from "../../components/loading"
import { SifarishForms }             from "../../components/sifarish-forms"
import {
    FormLayoutFooter,
    FormLayoutHeader,
}                                    from "../../components/sifarish-forms/fields"
import { PermissionDeniedException } from "../../exceptions"
import { useTrans }                  from "../../hooks"
import LayoutDBService               from "../../services/layout/LayoutDBService"
import { fetchSifarishById }         from "../../store/actions/SifarishActions"
import CitizenInfoForm               from "./partials/CitizenInfoForm"

const SifarishPrintForm = ({ sifarishId, history }) => {
    const [isLoaded, setIsLoaded] = useState(false)
    const [ward, setWard] = useState(null)
    const [municipality, setMunicipality] = useState({})
    const [sifarish, setSifarish] = useState({})
    const sifarishForms = useSelector(state => state.sifarish.forms)
    const [dropdownVisible, setDropdownVisible] = useState(false)
    const [showHeaderOnPrint, setShowHeaderOnPrint] = useState(true)
    const wardProfile = useSelector(state => state.municipality.wardProfile)
    const [layoutData, setLayoutData] = useState({
        header: null,
        footer: null,
    })
    const [citizenData, setCitizenData] = useState({})

    const dispatch = useDispatch()
    const { trans } = useTrans()

    const showDropdown = event => {
        event.preventDefault()

        setDropdownVisible(!dropdownVisible)
    }

    const fetchLayoutData = useCallback(async (municipality, ward) => {
        const header = await LayoutDBService.getLayout("header", municipality, ward)
        const footer = await LayoutDBService.getLayout("footer", municipality, ward)

        // if (!header || !footer) {
        //     dispatch(notify("Form Header and/or Footer layout has not been properly set. Please set layouts first.",
        //         "error"))
        //     history.push("/admin/layout-builder")
        // }

        setLayoutData({ ...layoutData, header: get(header, "dataURL", null), footer: get(footer, "dataURL", null) })

        return layoutData
    }, [])

    useLayoutEffect(() => {
        if (showHeaderOnPrint === false) {
            window.print()
            setShowHeaderOnPrint(true)
        }
    }, [showHeaderOnPrint])

    useEffect(() => {
        dispatch(
            fetchSifarishById(
                sifarishId,
                sifarishData => {
                    const form = sifarishForms.find(sifarishForm => sifarishForm.name === sifarishData.form_name)
                    setSifarish({ ...sifarishData, sifarishForm: form })
                    setCitizenData({
                        ...sifarishData.citizen,
                        email: sifarishData.citizen?.metadata.email,
                        contact: sifarishData.citizen?.metadata.contact,
                        address: sifarishData.citizen?.metadata.address,
                        documents: sifarishData.citizen?.metadata.documents || [],
                    })
                    setIsLoaded(true)
                },
                error => {
                    if (error instanceof PermissionDeniedException) {
                        history.push(`/admin/permission-denied`)
                    }
                },
            ),
        )
    }, [dispatch, sifarishId, history, sifarishForms])

    useEffect(() => {
        if (!isEmpty(sifarish)) {
            setMunicipality(sifarish.municipality)
            setWard(sifarish.ward)

            // eslint-disable-next-line
            fetchLayoutData(sifarish.municipality.name, sifarish.ward).then()
        }
    }, [fetchLayoutData, sifarish])

    useLayoutEffect(() => {
        const header = document.querySelector(".nav")
        const stickyElement = document.querySelector(".sifarish-header")
        const doc = document.documentElement
        window.onscroll = () => {
            const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
            if (top > header.clientHeight) {
                stickyElement && stickyElement.classList.add("sticky")
            } else {
                stickyElement && stickyElement.classList.remove("sticky")
            }
        }

        document.addEventListener("click", event => {
            const dropdownContainer = event.target.closest(".dropdown-container")
            const dropdownElement = event.target.closest(".dropdown-options")
            if (!dropdownElement && !dropdownContainer) {
                setDropdownVisible(false)
            }
        })
    }, [isLoaded])

    if (!isLoaded) {
        return <Loading/>
    }

    return (
        <Fragment>
            <div className="sifarish-header sub-header no-print">
                <div className="container">
                    <Link to="/admin/sifarish" className="btn btn-link">
                        {trans("general.back")}
                    </Link>

                    <div className="btn-prints">
                        <Link
                            to={`/admin/sifarish/${sifarish.app_index}/edit`}
                            className="btn-edit"
                        >
                            <Icon icon="edit"/>
                            सम्पादन
                        </Link>

                        <button className="btn-print" onClick={() => window.print()}>
                            {trans("general.print")}
                        </button>
                        <div
                            className={dropdownVisible
                                ? "print-option dropdown--visible dropdown-container"
                                : "print-option dropdown-container"}
                            onClick={showDropdown}>
                            <span></span>
                            {dropdownVisible && (
                                <div className="dropdown-options">
                                    <button className="user-dropdown-item"
                                            onClick={() => {
                                                setShowHeaderOnPrint(false)
                                            }}>
                                        लेटरहेडविना प्रिन्ट
                                    </button>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div className="printable">
                <table className="print-layout-table">
                    <thead>
                        <tr>
                            <td>
                                {showHeaderOnPrint ? (
                                    <FormLayoutHeader
                                        staticData={{ municipality, ward, layoutData }}
                                        locale={sifarish.sifarishForm.metadata.form_locale}
                                        formdata={{ ...sifarish.form_data, status: sifarish.status }}
                                    />
                                ) : (
                                    <div className="page-header-space"></div>
                                )}
                            </td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <SifarishForms
                                    formName={sifarish.form_name}
                                    staticData={{ municipality, ward, layoutData }}
                                    layoutData={layoutData}
                                    type="print"
                                    formData={{ ...sifarish.form_data, status: sifarish.status }}
                                />
                            </td>
                        </tr>
                    </tbody>
                    <tfoot>
                        <tr>
                            <td>
                                <div className="page-footer-space"></div>
                            </td>
                        </tr>
                    </tfoot>
                </table>
                <CitizenInfoForm
                    formData={citizenData}
                    isPreview={true}
                />
                {showHeaderOnPrint ?
                    <FormLayoutFooter
                        staticData={{ municipality, ward, wardProfile }}
                        layoutData={layoutData}
                        locale={sifarish.sifarishForm.metadata.form_locale}
                    />
                    :
                    <></>
                }
            </div>
        </Fragment>
    )
}

export default SifarishPrintForm
