import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const IdentityByBirthDate = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }
    const { trans } = useTrans()
    const { municipality } = staticData

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="फरक फरक जन्म मिति प्रमाणित।"
                onChange={handleOnChange}/>

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त विषयमा{" "}
                    {trans(`municipalities.${municipality.name}`)}{" "} वडा नं.{" "}
                    <FormFieldWard
                        value={formData.current_ward}
                        name="current_ward"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    /> निवासी{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />को जन्म मिति{" "}
                    <FormFieldText
                        placeholder={trans("general.document")}
                        value={formData.document_1}
                        name="document_1"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />को आधारमा{" "}
                    <FormFieldDate
                        placeholder={trans("general.date")}
                        value={formData.dob_date_1}
                        name="dob_date_1"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> भएको र निजको जन्म मिति{" "}
                    <FormFieldText
                        placeholder={trans("general.document")}
                        value={formData.document_2}
                        name="document_2"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    /> मा{" "}
                    <FormFieldDate
                        placeholder={trans("general.date")}
                        value={formData.dob_date_2}
                        name="dob_date_2"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> हुन गएकोले सो फरक हुन गएको जन्म मिति भएको व्यक्ति एकै भएको प्रमाणित गरी{" "}
                    पाऊँ भनी यस कार्यालयमा पेश हुन आएको निवेदन तथा सर्जमिनका आधारमा निजले पेश गरेको व्यहोरा मनासिव{" "}
                    भएकोले सो जन्म मिति भएको व्यक्ति फरक फरक नभई एकै भएको सिफारिस गरिन्छ।
                </div>

            </div>

            <FormFooter errors={errors} staticData={staticData} value={formData.footer} isPreview={isPreview} onChange={handleOnChange}/>
        </div>
    )
}

export default IdentityByBirthDate
