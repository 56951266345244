export default {
    header: {},
    footer: {},
    salutation: {},

    ward: "",
    applicant: {},
    school_ward: "",
    school_name: "",
    school_class: "",
    date: ""
};
