import {
    get,
    has,
    set,
} from "lodash"

class DataCacheService {
    data = {}

    set = ({ key, value }) => {
        set(this.data, key, value)
    }

    has = key => has(this.data, key)

    get = key => get(this.data, key)

    clear = () => {
        this.data = {}
    }

}

export default DataCacheService
