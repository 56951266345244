const env = (key, defaultValue) => {
    const value = process.env[key] || defaultValue
    if (typeof value === "undefined") {
        throw new Error(`Environment variable ${key} not set.`)
    }

    return value
}

export default {
    baseUrl: env("REACT_APP_API_URL"),
    debug: env("REACT_APP_ENV", "development") === "development",
    apiCache: env("REACT_APP_ENABLE_API_CACHE", false) === "true",
    hashRounds: 12,
    tokenExpiration: 60 * 60 * 24, // in seconds
    jwtSecret: env("REACT_APP_JWT_SECRET"),

    authTokenKeyName: "e_sifarish_auth_token",
}
