import {
    auth,
    call,
    log,
} from "../../helpers"

import {
    fetchLayoutFromApi,
    fetchLayoutFromDB,
    setLayoutToApi,
} from "./LayoutAction"
import {
    fetchWardProfileFromApi,
    fetchWardProfileFromDB,
    setWardProfileToApi,
} from "./MunicipalityActions"
import {
    fetchSifarishListFromApi,
    fetchSifarishListFromDB,
    pushSifarishToApi,
    setSifarishListToDB,
    updateSifarishInDB,
} from "./SifarishActions"

export const sifarishManualSync = (messageLog, onSuccess, onError) => async (dispatch, getState) => {
    try {
        messageLog("Fetching data from database...")
        const sifarishListInDB = await new Promise((resolve, reject) => {
            dispatch(fetchSifarishListFromDB({}, resolve, error => {
                dispatch(log(error))
                reject(error)
            }))
        })

        messageLog("Pushing data to server...")
        for (let sifarish of sifarishListInDB) {
            if (!sifarish.is_synced && sifarish.form_data && !sifarish.citizen) {
                const response = await new Promise((resolve, reject) => {
                    dispatch(pushSifarishToApi(sifarish, resolve, reject))
                })

                await new Promise((resolve, reject) => {
                    dispatch(updateSifarishInDB({
                        sifarish: { ...response, is_synced: true },
                        rev: sifarish._rev,
                    }, resolve, reject))
                })
            }
        }

        messageLog("Fetching data from server...")
        const sifarishList = await new Promise((resolve, reject) => {
            dispatch(fetchSifarishListFromApi({}, resolve, reject))
        })

        messageLog("Persist data to local database...")
        await new Promise((resolve, reject) => {
            dispatch(setSifarishListToDB(sifarishList, resolve, reject))
        })

        messageLog("Sifarish synced.")
        call(onSuccess)
    } catch (error) {
        dispatch(log(error))
        messageLog("Sifarish sync failed")
        call(onError)
    }
}

export const wardProfileManualSync = (messageLog, onSuccess, onError) => async (dispatch, getState) => {
    try {
        const { municipality: { name: municipalityName }, ward } = auth.getCurrentUser(getState())
        messageLog("Fetching data from database...")
        await new Promise(resolve => {
            dispatch(fetchWardProfileFromDB(municipalityName, ward, profile => {
                dispatch(setWardProfileToApi(municipalityName, ward, profile, () => {
                    resolve()
                }))
            }, (err) => {
                resolve()
            }))
        })

        await new Promise(resolve => {
            dispatch(fetchWardProfileFromApi(municipalityName, ward, () => {
                resolve()
            }))
        })

        messageLog("Ward profile synced.")
        call(onSuccess)
    } catch (error) {
        dispatch(log(error))
        messageLog("Ward profile failed")
        call(onError)
    }
}

export const layoutManualSync = (messageLog, onSuccess, onError) => async (dispatch, getState) => {
    try {
        const { municipality: { name: municipalityName }, ward } = auth.getCurrentUser(getState())
        messageLog("Fetching data from database...")
        await new Promise(resolve => {
            dispatch(fetchLayoutFromDB(municipalityName, ward, layout => {
                dispatch(setLayoutToApi(municipalityName, ward, layout, () => {
                    resolve()
                }))
            }, (err) => {
                resolve()
            }))
        })

        await new Promise(resolve => {
            dispatch(fetchLayoutFromApi(municipalityName, ward, () => {
                resolve()
            }))
        })

        messageLog("Layout synced.")
        call(onSuccess)
    } catch (error) {
        dispatch(log(error))
        messageLog("Layout failed")
        call(onError)
    }
}
