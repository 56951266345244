import { isEmpty }  from "lodash"
import PropTypes    from "prop-types"
import React        from "react"
import { useTrans } from "../../../hooks"

const FormFieldWard = ({ isPreview, value, name, onChange, errors, locale }) => {
    const { numberTrans, trans } = useTrans()

    if (!isEmpty(errors)) {console.error(errors)}

    return (
        isPreview ? (
            <span className="form-input-view">{numberTrans(value || "", {locale})}</span>
        ) : (
            <input className="form-input form-input-sm" placeholder={trans("general.Ward", {locale})} type="text" min="1" name={name} value={value || ""} onChange={event => onChange({ name: event.target.name, value: event.target.value })}/>
        )
    )
}

FormFieldWard.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.oneOfType([
        PropTypes.number,
        PropTypes.string,
    ]).isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldWard
