export default {
    header: {},
    footer: {},
    salutation: {},

    previous_address: {},
    name: {},
    applicant: {},
    date: "",
    court_name: "",
    case_type: "",
    current_ward: "",
    landDetails: [{}]
}
