export default {
    header: {},
    footer: {},
    salutation: {},

    municipality: {},
    ward: "",
    previous_address: {},
    previous_ward: "",
    applicant_father: "",
    applicant_mother: "",
    financial_state: "",
    childType: "",
    beneficiaries: [{}],
}
