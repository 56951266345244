import React, { Fragment } from "react"
import { useTrans }        from "../../../../hooks"

const LocalGovernmentLevel = ({ inputData, onChange, name, locale, isPrevious }) => {
    const localGovernmentLevels = ["metropolitan_city", "sub_metropolitan_city", "municipality", "rural_municipality"]
    const { trans } = useTrans()
    const value = { name: "", level: "", ...inputData }

    const handleOnChange = event => {
        const data = { ...value }
        data[event.target.name] = event.target.value

        onChange({ name: name, value: data })
    }
    return (
        <Fragment>
            <input className="form-input form-input-lg" type="text" name="name" placeholder={trans("general.Local Government Name", {locale})} value={value.name} onChange={handleOnChange}/>{" "}
            <select name="level" value={value.level} onChange={handleOnChange}>
                <option value=""></option>
                {localGovernmentLevels.map((localGovernmentLevel, localGovernmentLevelIndex) => (
                    <option key={`localGovernmentLevel-${localGovernmentLevelIndex}`} value={localGovernmentLevel}>{trans(`municipalities.administrative_division.${localGovernmentLevel}`, {locale})}</option>
                ))}
              {isPrevious && (
                <option value="vdc">गा.बि.स.</option>
              )}
            </select>
        </Fragment>
    )
}

export default LocalGovernmentLevel
