import React from "react"
import {
    PublicFormWrapper,
    SifarishSubmissionSuccess,
}            from "../../pages/sifarish"

export default prefix => ([
    {
        auth: false,
        path: `${prefix}/:municipality/:ward/:form_name`,
        exact: true,
        component: (props) => {
            const municipality = props.match.params.municipality
            const ward = parseInt(props.match.params.ward)
            const formName = props.match.params.form_name

            return <PublicFormWrapper {...props}
                                      municipalityName={municipality}
                                      ward={ward}
                                      formName={formName}/>
        },
        hasSubHeader: false,
    },
    {
        auth: false,
        path: `${prefix}/:municipality/:ward/:form_name/success/:token`,
        exact: true,
        component: (props) => {
            const municipality = props.match.params.municipality
            const ward = parseInt(props.match.params.ward)
            const formName = props.match.params.form_name
            const token = props.match.params.token

            return <SifarishSubmissionSuccess {...props}
                                              municipalityName={municipality}
                                              ward={ward}
                                              formName={formName}
                                              token={token}/>
        },
        hasSubHeader: false,
    },
])
