import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldKitta,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldRelation,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const DeathInheritance = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { ward } = staticData
    formData = { ...defaultFormData, currentWard: ward, ...formData }

    const { municipality } = staticData
    const { trans } = useTrans()
    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="सिफारिस सम्बन्धमा ।"
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा{" "}
                    {trans(`municipalities.${municipality.name}`)} वडा नं.{" "}
                    <FormFieldWard
                        value={formData.currentWard}
                        name="currentWard"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    बस्ने{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />
                    ले हकदार प्रमाणित गरी पाँऊ भनि यस वडा कार्यालय निवेदन
                    दिनुभएको हुँदा सो सम्बन्धमा{" "}
                    <FormFieldText
                        value={formData.lg_name}
                        name="lg_name"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.Local Government Name")}
                    />{" "}
                    बाट मिति{" "}
                    <FormFieldDate
                        value={formData.registered_date}
                        name="registered_date"
                        errors={errors}
                        placeholder={trans("general.date")}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    मा गरिएको द.नं.
                    <FormFieldText
                        value={formData.registration_number}
                        name="registration_number"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.registration_number")}
                    />{" "}
                    को नाता प्रमाणित प्रमाणपत्र अनुसार मृतक{" "}
                    <FormFieldName
                        value={formData.deceased_name}
                        name="deceased_name"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />
                    का हकदारहरु देहाय बमोजिम उल्लेखित{" "}
                    <FormFieldText
                        value={formData.total_person}
                        name="total_person"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.number")}
                    />{" "}
                    जना मात्र भएको व्यहोरा सिफारिस गरिन्छ ।
                </div>

                <FormTable
                    title={trans("general.death_inheritance_details")}
                    name="inheritanceDetails"
                    structure={[
                        {
                            name: "entitled_person",
                            width: "30%",
                            label: "हकदारहरुको नाम",
                            component: FormFieldName,
                            defaultValue: {},
                        },
                        {
                            name: "relation",
                            width: "20%",
                            label: "नाता",
                            component: FormFieldRelation,
                            defaultValue: "",
                        },

                        {
                            name: "father_husband_name",
                            width: "20%",
                            label: "बाबु/पति को नाम",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "citizenship_no",
                            width: "20%",
                            label: trans("general.citizenship_no"),
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "house_no",
                            width: "20%",
                            label: "घर नं",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "kitta",
                            width: "20%",
                            label: "कित्ता नं",
                            component: FormFieldKitta,
                            defaultValue: "",
                        },
                        {
                            name: "road_name",
                            width: "20%",
                            label: "बाटोको नाम",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                    ]}
                    value={formData.inheritanceDetails}
                    isPreview={isPreview}
                    errors={errors}
                    onChange={handleOnChange}
                />
            </div>

            <FormFooter
                staticData={staticData}
                isPreview={isPreview}
                value={formData.footer}
                errors={errors}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default DeathInheritance
