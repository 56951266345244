import {
    call,
    dispatcherWithLoader,
}                                 from "../../helpers"
import { StaticDataService }      from "../../services/static-data"
import {
    addNotification,
    clearLoaderMessages,
    setOffline,
    setOnline,
    stopLoader,
    systemBooted,
    systemBootFailed,
    systemBooting,
} from "../reducers/system/action"
import { API_REQUEST }            from "../reducers/system/type"
import { loginFromLocalStorage }  from "./AuthActions"
import { fetchStaticDataFromDB }  from "./StaticDataActions"
import { fetchTranslationFromDB } from "./TranslationActions"

export const checkInternetConnection = () => dispatch => {
    const isOnline = navigator.onLine

    if (!isOnline) {
        dispatch(setOffline())
        return
    }

    dispatch({
        type: API_REQUEST,
        payload: {
            onRequest: () => StaticDataService.pingServer(),
            onSuccess: () => {
                dispatch(setOnline())
            },
            onError: () => {
                dispatch(setOffline())
            },
        },
    })
}

export const bootSystemFromDB = (onSuccess, onFailed) => async dispatch => {
    dispatch(systemBooting())
    const loadingMessages = {
        translation: "Loading translations from database.",
        staticData: "Loading system data from database.",
        login: "Attempting login user from database.",
    }

    const clearAllLoaderMessages = () => {
        dispatch(clearLoaderMessages(Object.values(loadingMessages)))
    }

    try {
        await Promise.all([
            dispatcherWithLoader(dispatch, loadingMessages.translation, fetchTranslationFromDB),
            dispatcherWithLoader(dispatch, loadingMessages.staticData, fetchStaticDataFromDB),
            dispatcherWithLoader(dispatch, loadingMessages.login, loginFromLocalStorage),
        ])

        clearAllLoaderMessages()
        dispatch(systemBooted())
        dispatch(stopLoader())
        call(onSuccess)
    } catch (error) {
        clearAllLoaderMessages()
        dispatch(systemBootFailed())
        call(onFailed, error)
    }
}

export const notify = (message, type = "success") => dispatch => {
    dispatch(addNotification({
        time: new Date(),
        content: message,
        type,
    }))
}
