import React, {
    Fragment,
    useCallback,
    useEffect,
    useMemo,
}                                   from "react"
import {
    useDispatch,
    useSelector,
}                                   from "react-redux"
import {
    checkIfSystemLoading,
    electron,
    isBooted,
    isOnline,
    isOnlineChecked,
}                                   from "../../helpers"
import { Socket }                   from "../../sockets"
import { triggerDatabaseSync }      from "../../store/actions/DatabaseSyncActions"
import { fetchAppInstallationInfo } from "../../store/actions/InstallationActions"
import { bootSystemFromDB }         from "../../store/actions/SystemActions"
import {
    addLoaderMessage,
    clearLoaderMessage,
}                                   from "../../store/reducers/system/action"
import { Installation }             from "../installation"
import { Loading }                  from "../loading"
import { Notifications }            from "../notifications"
import { GlobalStyle }              from "../styled"

const MainLayout = ({ children }) => {
    const isAppInstalled = useSelector(state => state.electron.isInstalled)
    const isSystemBooted = useSelector(isBooted)
    const isSystemOnline = useSelector(isOnline)
    const isSystemOnlineChecked = useSelector(isOnlineChecked)
    const isLoading = useSelector(checkIfSystemLoading)
    const isElectronApp = useMemo(() => electron.isElectronApp(), [])

    const dispatch = useDispatch()

    const bootSystem = useCallback(() => {
        if (!isSystemBooted) {
            dispatch(bootSystemFromDB())
        }
    }, [dispatch, isSystemBooted])

    useEffect(() => {
        const message = isElectronApp ? "Checking internet connection." : ""

        if (isSystemOnlineChecked) {
            dispatch(clearLoaderMessage(message))
        } else {
            dispatch(addLoaderMessage(message))
        }
    }, [isElectronApp, isSystemOnlineChecked, dispatch])

    useEffect(() => {
        Socket.init(dispatch)

        dispatch(fetchAppInstallationInfo())
    }, [dispatch])

    useEffect(() => {
        if (isSystemOnlineChecked && isAppInstalled) {
            bootSystem()
        }
    }, [bootSystem, isAppInstalled, isSystemOnlineChecked])

    useEffect(() => {
        if (isSystemOnline && isAppInstalled && isSystemBooted) {
            dispatch(triggerDatabaseSync())
            // const interval = setTimeout(() => {
            //     if (isSystemBooted && isSystemOnline) {
            //         dispatch(triggerDatabaseSync())
            //     }
            // }, 1000 * 60 * 5)
            //
            // return () => clearInterval(interval)
        }

        return () => {}
    }, [dispatch, isSystemOnline, isAppInstalled, isSystemBooted])

    if (!isSystemOnlineChecked) {
        return <Loading/>
    }

    if (isAppInstalled === false) {
        return <Installation/>
    }

    if (isLoading) {
        return (<Loading/>)
    }

    return (
        <Fragment>
            <GlobalStyle/>
            <Notifications/>

            {children}
        </Fragment>
    )
}

export default MainLayout
