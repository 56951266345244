import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const UnmarriedCertified = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="अविवाहित प्रमाणित बारे ।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा{" "}
                    {trans(
                        `municipalities.${staticData.municipality.name}`,
                    )} वडा नं{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    /> बस्ने{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        placeholder={trans("general.name")}
                    /> ले हाल सम्म अविवाहित अवस्थामा रहेर बसेकोले सोही व्यहोराको अविवाहित प्रमाणित गरी पाउँ भनी मिति{" "}
                    <FormFieldDate
                        value={formData.date}
                        name="date"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    /> गते यस कार्यालय मा निवेदन दिनुभएको हुँदा निवेदक{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    /> हालको मिति सम्म अविवाहित रहेको हो भनी निवेदकले पेश गरेका
                    कागजातहरुको आधारमा र वडा बाट भई आएको सर्जमिन मुचुल्काको आधारमा निवेदक{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        placeholder={trans("general.name")}
                    /> आजका मिति सम्म अविवाहित रहेकोे व्यहोरा प्रमाणित गरिन्छ ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                errors={errors}
                isPreview={isPreview}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default UnmarriedCertified
