import {
    isEmpty,
    omit,
}                   from "lodash"
import PropTypes    from "prop-types"
import React        from "react"
import { useTrans } from "../../../../hooks"

const NameWithHonorific = (props) => {
    const { value: inputData, onChange, name, errors, locale } = props
    const inputProps = omit(props, ["inputData", "name", "onChange", "errors", "locale"])

    if (!isEmpty(errors)) {console.error(errors)}

    const honorifics = ["mr", "ms", "mrs"]
    const { trans } = useTrans()

    const value = { honorific: "", name: "", ...inputData }
    const handleOnChange = event => {
        onChange({ name: name, value: { ...value, [event.target.name]: event.target.value } })
    }

    return (
        <span style={{ display: "inline-flex", alignItems: "center" }}>
            <select name="honorific" value={value.honorific} onChange={handleOnChange}>
                <option value=""></option>
                {honorifics.map((honorific, honorificIndex) => (
                    <option key={`honorific-${honorificIndex}`} value={honorific}>{trans(`general.honorifics.${honorific}`, {locale})}</option>
                ))}
            </select>{" "}
            <input {...inputProps} className="form-input form-input-lg" type="text" name="name" value={value.name} onChange={handleOnChange}/>
        </span>
    )
}

NameWithHonorific.propTypes = {
    value: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default NameWithHonorific
