import {
    dbFailed,
    dbStart,
    dbSuccess,
}                     from "../reducers/system/action"
import { DB_REQUEST } from "../reducers/system/type"

const DBMiddleware = ({ dispatch }) => next => async action => {
    next(action)

    const { type, payload } = action

    if (type !== DB_REQUEST) {
        return
    }

    const { config, onStart, onRequest, onSuccess, onError } = payload
    const { showLoader } = { showLoader: true, ...config }

    if (showLoader) {
        dispatch(dbStart())
    }

    if (onStart) {
        onStart()
    }

    try {
        const response = await onRequest()
        if (showLoader) {
            dispatch(dbSuccess())
        }

        if (onSuccess) {
            onSuccess(response)
        }
    } catch (error) {
        if (showLoader) {
            dispatch(dbFailed())
        }

        if (!onError) {
            throw error
        }

        onError(error)
    }
}

export default DBMiddleware
