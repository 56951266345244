import {
    OFFICERS,
    WARD_ADMIN,
}                                   from "../../config/Constants/UserRoles"
import {
    auth,
    call,
    electron,
    log,
}                                   from "../../helpers"
import { setWardProfile }           from "../reducers/municipality/action"
import { setSifarishList }          from "../reducers/sifarish/action"
import {
    fetchWardProfileFromApi,
    fetchWardProfileFromDB,
    setWardProfileToApi,
    setWardProfileToDB,
    syncWardProfileWithSocket,
}                                   from "./MunicipalityActions"
import {
    fetchSifarishListFromApi,
    fetchSifarishListFromDB,
    setSifarishListToDB,
    syncSifarishListWithSocket,
}                                   from "./SifarishActions"
import { fetchStaticDataFromApi }   from "./StaticDataActions"
import { fetchTranslationFromApi }  from "./TranslationActions"
import { fetchWardUserListFromApi } from "./UserActions"

export const triggerDatabaseSync = () => (dispatch, getState) => {
    const isAuthenticated = auth.checkIfAuthenticated(getState())

    dispatch(syncTranslations())
    dispatch(syncStaticData())
    dispatch(syncAuthUserList())

    if (isAuthenticated) {
        const { role } = auth.getCurrentUser(getState())

        dispatch(syncSifarishList())

        if ([WARD_ADMIN, OFFICERS].includes(role)) {
            dispatch(syncWardProfile())
        }
    }
}

export const syncTranslations = (onSuccess, onFailed) => dispatch => {
    dispatch(fetchTranslationFromApi(onSuccess, onFailed))
}

export const syncStaticData = (onSuccess, onFailed) => dispatch => {
    dispatch(fetchStaticDataFromApi(onSuccess, onFailed))
}

export const syncAuthUserList = (onSuccess, onFailed) => (dispatch, getState) => {
    if (!electron.isElectronApp()) {
        call(onSuccess)
        return
    }

    const { municipalityName, ward } = getState().electron.appInfo

    dispatch(fetchWardUserListFromApi({ municipality: municipalityName, ward }, onSuccess, onFailed))
}

export const syncWardProfileForElectron = (onSuccess) => async (dispatch, getState) => {
    if (!electron.isElectronApp()) {
        call(onSuccess)
        return
    }

    const { municipalityName, ward } = getState().electron.appInfo
    let wardProfileFromDB = {}

    await new Promise(resolve => {
        dispatch(fetchWardProfileFromDB(municipalityName, ward, profile => {
            wardProfileFromDB = { ...profile }
            resolve()
        }, error => {
            dispatch(log(error))
            resolve()
        }))
    })

    await new Promise(resolve => {
        dispatch(syncWardProfileWithSocket({
            profile: wardProfileFromDB,
            municipality: municipalityName,
            ward,
        }, response => {
            dispatch(setWardProfileToDB(municipalityName, ward, response))
            dispatch(setWardProfile(response))
            resolve()
        }))
    })

    call(onSuccess)
}

export const syncWardProfile = () => async (dispatch, getState) => {
    const { municipality: { name: municipalityName }, ward } = auth.getCurrentUser(getState())

    await new Promise(resolve => {
        dispatch(fetchWardProfileFromDB(municipalityName, ward, profile => {
            dispatch(setWardProfileToApi(municipalityName, ward, profile, () => {
                resolve()
            }))
        }))
    })

    await new Promise(resolve => {
        dispatch(fetchWardProfileFromApi(municipalityName, ward, () => {
            resolve()
        }))
    })
}

export const syncSifarishList = () => dispatch => {
    dispatch(fetchSifarishListFromApi())
}

export const syncSifarishListsForElectron = onSuccess => async (dispatch, getState) => {
    if (!electron.isElectronApp()) {
        call(onSuccess)
        return
    }

    const { municipalityName, ward } = getState().electron.appInfo
    let sifarishList = {}

    await new Promise(resolve => {
        dispatch(fetchSifarishListFromDB({}, sifarish => {
            sifarishList = [...sifarish]
            resolve()
        }, error => {
            dispatch(log(error))
            resolve()
        }))
    })

    await new Promise(resolve => {
        dispatch(syncSifarishListWithSocket({
            sifarish: sifarishList,
            municipality: municipalityName,
            ward,
        }, response => {
            dispatch(setSifarishListToDB(response))
            dispatch(setSifarishList(response))
            resolve()
        }))
    })

    call(onSuccess)
}
