import HttpService from "../HttpService"

class ProfileService {
    changePassword = async (password) => {
        const { body } = await HttpService.patch(`/profile/change-password`, password)

        return body
    }
}

export default (new ProfileService())
