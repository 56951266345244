import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const FourCornerCertified = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { trans } = useTrans()

    formData = { ...defaultFormData, ...formData }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="चार किल्ला प्रमाणित"
                errors={errors}
                onChange={handleOnChange}/>

            <div className="body-content">
                <div className="form-text-container">
                    {trans(`municipalities.${staticData.municipality.name}`)}{" "}

                    वडा नं. <FormFieldWard errors={errors} value={formData.applicant_ward} name="applicant_ward" isPreview={isPreview} onChange={handleOnChange}/>{" "}
                    <FormFieldPreviousAddress errors={errors} value={formData.previous_address} name="previous_address" isPreview={isPreview} onChange={handleOnChange}/> अन्तर्गत {" "}
                    <FormFieldName errors={errors} value={formData.applicant} name="applicant" isPreview={isPreview} onChange={handleOnChange} placeholder={trans("general.name")}/>{" "}
                    को नाममा मालपोत कार्यालयमा श्रेस्ता दर्ता कायम रहेको जग्गाको चार किल्ला निम्न विवरण अनुसार भएको प्रमाणित गरिन्छ ।
                </div>

                <FormTable title="चार किल्ला विवरण"
                           name="propertyDetails"
                           structure={[
                               { name: "ward_no", width: "10%", label: "वडा नं.", component: FormFieldWard, defaultValue: "" },
                               { name: "sheet_no-kitta_no-area", width: "20%", label: "सिट नं/कि.नं/क्षेत्रफल", component: FormFieldText, defaultValue: "" },
                               { name: "fatherOrHusband", width: "10%", label: "बाटो भएको/नभएको", component: FormFieldText, defaultValue: "" },
                               { name: "east", width: "15%", label: "पूर्व", component: FormFieldText, defaultValue: "" },
                               { name: "west", width: "15%", label: "पश्चिम", component: FormFieldText, defaultValue: "" },
                               { name: "north", width: "15%", label: "उत्तर", component: FormFieldText, defaultValue: "" },
                               { name: "south", width: "15%", label: "दक्षिण", component: FormFieldText, defaultValue: "" },
                               { name: "remarks", width: "10%", label: "कैफियत", component: FormFieldText, defaultValue: "" },
                           ]}
                           value={formData.propertyDetails}
                           errors={errors}
                           isPreview={isPreview}
                           onChange={handleOnChange}/>


            </div>

            <FormFooter errors={errors} staticData={staticData} value={formData.footer} isPreview={isPreview} onChange={handleOnChange}/>
        </div>
    )
}

export default FourCornerCertified
