import React from 'react'
import {get} from "lodash";
import {MUNICIPALITY_ADMIN, SUPER_ADMIN} from "../../config/Constants/UserRoles";
import {distanceInWordsToNow} from "date-fns";
import {Can} from "../../components/permissions";
import {ACTIONS} from "../../config/PermissionConfig";
import {Link} from "react-router-dom";
import {useTrans} from "../../hooks"

const SifarishHistory = (props) => {
    const {index, sn, sifarish, currentUser, length} = props;
    const {trans, numberTrans} = useTrans();
    console.log(length, index)
    return (
        <tr key={index}
            className={
                ` ${index + 1 === 1 ? "tr-nested tr-nested-first-child" : "tr-nested"} ${length - 1 === index ? "tr-nested-last-child" : " "}`
            }>

            <td>{numberTrans(sn)}.{numberTrans(index + 1)}.</td>
            <td>{numberTrans(get(sifarish, "form_data.header.date", ""))}</td>
            <td>
                <span style={{display: 'block'}}>{trans(`sifarish.forms.${sifarish.form_name}`)}</span>
                <em>({trans('general.old_update')}</em>)
            </td>
            <td>{get(sifarish, "form_data.applicant.name", "")}</td>
            {currentUser.role === SUPER_ADMIN && (
                <td>
                    {sifarish.municipality_name
                        ? trans(`municipalities.${sifarish.municipality_name}`)
                        : "-"}
                </td>
            )}
            {(currentUser.role === SUPER_ADMIN ||
                currentUser.role === MUNICIPALITY_ADMIN) && (
                <td>{sifarish.ward ? numberTrans(sifarish.ward) : "-"}</td>
            )}
            <td>{distanceInWordsToNow(new Date(sifarish.created_at))} ago</td>
            <td>
                <Can action={ACTIONS.SIFARISH.VIEW}>
                    <Link
                        style={{display: "block"}}
                        to={`/admin/sifarish/${sifarish.app_index}`}
                        className="btn-link btn-view-form"
                    >
                        {trans("general.view_form")}
                    </Link>
                </Can>
            </td>
        </tr>
    )
}

export default SifarishHistory
