import React, {
    useRef,
    useLayoutEffect,
    useEffect,
    useContext,
    useCallback,
} from "react"
import {FabricContext} from "./../../context/FabricContext"

const FabricCanvas = ({width = 816, height = 144, layoutType, data}) => {
    const canvasEl = useRef(null)
    const {canvas, initCanvas, setActiveObject, loadFromJSON} = useContext(FabricContext)

    const updateActiveObject = useCallback((e) => {
        if (!e) {
            return
        }
        setActiveObject(canvas.getActiveObject())
        canvas.requestRenderAll()
    }, [canvas, setActiveObject])

    useLayoutEffect(() => {
        initCanvas(canvasEl.current, {
            width: width,
            height: height,
        })
    }, [canvasEl, initCanvas, height, width])

    useLayoutEffect(() => {
        if(!data || !canvas){
            return
        }
        loadFromJSON(canvas, data.json)

    }, [canvasEl, loadFromJSON, data])

    useEffect(() => {
        if (!canvas) {
            return
        }

        canvas.on("selection:created", updateActiveObject)
        canvas.on("selection:updated", updateActiveObject)
        canvas.on("selection:cleared", updateActiveObject)

        return () => {
            canvas.off("selection:created")
            canvas.off("selection:cleared")
            canvas.off("selection:updated")
        }
    }, [canvas])

    useEffect(() => {
        if (canvas) {
            console.log('trying to re render canvas')
            canvas.renderAll.bind(canvas)
            canvas.requestRenderAll.bind(canvas)
        }
    }, [canvas])

    return (
        <div className={"rounded"}>
            <canvas ref={canvasEl} id="fabric-canvas"/>
        </div>
    )
}

export default FabricCanvas
