import { config } from "../../config"
import { LOG }    from "../reducers/system/type"

const LogMiddleware = () => next => action => {
    if (false && config("app.debug")) {
        console.info(">>> action:", action)
    }

    next(action)

    if (action.type !== LOG) {
        return
    }

    const payload = action.payload
    console.log(...payload)
}

export default LogMiddleware
