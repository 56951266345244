import PouchDB from "pouchdb"

class ElectronDBService {
    constructor() {
        this.db = new PouchDB("electron", { adapter: "idb" })
    }

    async getByKey(key) {
        return await this.db.get(key)
    }

    async setKey(key, value) {
        const data = {
            _id: key,
            value,
        }

        try {
            const dbValue = await this.getByKey(key)
            data["_rev"] = dbValue._rev
        } catch (error) {
            console.error(`No data found in electron db for key ${key}`, error)
        }

        return await this.db.put(data, { force: true })
    }
}

export default (new ElectronDBService())
