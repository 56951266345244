import PropTypes           from "prop-types"
import React               from "react"
import { useTrans }        from "../../../hooks"
import { FormFieldSelect } from "./"

const FormFieldChildType = ({ isPreview, value, name, onChange, errors, locale }) => {
    const { trans } = useTrans()

    const children = ["son", "daughter"].reduce((collection, child) => {
        collection[child] = trans("general.children." + child, {locale})
        return collection
    }, {})

    const handleOnChange = (event) => {
        onChange({ name, value: event.value })
    }

    return (<FormFieldSelect name={name} value={value} isPreview={isPreview} options={children} onChange={handleOnChange} errors={errors}/>)

}

FormFieldChildType.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldChildType
