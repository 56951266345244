import { get }              from "lodash"
import PropTypes            from "prop-types"
import React, {useContext, useEffect} from "react"
import { useTrans }         from "../../../hooks"
import { FormFieldText }    from "./"
import { PublicSifarishFormContext } from "../../../pages/sifarish/SifarishFormForPublic";

const FormFooter = ({ staticData, value: footerData, onChange, isPreview, errors, locale }) => {
    const officers = ["chairman", "acting_chairman", "secretary"]
    const { trans, numberTrans } = useTrans()
    const { ward, wardProfile: officerName } = staticData
    const sifarishForm = useContext(PublicSifarishFormContext)
    const isPublicView = get(sifarishForm, 'isPublic', false)

    const formFooterData = {
        officer_type: "chairman",
        officer_name: get(officerName, "chairman"),
        ...footerData,
    }

    // useEffect(() => {
    //     if (!isPreview) {
    //         onChange({ name: "footer", value: formFooterData })
    //     }
    // }, [formFooterData, isPreview, onChange])

    const handleOnOfficerTypeChange = event => {
        const name = event.target.name
        const value = event.target.value

        const data = { ...formFooterData }
        data[name] = value
        data["officer_name"] = get(officerName, value, "")

        onChange({ name: "footer", value: data })
    }

    const handleOnChangeCustom = ({ name, value }) => {
        onChange({ name: "footer", value: { ...formFooterData, [name]: value } })
    }

    return (
        !isPublicView && (
            <div className="bottom mt-5">
                {isPreview && (<p>...................</p>)}

                <p>
                    <FormFieldText
                        value={get(formFooterData, "officer_name", "") ||
                        get(officerName, get(formFooterData, "officer_type") || "chairman", "")}
                        name="officer_name"
                        isPreview={isPreview}
                        onChange={handleOnChangeCustom}
                        hideBlank={true}
                        errors={errors}/>
                </p>

                {isPreview ? (
                    <p className="">
                        {trans(`general.officers.${get(formFooterData, "officer_type") || "chairman"}`,
                            { locale })}, {trans(
                        "general.ward_no", { locale })} {numberTrans(ward, { locale })}
                    </p>
                ) : (
                    <select
                        name="officer_type"
                        value={get(formFooterData, "officer_type") || "chairman"}
                        onChange={handleOnOfficerTypeChange}
                    >
                        {officers.map((officer, officerIndex) => (
                            <option key={`officer-${officerIndex}`} value={officer}>
                                {trans(`general.officers.${officer}`, { locale })}
                            </option>
                        ))}
                    </select>
                )}
            </div>
        )
    )
}

FormFooter.propTypes = {
    staticData: PropTypes.object.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    isPreview: PropTypes.bool.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFooter
