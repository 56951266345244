import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldChildType,
    FormFieldLocalGovernment,
    FormFieldPreviousAddress,
    FormFieldSelect,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const ScholarshipForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    const FormFieldFinancialState = props => {
        const { value, name, isPreview, onChange } = props

        let options = {
            weak_financial_state: trans("general.weak_financial_state"),
            low_income: trans("general.low_income"),
        }

        return (
            <FormFieldSelect
                value={value}
                name={name}
                isPreview={isPreview}
                onChange={onChange}
                options={options}
                errors={errors}
            />
        )
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="छात्रवृत्ति सिफारिस।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त विषयमा{" "}
                    <FormFieldLocalGovernment
                        value={formData.municipality}
                        name="municipality"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    वडा नं.{" "}
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    <FormFieldPreviousAddress
                        errors={errors}
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    अन्तर्गत बसोबास गर्ने श्री{" "}
                    <FormFieldText
                        value={formData.applicant_father}
                        name="applicant_father"
                        isPreview={isPreview}
                        placeholder={trans("general.father_name")}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    तथा श्रीमती{" "}
                    <FormFieldText
                        value={formData.applicant_mother}
                        name="applicant_mother"
                        isPreview={isPreview}
                        placeholder={trans("general.mother_name")}
                        onChange={handleOnChange}
                        errors={errors}
                    />
                    को{" "}
                    <FormFieldFinancialState
                        value={formData.financial_state}
                        name="financial_state"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    हुँदा निजहरूको देहाय बमोजिमका{" "}
                    सन्तानहरुलाई नियमानुसार छात्रवृत्तिको लागि सिफारिस गरिन्छ।
                </div>

                <FormTable title=""
                           name="beneficiaries"
                           structure={[
                               { name: "name", width: "25%", label: "नाम", component: FormFieldText, defaultValue: "" },
                               {
                                   name: "relation",
                                   width: "30%",
                                   label: "नाता",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                               {
                                   name: "house_number",
                                   width: "30%",
                                   label: "घर नं.",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                           ]}
                           value={formData.beneficiaries}
                           isPreview={isPreview}
                           errors={errors}
                           onChange={handleOnChange}/>
            </div>

            <FormFooter
                staticData={staticData}
                isPreview={isPreview}
                value={formData.footer}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default ScholarshipForm
