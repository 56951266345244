import React, {
    useCallback,
    createContext,
    useState,
} from "react"
import {fabric} from "fabric"

const defaultOptions = {
    preserveObjectStacking: true,
    selection: true,
    defaultCursor: "default",
    backgroundColor: "#ffffff",
    width: 816,
    height: 144,
}

export const FabricContext = createContext([])

export const FabricContextProvider = ({children}) => {
    const [canvas, setCanvas] = useState(null)
    const [activeObject, setActiveObject] = useState(null)

    const initCanvas = useCallback((el, options = {}) => {
        const canvasOptions = {...defaultOptions, ...options}
        let c = new fabric.Canvas(el, canvasOptions)
        c.renderAll()
        setCanvas(c)
    }, [])

    const loadFromJSON = useCallback((c, json) => {
        c.loadFromJSON(
            json,
            () => {
                c.renderAll.bind(c)
                c.setWidth(json.width)
                c.setHeight(json.height)
            },
            function (o, object) {
                fabric.log(o, object)
            })
    }, [])

    return (
        <FabricContext.Provider
            value={{canvas, activeObject, initCanvas, setActiveObject, loadFromJSON}}>
            {children}
        </FabricContext.Provider>
    )
}
