import { ProfileService }      from "../../services/profile"
import { API_REQUEST }       from "../reducers/system/type"

export const changePassword = ( password, onSuccess, onError = null) => dispatch => {
    dispatch({
        type: API_REQUEST,
        payload: {
            onRequest: () => ProfileService.changePassword(password),
            onSuccess: response => {
                onSuccess(response)
            },
            onError: error => {
                if (onError) {
                    onError(error.response.data.errors)
                }
            },
        },
    })
}