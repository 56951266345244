import React from "react"
import { useTrans } from "../../../../hooks"
import {
    FormFooter,
    FormHeader,
    FormFieldCkEditor
} from "../../fields"
import defaultFormData from "./formData"

const BlankForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    const {  ward } = staticData
    const { trans } = useTrans()

    formData = {
        ...defaultFormData,
        current_ward: ward,
        ...formData,
    }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject=""
                onChange={handleOnChange}
                isBlank={true}
            />

            <div className="body-content">
                <FormFieldCkEditor
                 value={formData.editorData}
                 isPreview={isPreview}
                 errors={errors}
                 subject=""
                 onChange={handleOnChange}
                 name="editorData"
                />
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                errors={errors}
                isPreview={isPreview}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default BlankForm
