import React from "react"
import FabricCanvas              from "../../../components/canvas/FabricCanvas"
import FabricExport              from "../../../components/canvas/FabricExport"
import FabricToolbar             from "../../../components/canvas/FabricToolbar"
import { NoResult }              from "../../../components/empty-state"
import { ACTIONS }               from "../../../config/PermissionConfig"
import { FabricContextProvider} from "../../../context/FabricContext"
import { usePermission }         from "../../../hooks"
import DeleteBtn from   "../../../components/canvas/objects/FabricDelete"

const FooterLayoutManager = ({footerData}) => {
    const { check } = usePermission()
    const canEdit = check(ACTIONS.LAYOUT.EDIT)
    const canView = check(ACTIONS.LAYOUT.VIEW)

    return (
        <div>{
            canEdit &&

            <FabricContextProvider>
                <div className="main-container rounded" style={{ "alignItems": "stretch" }}>
                    <div className="sidebar">
                        <FabricToolbar/>
                    </div>
                    <div className="content rounded" style={{ "flex": "1", "overflowX": "auto" }}>
                        <div>
                        <FabricCanvas width="816" height="72" layoutType='footer' data={footerData}/>
                        </div>
                    </div>
                </div>
                <FabricExport layoutType='footer'/>
            </FabricContextProvider>
        }
            {
                !canEdit && canView && !footerData &&
                <NoResult text={"No Footer Layout is set. Please contact admin."}/>
            }
            {
                !canEdit && canView && footerData &&
                <img src={footerData.dataURL} alt="footer layout"/>
            }
        </div>

    )
}
export default FooterLayoutManager
