import React, {
    useEffect,
    useMemo,
    useState,
}                              from "react"
import {
    useDispatch,
    useSelector,
}                              from "react-redux"
import {
    apiCall,
    log,
}                              from "../../helpers"
import { MunicipalityService } from "../../services/municipality"
import { PageNotFound }        from "../errors"
import SifarishFormForPublic   from "./SifarishFormForPublic"

const PublicFormWrapper = ({ municipalityName, ward, formName, ...props }) => {
    const municipalities = useSelector(state => state.municipality.municipalities)
    const municipality = useMemo(
        () => municipalities.find(mun => mun.name === municipalityName),
        [municipalities, municipalityName],
    )
    const [isValidRequest, setIsValidRequest] = useState(false)
    const [wardProfile, setWardProfile] = useState({})
    const dispatch = useDispatch()

    useEffect(() => {
        try {
            if (!municipality) {
                throw new Error("Invalid municipality.")
            }

            if (isNaN(ward) || ward < 1 || ward > municipality.no_of_wards) {
                throw new Error("Invalid ward.")
            }

            setIsValidRequest(true)
        } catch (e) {
            dispatch(log(e.message))
            setIsValidRequest(false)
        }
    }, [dispatch, municipality, ward])

    useEffect(() => {
        if (isValidRequest) {
            dispatch(apiCall({
                onRequest: () => MunicipalityService.fetchWardProfile(municipality.name, ward),
                onSuccess: wardProfile => {
                    setWardProfile(wardProfile)
                },
                onError: error => {
                    dispatch(log(`Ward profile fetching from api failed for ${municipality.name} ${ward}`, error))
                    setIsValidRequest(false)
                },
            }))
        }
    }, [dispatch, isValidRequest, municipality.name, ward])

    if (!isValidRequest) {
        return <PageNotFound/>
    }

    return (
        <SifarishFormForPublic
            municipality={municipality}
            ward={ward}
            wardProfile={wardProfile}
            formName={formName}
            {...props} />
    )
}

export default PublicFormWrapper
