export default {
    header: {},
    footer: {},
    salutation: {},

    ward: "",
    applicant: {},
    kitta: "",
    area: "",
}
