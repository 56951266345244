import React            from "react"
import { ACTIONS }      from "../../config/PermissionConfig"
import { PageNotFound } from "../../pages/errors"
import {
    SifarishCreateForm,
    SifarishFormList,
    SifarishList,
    SifarishPrintForm,
    SifarishEditForm,
}                       from "../../pages/sifarish"

export default prefix => ([
    {
        auth: true,
        path: prefix,
        exact: true,
        component: SifarishList,
        hasSubHeader: true,
        can: [ACTIONS.SIFARISH.VIEW],
    },

    {
        auth: true,
        path: `${prefix}/forms`,
        exact: true,
        component: SifarishFormList,
        hasSubHeader: true,
        can: [ACTIONS.SIFARISH.ADD],
    },

    {
        auth: true,
        path: `${prefix}/forms/:name/create`,
        exact: true,
        component: (props) => {
            const formName = props.match.params.name
            if (!formName) {
                return <PageNotFound/>
            }

            return (
                <SifarishCreateForm {...props} formName={formName}/>
            )
        },
        hasSubHeader: false,
        can: [ACTIONS.SIFARISH.ADD],
    },

    {
        auth: true,
        path: `${prefix}/:id`,
        exact: true,
        component: (props) => {
            const sifarishId = props.match.params.id
            if (!sifarishId || isNaN(sifarishId)) {
                return <PageNotFound/>
            }

            return (
                <SifarishPrintForm {...props} sifarishId={sifarishId}/>
            )
        },
        hasSubHeader: false,
        can: [ACTIONS.SIFARISH.VIEW],
    },
    {
        auth: true,
        path: `${prefix}/:id/edit`,
        exact: true,
        component: (props) => {
            const sifarishId = props.match.params.id
            if (!sifarishId || isNaN(sifarishId)) {
                return <PageNotFound/>
            }

            return (
                <SifarishEditForm {...props} sifarishId={sifarishId} />
            )
        },
        hasSubHeader: false,
        can: [ACTIONS.SIFARISH.VIEW],
    },
])
