import React, { useEffect } from "react"

const Toaster = ({ content, type, onRemove }) => {
    useEffect(() => {
        const interval = setInterval(() => {
            onRemove()
        }, 1000 * 30)

        return () => clearInterval(interval)
    }, [onRemove])

    return (
        <div className={`toast ${type}`}>
            <div className="toast-control">
                <button onClick={onRemove} type="button" className="ml-2 mb-1 close">
                    <span>&times;</span>
                </button>
            </div>

            <div className="toast-body">
                {content}
            </div>
        </div>
    )
}

export default Toaster
