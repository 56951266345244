import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldKitta,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const HomeRoad = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { ward } = staticData
    formData = { ...defaultFormData, currentWard: ward, ...formData }

    const { municipality } = staticData
    const { trans } = useTrans()
    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader staticData={staticData} errors={errors} value={formData.header} isPreview={isPreview} subject="घरबाटो प्रमाणित" onChange={handleOnChange}/>

            <div className="body-content">
                <div className="form-text-container">
                    {trans(`municipalities.${municipality.name}`)} वडा नं.{" "}
                    <FormFieldWard
                        value={formData.currentWard}
                        name="currentWard"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    /> अन्तर्गत{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />को नाममा त्यस कार्यालयमा श्रेस्ता दर्ता कायम रहेको जग्गाको घरबाटो तपशिलमा उल्लेखित विवरण अनुसार भएको व्यहोरा प्रमाणित गरिन्छ।
                </div>

                <FormTable title={trans("general.description_of_home_road")} name="propertyDetails"
                           structure={[
                               {
                                   name: "ward",
                                   width: "10%",
                                   label: trans("general.Ward"),
                                   component: FormFieldWard,
                                   defaultValue: "",
                               },
                               {
                                   name: "sheet",
                                   width: "20%",
                                   label: "सिट नं",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                               {
                                   name: "kitta",
                                   width: "20%",
                                   label: "कित्ता नं",
                                   component: FormFieldKitta,
                                   defaultValue: "",
                               },
                               {
                                   name: "area",
                                   width: "20%",
                                   label: "क्षेत्रफल",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                               {
                                   name: "house_status",
                                   width: "20%",
                                   label: "घर भएको / नभएको",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                               {
                                   name: "road_type",
                                   width: "30%",
                                   label: "बाटोको प्रकार",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                               {
                                   name: "remarks",
                                   width: "20%",
                                   label: "कैफियत",
                                   component: FormFieldText,
                                   defaultValue: "",
                               },
                           ]}
                           value={formData.propertyDetails}
                           isPreview={isPreview}
                           errors={errors}
                           onChange={handleOnChange}
                />
            </div>

            <FormFooter staticData={staticData} errors={errors} isPreview={isPreview} value={formData.footer} onChange={handleOnChange}/>
        </div>
    )
}

export default HomeRoad
