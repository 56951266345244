export default {
    header: {},
    footer: {},
    salutation: {},

    birth_local_government: {},
    birth_ward: "",
    birth_date: "",
    current_ward: "",
    age: "",
    applicant: {},
    district_office: "",
    issued_date: "",
    citizenship_no: "",
    citizenship_type: "",
    reason: "",
}
