import * as types from "./type"

const initialState = {
    forms: [],
    categories: [],
    sifarishList: {
        data: [],
    },
}

const SifarishReducer = (state = initialState, { type, payload }) => {
    switch (type) {
        case types.SET_FORMS:
            return { ...state, forms: payload }

        case types.SET_FORMS_CATEGORIES:
            return { ...state, categories: payload }

        case types.SET_SIFARISH_LIST:
            return { ...state, sifarishList: { ...state.sifarishList, data: payload } }

        default:
            return state
    }
}

export default SifarishReducer
