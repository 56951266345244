import React, {
    useCallback,
    useState,
}                   from "react"
import {
    useDispatch,
    useSelector,
}                   from "react-redux"
import { auth }     from "../../helpers"
import { dumpData } from "../../store/actions/DataDumperActions"

const defaultSyncStatutes = {
    forms: {
        label: "Sifarish Forms",
        status: "starting",
    },
    profile: {
        label: "Ward Profiles",
        status: "starting",
    },
}

const DataDumper = () => {
    const [isLoading, setIsLoading] = useState(false)
    const [syncStatuses, setSyncStatuses] = useState(defaultSyncStatutes)
    const dispatch = useDispatch()

    const municipality = useSelector(auth.getCurrentMunicipality)
    const ward = useSelector(auth.getCurrentWard)

    const handleOnSync = useCallback(() => {
        setIsLoading(true)

        dispatch(dumpData({ municipality, ward }, (message, key) => {
            setSyncStatuses(statuses => ({ ...statuses, [key]: message }))
        }, () => {
            setIsLoading(false)
        }, () => {
            setIsLoading(false)
        }))
    }, [dispatch, municipality, ward])

    return (
        <div className="data-syncer">
            <div>
                <button type="button"
                        className="btn-outline btn-primary"
                        disabled={isLoading}
                        onClick={handleOnSync}>
                    {isLoading ? "Uploading..." : "Upload data to server"}
                </button>
            </div>

            <div className="loading-message">
                <ul>
                    {Object.entries(syncStatuses).map(([key, value]) => (
                        <li key={key} className={`loading-animation ${value.status}`}>{value.label}</li>
                    ))}
                </ul>
            </div>
        </div>
    )
}

export default DataDumper
