import { useMemo }     from "react"
import { useSelector } from "react-redux"
import { config }      from "../config"
import { auth }        from "../helpers"

const usePermission = () => {
    const { role } = useSelector(auth.getCurrentUser)
    const policies = useMemo(() => config("permission.POLICIES"), [])

    const check = (action, condition = null) => {
        const { GLOBAL, ...roleBasedPolicies } = policies
        if (GLOBAL.static.includes(action)) {
            return true
        }

        const globalDynamic = GLOBAL.dynamic[action]
        if (globalDynamic) {
            return globalDynamic(condition)
        }

        const permissions = roleBasedPolicies[role]
        if (!permissions) {
            return false
        }

        const staticPermissions = permissions.static
        if (staticPermissions && staticPermissions.includes(action)) {
            return true
        }

        const dynamicPermissions = permissions.dynamic
        if (!dynamicPermissions) {
            return false
        }

        const dynamicCondition = dynamicPermissions[action]
        if (!dynamicCondition) {
            return false
        }

        return dynamicCondition(condition)
    }

    return { check }
}

export default usePermission
