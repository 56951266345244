import {
    apiCall,
    call,
    dbCall,
    log,
}                              from "../../helpers"
import { MunicipalityService } from "../../services/municipality"
import MunicipalityDBService   from "../../services/municipality/MunicipalityDBService"
import { socket }              from "../../services/SocketService"
import { setWardProfile }      from "../reducers/municipality/action"

export const syncWardProfileWithSocket = ({ profile, municipality, ward }, onSuccess) => (dispatch) => {
    socket.emit("syncWardProfile", { profile, municipality, ward }, response => {
        call(onSuccess, response)
    })
}

export const fetchWardProfileFromApi = (municipality, ward, onSuccess, onError) => dispatch => {
    if (!municipality || !ward) {
        call(onSuccess, {})
        return
    }

    dispatch(apiCall({
        onRequest: () => MunicipalityService.fetchWardProfile(municipality, ward),
        onSuccess: wardProfile => {
            dispatch(setWardProfile(wardProfile))
            dispatch(setWardProfileToDB(municipality, ward, wardProfile, () => {
                dispatch(fetchWardProfile(municipality, ward, onSuccess, onError))
            }))
        },
        onError: error => {
            call(onError, error)
        },
    }))
}

export const fetchWardProfileFromDB = (municipality, ward, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => MunicipalityDBService.getWardProfile(municipality, ward),
        onSuccess,
        onError: error => {
            dispatch(log(`Ward profile fetching from database failed for ${municipality} ${ward}`, error))
            call(onError, error)
        },
    }))
}

export const setWardProfileToDB = (municipality, ward, profile, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => MunicipalityDBService.setWardProfile(municipality, ward, profile),
        onSuccess, onError,
    }))
}

export const setWardProfileToApi = (municipality, ward, profile, onSuccess, onError) => dispatch => {
    dispatch(apiCall({
        onRequest: () => MunicipalityService.saveWardProfile(profile, { municipality, ward }),
        onSuccess, onError,
    }))
}

export const saveWardProfile = (profile, { municipality, ward }, onSuccess, onError = null) => dispatch => {
    dispatch(setWardProfileToDB(municipality, ward, profile, () => {
        call(onSuccess, {
            message: "Ward profile saved successfully.",
        })
    }, onError))
}

export const fetchWardProfile = (municipality, ward, onSuccess, onError) => dispatch => {
    dispatch(fetchWardProfileFromDB(municipality, ward, profile => {
        dispatch(setWardProfile(profile))

        call(onSuccess, profile)
    }))
}
