export default {
    header: {},
    footer: {},
    salutation: {},

    current_ward: "",
    parentName: {},
    relation: "",
    applicant: {},
    applicationDate: "",
    surname: "",
    sifarishDate: "",
}
