import React           from "react"
import { useSelector } from "react-redux"
import { useTrans }    from "./../../hooks"

const SifarishFilter = (props) => {
    const categories = useSelector(state => state.sifarish.categories)
    const forms = useSelector(state => state.sifarish.forms)

    const { trans } = useTrans()
    const { filterToggle } = props

    return (
        <div className="filter-container">
            <div className="filter-form-wrapper">
                <div className="filter-top">
                    <div className="filter-input">
                        <div>search</div>
                    </div>
                    <div className="filter-actions">
                        <button className="btn-outline btn-primary">
                            फिल्टर गर्नुहाेस्
                        </button>
                        <button className="btn-link btn-filter-reset">
                            सबै हटाउनुहोस्
                        </button>
                        <button
                            className="btn-link"
                            onClick={() => filterToggle()}
                        >
                            Cancel
                        </button>
                    </div>
                </div>
                <div className="filter-options">
                    <div className="filter-option date">
                        <span>सुरू - अन्त्य मिति</span>
                    </div>
                    {/* <div className="filter-option form-type">
                                <span>सिफारिस फारमहरूको  प्रकार </span>
                            </div> */}
                    <div className="filter-option">
                        <span>सिफारिस फारमहरू</span>
                    </div>

                    <ul className="filter-select">
                        <p className="filter-select-title">सिफारिस फारमहरू</p>
                        {categories && categories.map((category, categoryIndex) => (
                            <li key={categoryIndex} className="filter-select-category">
                                <label className="checkbox-container">
                                    <input type="checkbox"/>
                                    <span className={`form-categories ${category}`}>
                                        {trans(`sifarish.categories.${category}`)}
                                    </span>
                                    <span className="checkmark"></span>
                                </label>

                                <ul className="filter-select-options">
                                    {forms.filter(form => form.category === category)
                                          .map((filteredForm, filteredFormIndex) => (
                                              <li key={filteredFormIndex} className="filter-select-option">
                                                  <label className="checkbox-container">
                                                      <input type="checkbox"/>
                                                      <span className="filter-select-option-label">
                                                    {trans(`sifarish.forms.${filteredForm.name}`)}
                                                </span>
                                                      <span className="checkmark"></span>
                                                  </label>
                                              </li>
                                          ))}
                                </ul>
                            </li>
                        ))
                        }
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default SifarishFilter
