import {
    isEmpty
} from "lodash"
import PropTypes from "prop-types"
import React from "react"
import { CKEditor } from "@ckeditor/ckeditor5-react"
import ClassicEditor from "@ckeditor/ckeditor5-build-classic"

const FormFieldCkEditor = (props) => {
    const { isPreview, value, name, onChange, errors } = props

    if (!isEmpty(errors)) { console.error(errors) }

    return isPreview ? (
        <p
            className="project-info"
            dangerouslySetInnerHTML={{
                __html: value,
            }}
        ></p>
    ) : (
            <CKEditor
                editor={ClassicEditor}
                data={value}
                onChange={(_, editor) => {
                    const data = editor.getData()
                    onChange({ name, value: data })
                }}
                config={{
                    toolbar: ["Heading", "bold", "italic", "numberedList", "insertTable"],
                }}
            />
        )
}

FormFieldCkEditor.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldCkEditor
