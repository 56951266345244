import HttpService from "../HttpService"

class DataDumperService {
    upload = async data => {
        const { body } = await HttpService.post("/utilities/dump-data", data)

        return body.data
    }

    uploadNewData = async data => {
        const { body } = await HttpService.post("/utilities/dump-new-data", data)
        return body.data
    }
}

export default (new DataDumperService())
