import { isEmpty }          from "lodash"
// import NepaliDatePicker from "../../../lib/NepaliDatePicker"
import { NepaliDatePicker } from "nepali-datepicker-reactjs"
import PropTypes            from "prop-types"
import React                from "react"
import { useTrans }         from "../../../hooks"

const FormFieldDate = ({ isPreview, value, name, onChange, errors, locale }) => {
    const { numberTrans } = useTrans()

    if (!isEmpty(errors)) {
        console.error(errors)
    }

    const handleOnChange = (date) => {
        onChange({
            name, value: date ? date.replace(/-/g, "/") : null,
        })
    }

    return isPreview ? (
        <span className="form-input-view">{numberTrans(value || "", { locale })}</span>
    ) : (
        // <NepaliDatePicker inputClass="form-input form-input-lg" name={name} value={value || ""} placeholder="yyyy-mm-dd" onChange={event => onChange({ name: event.target.name, value: event.target.value })} />
        <NepaliDatePicker value={value ? value.replace(/\//g, "-") : null}
                          inputClassName="form-input form-input-lg"
                          onSelect={handleOnChange}
                          options={{
                              closeOnSelect: true,
                              calenderLocale: "ne",
                              valueLocale: "en",
                          }}/>
        // <NepaliDatePicker value={value} name={name} onChange={onChange} locale={locale}
        //                   className="form-input form-input-lg"/>
    )
}

FormFieldDate.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldDate
