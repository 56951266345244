import {
    setOffline,
    setOnline,
} from "../../store/reducers/system/action"

export default class CommonEvents {
    constructor(props) {
        this.props = props
    }

    listener() {
        this.props.socket.on("connect_error", this.onConnectionFailed.bind(this))
        this.props.socket.on("connected_to_server", this.onConnectedToServer.bind(this))
        this.props.socket.on("ping", this.onPing.bind(this))
        this.props.socket.on("disconnect", this.onDisconnect.bind(this))
    }

    onConnectionFailed() {
        this.props.dispatch(setOffline())
    }

    onConnectedToServer() {
        this.props.dispatch(setOnline())
    }

    onPing() {
        this.props.dispatch(setOnline())
    }

    onDisconnect() {
        this.props.dispatch(setOffline())
    }
}
