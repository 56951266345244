import React  from "react"
import FabricImage           from "./objects/FabricImage"
import FabricLine            from "./objects/FabricLine"
import FabricTextBox         from "./objects/FabricTextBox"
import DeleteBtn from "./objects/FabricDelete";

const FabricToolbar = props => {
    return (
        <>
            <ul className="sidebar-menu">
                <li className="sidebar-menu-item"> <FabricTextBox/> </li>
                <li className="sidebar-menu-item"> <FabricLine/></li>
                <li className="sidebar-menu-item"> <FabricImage/></li>
                <li><DeleteBtn/></li>
            </ul>
        </>
    )
}

export default FabricToolbar

