import { ADToBS } from "bikram-sambat-js"
import { get } from "lodash"
import { englishToNepaliNumber } from "nepali-number"
import PropTypes from "prop-types"
import React, {
    Fragment,
    useContext,
} from "react"
import * as images from "../../../assets/image"
import { useTrans } from "../../../hooks"
import { PublicSifarishFormContext } from "../../../pages/sifarish/SifarishFormForPublic"
import {
    FormFieldDate,
    FormFieldText,
    FormSalutation,
    FormFieldEngDate
} from "./"
import moment from "moment"
import {dhangadhiLogo} from "../../../assets/image";

const FormHeader = ({ staticData, subject, isPreview, value: headerData, onChange, errors, salutation, locale, isBlank = false }) => {
    const { municipality, ward } = staticData
    const { trans, numberTrans } = useTrans()
    const sifarishForm = useContext(PublicSifarishFormContext)
    const isPublicView = get(sifarishForm, "isPublic", false)

    const date = locale == 'en' ?
        moment(new Date()).format("YYYY-MM-DD").replace(/-/g, '/') :
        englishToNepaliNumber(ADToBS(new Date()));

    headerData = {
        letter_no: "",
        chalani_no: "",
        address: get(staticData, "wardProfile.address"),
        salutation: {},
        date,
        ...headerData,
    }

    const handleOnChange = ({ name, value }) => {
        onChange({ name: "header", value: { ...headerData, [name]: value } })
    }

    return (
        <Fragment>
            {!isPublicView && (
                <div className={`sifarish-form-header ${locale ? locale : ""}`}>

                    {
                        staticData.layoutData && staticData.layoutData.header ?
                            <>
                                <img src={staticData.layoutData.header} className="" alt="Form header"
                                    style={{ "width": "100%" }} />
                            </>
                            :
                            <>
                                <img src={images.govLogo} className="gov-logo" alt="" />
                                {municipality.name === 'mellekh' && <img src={images.mellekhLogo} className="visit-nepal" alt=""/>}
                                <p className="municipality">{trans(`municipalities.${municipality.name}`,
                                    { locale })}</p>
                                <p className="ward">
                                    {numberTrans(ward, { locale })} {trans("general.no_ward_office", { locale })}
                                </p>
                                <p className="address">
                                    <FormFieldText
                                        value={get(headerData, "address", "")}
                                        name="address"
                                        isPreview={isPreview}
                                        onChange={handleOnChange}
                                        errors={errors}
                                        placeholder=""
                                    />, {" "}
                                    {(trans(`municipalities.city.${municipality.name}`, { locale }) + ", ")}
                                    {trans(`municipalities.district.${municipality.district}`, { locale })}
                                </p>
                                <p className="address">
                                    {trans(`municipalities.province_${municipality.province}`, { locale })},{" "}
                                    {trans(`municipalities.country_${municipality.country}`, { locale })}
                                </p>
                            </>
                    }
                    {
                        !isPublicView && (
                            <div className="letterpad-info">
                                {locale === "en" ? (
                                    <p>Reference No:</p>
                                ) : (
                                    <>
                                        <p>प.सं.:</p>
                                        <p>च.नं.:</p>
                                    </>
                                )}
                            </div>
                        )
                    }
                </div>
            )}

            <div className="body-top">
                {/* <div className="left">
                    <p>
                        पत्र संख्याः <FormFieldText value={headerData.letter_no} name="letter_no" isPreview={true} onChange={handleOnChange} errors={errors}/>
                    </p>

                    <p>
                        चलानी नंः <FormFieldText value={headerData.chalani_no} name="chalani_no" isPreview={true} onChange={handleOnChange} errors={errors}/>
                    </p>
                </div> */}

                <div className={`right ${(locale === 'en' && !isPreview) ? 'eng-date' : ''}`}>
                    <div className={'wrapper'}>
                        <span className={'date-lebel'}>{trans("general.date", { locale })}:{" "}</span>
                        {locale == 'en' ?
                            <FormFieldEngDate
                                name="date"
                                value={headerData.date}
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                                locale={locale}
                            /> : <FormFieldDate
                                value={headerData.date}
                                name="date"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                                locale={locale}
                            />}
                    </div>
                </div>

                {salutation ? (
                    salutation
                ) : (
                    <FormSalutation
                        value={headerData.salutation}
                        onChange={handleOnChange}
                        isPreview={isPreview}
                        errors={errors}
                        locale={locale}
                    />
                )}
                <div className="subject">
                    <p>
                        {trans("general.subject", { locale })}: {subject}
                        {isBlank ? (
                            <FormFieldText
                                value={get(headerData, "subject", "")}
                                name="subject"
                                isPreview={isPreview}
                                onChange={handleOnChange}
                                errors={errors}
                                placeholder=""
                            />) : (<></>)}
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

FormHeader.propTypes = {
    staticData: PropTypes.object.isRequired,
    subject: PropTypes.string.isRequired,
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormHeader
