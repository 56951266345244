export default {
    header: {},
    footer: {},
    salutation: {},

    permanentDistrict: "",
    permanentLocalGovernmentName: {},
    permanentWard: "",
    current_ward: "",
    applicant: {},
    ethnicity: "",
    date: "",
}
