import React, {
    useCallback,
    useState,
}                              from "react"
import { useDispatch }         from "react-redux"
import { InlineLoader }        from "../../components/loading"
import { updateObjectByIndex } from "../../helpers"
import { useTrans }            from "../../hooks"
import {
    sifarishManualSync,
    wardProfileManualSync,
    layoutManualSync
}                              from "../../store/actions/ManualSyncActions"

const initialModules = [
    {
        name: "sifarish",
        label: "Sifarish",
        isSyncing: false,
        handler: sifarishManualSync,
        message: "",
    },
    {
        name: "ward-profile",
        label: "Ward Profile",
        isSyncing: false,
        handler: wardProfileManualSync,
        message: "",
    },
    {
        name: "layout",
        label: "Layout",
        isSyncing: false,
        handler: layoutManualSync,
        message: "",
    },
]

const ManualSync = () => {
    const { trans, numberTrans } = useTrans()
    let [modules, setModules] = useState(initialModules)

    const dispatch = useDispatch()

    const setIsSyncing = useCallback((index, status) => {
        setModules((modules) => updateObjectByIndex(modules, index, { isSyncing: status }))
    }, [])

    const setMessageLog = useCallback((index, message) => {
        setModules((modules) => updateObjectByIndex(modules, index, { message }))
    }, [])

    const syncNow = useCallback((module, index) => {
        setIsSyncing(index, true)

        dispatch(module.handler(message => {
            setMessageLog(index, message)
        }, () => {
            setIsSyncing(index, false)
        }, () => {
            setIsSyncing(index, false)
        }))
    }, [dispatch, setIsSyncing, setMessageLog])

    return (
        <div>
            <div className="page-header-container">
                <h3 className="page-title">Manual Sync</h3>
            </div>

            <div className="table-responsive">
                <table className="table form-list table-striped table-hover">
                    <thead>
                        <tr>
                            <th style={{ width: "15%" }}>{trans("general.s_n")}</th>
                            <th style={{ width: "30%" }}>Module</th>
                            <th style={{ width: "25%" }}></th>
                            <th></th>
                        </tr>
                    </thead>

                    <tbody>
                        {modules.map((module, index) => (
                            <tr key={index}>
                                <td>{numberTrans(index + 1)}</td>
                                <td>{module.label}</td>
                                <td className="center-xs">
                                    {module.isSyncing ? (
                                        <InlineLoader/>
                                    ) : (
                                        <button onClick={() => syncNow(module, index)}
                                                className="btn-link btn-view-form">Sync now</button>
                                    )}
                                </td>
                                <td>{module.message}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ManualSync
