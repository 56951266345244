import { get }         from "lodash"
import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const TreatmentAssistance = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="सिफारिस गरी पठाएको बारे ।"
                errors={errors}
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्वन्धमा{" "}
                    {trans(`municipalities.${staticData.municipality.name}`)}{" "}
                    वडा नं&nbsp;
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    {trans(
                        `municipalities.city.${get(
                            staticData,
                            "municipality.city",
                        )}`,
                    )}
                    ,&nbsp;
                    {trans(
                        `municipalities.district.${get(
                            staticData,
                            "municipality.district",
                        )}`,
                    )}
                    ,&nbsp;
                    {trans(
                        `municipalities.province_${get(
                            staticData,
                            "municipality.province",
                        )}`,
                    )}
                    ,{" "}
                    {trans(
                        `municipalities.country_${get(
                            staticData,
                            "municipality.country",
                        )}`,
                    )}{" "}
                    बस्ने &nbsp;
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        placeholder={trans("general.name")}
                    />
                    ले म निवेदक विरामी भई उपचार गर्न मेरो पारिवारिक अवस्था कमजोर
                    भएकोले निःशुल्क उपचारका लागि सम्बन्धित निकायमा सिफारिस गरी
                    पाउँ भनी मिति&nbsp;
                    <FormFieldDate
                        value={formData.birth_date}
                        name="birth_date"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    गते यस कार्यालयमा निवेदन दिनु भएको हुदा निवेदक&nbsp;
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />
                    कोे पारिवारिक अवस्था अति गरिब भएकोले निजको&nbsp; नियमानुसार
                    निःशुल्क औषधि उपचार गराईदिन हुन सिफारिस साथ अनुरोध छ ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default TreatmentAssistance
