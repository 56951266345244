export default {
    header: {},
    footer: {},
    salutation: {},

    previous_address: {},
    name: {},
    current_ward: "",
    landDetails: [{}]
}
