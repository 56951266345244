import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldRelation,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const RelationshipCertificateDeceased = ({ staticData, formData, onChange, isPreview, errors }) => {

    const { municipality, ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    const PhotoComponent = () => {
        return <div className="photo-field">Photo</div>
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="नाता प्रमाणित प्रमाणपत्र ।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    देहायका व्यक्तिसंग देहाय बमोजिमको नाता सम्बन्ध रहेकोले सो
                    नाता सम्बन्ध प्रमाणित गरी पाऊँ{" "}
                    {trans(`municipalities.${municipality.name}`)}{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    नं. वडा कार्यालयमा{" "}
                    <FormFieldDate
                        value={formData.submit_date}
                        name={"submit_date"}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    मा दिनु भएको निवेदन बमोजिम यस कार्यालयबाट सर्जमिन मुचुल्का
                    गरि/ स्थानिय प्रहरी समेतबाट सर्जमिन साक्षी बुझ्दा तपाईको
                    देहाय बमोजिमका व्यक्तिसंग देहाय बमोजिमको नाता सम्बन्ध कायम
                    रहेको देखिएकोले स्थानिय सरकार सञ्‍चालन ऐन २०७४ बमोजिम नाता
                    प्रमाणित गरी यो प्रमाण पत्र दिइएको छ ।
                </div>

                <FormTable
                    title=""
                    name="relationshipDetail"
                    structure={[
                        {
                            name: "applicant",
                            width: "20%",
                            label: "सम्बन्धितहरु: ",
                            component: FormFieldName,
                            defaultValue: {},
                        },
                        {
                            name: "relation",
                            width: "20%",
                            label: "मृतक संग कायम रहेको  नाता सम्बन्ध: ",
                            component: FormFieldRelation,
                            defaultValue: "",
                        },
                    ]}
                    value={formData.relationshipDetail}
                    isPreview={isPreview}
                    errors={errors}
                    onChange={handleOnChange}
                />

                <p>
                    <FormFieldText
                        placeholder="Village name"
                        value={formData.village}
                        name="village"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />
                    , वडा न.
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />
                    ,{" "}
                    <FormFieldText
                        placeholder="City name"
                        value={formData.city}
                        name="city"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />
                    ,{" "}
                    <FormFieldText
                        placeholder="District name"
                        value={formData.district}
                        name="district"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />
                    ,{" "}
                    <FormFieldText
                        placeholder="Province name"
                        value={formData.province}
                        name="province"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    प्रदेश,{" "}
                    {trans(`municipalities.country_${municipality.country}`)}
                </p>
            </div>

            <div className="section-fingerprint">
                <div>
                    <p>दरखास्तवालाको दस्तखतः–</p>
                    <p>ल्याप्चे सहिछाप</p>
                    <div className="form-fingerprint">
                        <div className="fingerprint-right">
                            <span>दायाँ</span>
                        </div>
                        <div className="fingerprint-left">
                            <span>बायाँ</span>
                        </div>
                    </div>
                </div>
                <FormFooter
                    staticData={staticData}
                    isPreview={isPreview}
                    value={formData.footer}
                    onChange={handleOnChange}
                    errors={errors}
                />
            </div>

            <div className="body-content table-with-photo print-page-break">
                <FormTable
                    title=""
                    name="relationshipDetail"
                    structure={[
                        {
                            name: "applicant",
                            width: "20%",
                            label: "सम्बन्धितहरु: ",
                            component: FormFieldName,
                            defaultValue: {},
                        },
                        {
                            name: "relation",
                            width: "20%",
                            label: "मृतक संग कायम रहेको  नाता सम्बन्ध: ",
                            component: FormFieldRelation,
                            defaultValue: "",
                        },
                        {
                            name: "photo",
                            width: "25%",
                            label: "फोटो",
                            component: PhotoComponent,
                            defaultValue: "",
                        },
                    ]}
                    value={formData.relationshipDetail}
                    isPreview={true}
                    errors={errors}
                    onChange={handleOnChange}
                />
            </div>
        </div>
    )
}

export default RelationshipCertificateDeceased
