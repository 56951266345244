export default {
    header: {},
    salutation: {},
    footer: {},

    houseOwner: "",
    migrationType: "",
    migrationDate: "",
    exDistrict: "",
    exLocalGovernment: {},
    exWard: "",
    current_ward: "",
    currentAddress: "",
    familyDetails: [{}],
}
