import React, {
    useEffect,
    useMemo,
    useState,
}                          from "react"
import {
    useDispatch,
    useSelector,
}                          from "react-redux"
import {
    apiCall,
    log,
}                          from "../../helpers"
import { SifarishService } from "../../services/sifarish"
import { PageNotFound }    from "../errors"

const SifarishSubmissionSuccess = ({ municipalityName, ward, formName, token, ...props }) => {
    const municipalities = useSelector(state => state.municipality.municipalities)
    const municipality = useMemo(
        () => municipalities.find(mun => mun.name === municipalityName),
        [municipalities, municipalityName],
    )
    const [isValidRequest, setIsValidRequest] = useState(false)
    const [tokenImageUrl, setTokenImageUrl] = useState("")
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(apiCall({
            onRequest: () => SifarishService.getTokenImage(token),
            onSuccess: (response) => {
                setTokenImageUrl(response.image)
            },
            onError: (errors) => {
                console.error("Error while fetching image token.", errors.response)
            },
        }))
    }, [token, dispatch])

    useEffect(() => {
        try {
            if (!municipality) {
                throw new Error("Invalid municipality.")
            }

            if (isNaN(ward) || ward < 1 || ward > municipality.no_of_wards) {
                throw new Error("Invalid ward.")
            }

            setIsValidRequest(true)
        } catch (e) {
            dispatch(log(e.message))
            setIsValidRequest(false)
        }
    }, [dispatch, municipality, ward])

    if (!isValidRequest) {
        return <PageNotFound/>
    }

    return (
        <div className="container">
            <p>Your form has been submitted.</p>

            <p>Remember this token for updates.</p>

            <p><strong>{token}</strong></p>

            {/*{*/}
            {/*    tokenImageUrl && (*/}
            {/*        <a href={tokenImageUrl} download="sifarsish-token">*/}
            {/*            <button>Download</button>*/}
            {/*        </a>*/}
            {/*    )*/}
            {/*}*/}
        </div>
    )
}

export default SifarishSubmissionSuccess
