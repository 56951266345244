import HttpService from "../HttpService"

class SifarishService {
    getStatsByStatus = async () => {
        const { body } = await HttpService.get("/sifarish/stats/by-status")

        return body.data
    }

    getStatsByForm = async () => {
        const { body } = await HttpService.get("/sifarish/stats/by-form")

        return body.data
    }

    getList = async (query, pageFilter) => {
        const { body } = await HttpService.get("/sifarish", query, pageFilter)

        return body
    }

    saveSifarishForm = async data => {
        const { body } = await HttpService.post("/sifarish", data)

        return body.data
    }

    submitPublicSifarishForm = async data => {
        const { body } = await HttpService.post("/public/sifarish", data)

        return body.data
    }

    getById = async sifarishId => {
        const { body } = await HttpService.get(`/sifarish/${sifarishId}`)

        return body.data
    }

    getTokenImage = async sifarishToken => {
        const { body } = await HttpService.get(`/public/downloads/sifarish-tokens/${sifarishToken}`)

        return body.data
    }
}

export default (new SifarishService())
