import { isEmpty } from "lodash"
import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"
import PropTypes from "prop-types"
import React, { useState } from "react"
import { useTrans } from "../../../hooks"
import moment from "moment";

const FormFieldEngDate = ({ isPreview, value, name, onChange, errors, locale }) => {

    if (!isEmpty(errors)) {
        console.error(errors)
    }

    const defaultDate = value ? new Date(value) : new Date()

    const [date, setDate] = useState(new Date(defaultDate));

    const range = (start, end) => {
        const arr = [];
        for (let i = start; i <= end; i++) {
            arr.push(i);
        }
        return arr;
    }

    const years = range(1990, 2022);

    const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ];

    const { numberTrans } = useTrans();

    const getYear = (date) => {
        return new Date(date).getFullYear();
    }

    const getMonth = (date) => {
        const monthIndex = new Date(date).getMonth();
        return months[monthIndex];
    }

    const handleOnChange = (date) => {
        setDate(date);
        const dateString = moment(date).format("YYYY-MM-DD").replace(/-/g, "/");
        onChange({
            name, value: date ? dateString : null,
        })
    }

    return isPreview ? (
        <span className="form-input-view">{numberTrans(moment(date).format('YYYY-MM-DD').replace(/-/g, '/') || "", { locale })}</span>
    ) : (
        <DatePicker
            renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
            }) => (
                <div
                    style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                    </button>
                    <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) =>
                            changeYear(value)}
                    >
                        {years.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <select
                        value={getMonth(date)}
                        onChange={({ target: { value } }) =>
                            changeMonth(months.indexOf(value))
                        }
                    >
                        {months.map((option) => (
                            <option key={option} value={option}>
                                {option}
                            </option>
                        ))}
                    </select>

                    <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                    </button>
                </div>
            )}
            selected={date}
            onChange={handleOnChange}
            dateFormat="yyyy-MM-dd"
        />
    )
}

FormFieldEngDate.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldEngDate
