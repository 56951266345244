import React, {useState, useLayoutEffect}       from "react"
import { NavLink } from "react-router-dom"
import { useTrans } from "./../../hooks"
import { ACTIONS } from "../../config/PermissionConfig"
import { Can }     from "../permissions"

const SubHeader = () => {
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const { trans } = useTrans()

    const showDropdown = event => {
        event.preventDefault();
        setDropdownVisible(!dropdownVisible);
    };

    useLayoutEffect(()=> {
        const header = document.querySelector(".nav")
        const stickyElement = document.querySelector(".sub-header")
        const doc = document.documentElement;
        window.onscroll = ()=>{
            const top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);
            if(top > header.clientHeight){
                stickyElement.classList.add("sticky")
            }else{
                stickyElement.classList.remove("sticky")
            }
        }
    }, [])
    return (
        <div className="sub-header">
            <ul className="sub-header-lists">
                <Can action={ACTIONS.DASHBOARD.MODULES}>
                    <li className="sub-header-item">
                        <NavLink to="/admin/dashboard" activeClassName="active" className="sub-header-link dashboard">Dashboard</NavLink>
                    </li>
                </Can>

                <Can action={ACTIONS.SIFARISH.VIEW}>
                    <li className="sub-header-item">
                        <NavLink exact to="/admin/sifarish" activeClassName="active" className="sub-header-link submitted-forms">{trans("general.submitted_forms")}</NavLink>
                    </li>
                </Can>

                <Can action={ACTIONS.SIFARISH.ADD}>
                    <li className="sub-header-item">
                        <NavLink to="/admin/sifarish/forms" activeClassName="active" className="sub-header-link sifarish-forms">{trans("general.sifarish_forms")}</NavLink>
                    </li>
                </Can>

                <Can action={ACTIONS.USERS.MODULES}>
                    <li className="sub-header-item">
                        <NavLink to="/admin/users" className="sub-header-link user-management">{trans("general.user_management")}</NavLink>
                    </li>
                </Can>

                <Can action={ACTIONS.WARD_PROFILE.MODULES}>
                    <li className="sub-header-item">
                        <NavLink to="/admin/ward-profile" className="sub-header-link ward-profile">{trans("general.ward_profile")}</NavLink>
                    </li>
                </Can>

                <Can action={ACTIONS.LAYOUT.VIEW}>
                    <li className="sub-header-item">
                        <NavLink to="/admin/layout-builder" className="sub-header-link ward-profile">{trans("general.layout_manager")}</NavLink>
                    </li>
                </Can>
            </ul>
            <Can action={ACTIONS.SIFARISH.FILTER}>
                <div className="dropdown-container">
                    <div className="year-filter" onClick={showDropdown}>
                        <span>२०७६ - २०७७</span>
                    </div>
                    {dropdownVisible && (
                        <div className="dropdown-options">
                            <ul>
                                <li>
                                    <span>२०७६ - २०७७</span>
                                </li>
                                <li>
                                    <span>२०७६ - २०७७</span>
                                </li>
                                <li>
                                    <span>२०७६ - २०७७</span>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </Can>
        </div>
    )
}

export default SubHeader
