export default {
    header: {},
    footer: {},
    salutation: {},

    applicant: {},
    kitta: "",
    direction: "",
    distance: "",
    road_type: "",
    date: "",
    current_ward: ""
}
