import {
    get,
    isEmpty,
}                      from "lodash"
import {
    englishToNepaliNumber,
    nepaliNumberFormat,
    nepaliToEnglishNumber,
}                      from "nepali-number"
import { useSelector } from "react-redux"

/**
 * TODO: Refactor needed. All translation being fetched from store on every call
 */
const useTrans = () => {
    const translations = useSelector(state => state.system.translations)
    const currentLocale = useSelector(state => state.system.currentLocale)

    return {
        trans: (key, { replace, locale } = {}) => {
            let translated = get(translations[locale || currentLocale], key, key)

            if (replace && !isEmpty(replace)) {
                Object.entries(replace)
                      .forEach(([key, value]) => {
                          translated = translated.replace(key, value)
                      })
            }

            return translated
        },

        numberTrans: (number, { formatNumber, locale } = { formatNumber: false, locale: null }) => {
            number = formatNumber ? nepaliNumberFormat(number) : number

            return `${locale || currentLocale}` === "en" ? nepaliToEnglishNumber(number) : englishToNepaliNumber(number)
        },
    }
}

export default useTrans
