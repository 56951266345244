import React from "react"

const NoResult = props => {
    const { text, action } = props

    return (
        <div className="empty-state state-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="50" height="51">
                <path
                    fill="#59626A"
                    d="M36.092 34.34l12.947 12.906a2.04 2.04 0 1 1-2.882 2.89l-13.08-13.038a20.346 20.346 0 0 1-12.216 4.052C9.574 41.15.423 32 .423 20.712c0-11.29 9.15-20.44 20.438-20.44 11.287 0 20.437 9.15 20.437 20.44a20.361 20.361 0 0 1-5.206 13.628zM20.86 37.068c9.033 0 16.355-7.323 16.355-16.356 0-9.035-7.322-16.358-16.355-16.358-9.034 0-16.356 7.323-16.356 16.358 0 9.033 7.323 16.356 16.356 16.356zm1.945-9.759a1.946 1.946 0 0 1-1.934 1.952 1.946 1.946 0 0 1-1.975-1.937 1.954 1.954 0 1 1 3.909-.015l-.255.001.255-.001zM18.952 17.2H16.47c-.039 0-.016.002-.133-.01l-.031-.002-.252-.019.017-.252c.044-.672.232-1.3.562-1.888.77-1.374 1.997-2.16 3.624-2.38 1.042-.14 2.007-.045 2.917.316 2.675 1.062 3.278 4.344 1.652 6.255a17.08 17.08 0 0 1-.88.942l-.325.335c-.094.097-.17.175-.34.343-.275.275-.395.398-.534.555-.251.28-.374.63-.38 1.051-.002.219-.002.392 0 .795v.62h-2.863l-.058-.175a.354.354 0 0 1-.035-.15l-.002-.135c-.01-.693-.013-1-.015-1.386-.004-.794.34-1.451 1.005-2.125.243-.245.439-.44.878-.874.42-.416.607-.601.837-.833.727-.733.649-1.96-.15-2.514-1.004-.696-2.432-.23-2.71 1.047l-.016.083-.037.194-.25-.048v.255z"
                ></path>
            </svg>
            <p className="message">{text}</p>
            {action}
        </div>
    )
}

export default NoResult
