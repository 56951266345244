import { config }          from "../config"
import LocalStorageService from "../services/LocalStorageService"

export const getToken = async () => await LocalStorageService.get(config("app.authTokenKeyName"))
export const setToken = async token => await LocalStorageService.set(config("app.authTokenKeyName"), token)
export const clearToken = async () => await LocalStorageService.remove(config("app.authTokenKeyName"))

export const checkIfAuthenticated = state => state.auth.isAuthenticated
export const getCurrentUser = state => state.auth.currentUser
export const getCurrentMunicipality = state => state.municipality.currentMunicipality
export const getCurrentWard = state => state.municipality.currentWard
