import React from "react"
import { useTrans } from "../../../../hooks"
import {
    FormFieldName,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormFieldLocalGovernment,
    FormFieldText,
} from "../../fields"
import defaultFormData from "./formData"

const RelationshipPhoto = ({ formData, isPreview, onChange, errors }) => {
    formData = { relation: "", relative_name: "", ...formData }
    const handleOnChange = data => {
        onChange({
            ...formData,
            [data.name]: data.value,
        })
    }
    return (
        <>
            <div className="relationship-content">
                <div className="photo-frame">Photo</div>
                <div>
                    <FormFieldText
                        value={formData.relation}
                        name="relation"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder="Relation"
                    />
                </div>
                <div>
                    <FormFieldText
                        value={formData.relative_name}
                        name="relative_name"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder="Name"
                    />
                </div>
            </div>
        </>
    )
}

const RelationshipVerificationForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { ward } = staticData
    const { trans } = useTrans()

    formData = {
        ...defaultFormData,
        current_ward: ward,
        ...formData,
    }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    const handleOnChangeArray = (name, index, value) => {
        const data = formData[name]
        data[index] = value
        onChange({ ...formData, [name]: data })
    }

    const addRelations = () => {
        const relationList = formData.relations
        relationList.push({})

        onChange({ ...formData, relations: relationList })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="Relationship Verification"
                onChange={handleOnChange}
                locale="en"
            />

            <div className="body-content">
                <div className="form-text-container">
                    As per Local Government Operation Act 2074, This is to certify that{" "}
                    <FormFieldName
                        value={formData.applicant_name}
                        name="applicant_name"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={trans("general.name", { locale: "en" })}
                    />{" "}
                    permanent resident of{" "}
                    <FormFieldLocalGovernment
                        value={formData.permanent_address}
                        name="permanent_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={"permanent address"}
                    /> Ward No.
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                    />, (Former{" "}
                    <FormFieldText
                        value={formData.former_address}
                        name="former_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder="address"
                    />
                    ){" "}
                    <FormFieldText
                        value={formData.current_address}
                        name="current_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder="address"
                    />, Nepal has the following relationship to the individuals listed below:

                    <div className={`relationship-container ${isPreview ? 'preview' : ''}`}>
                        {!isPreview && 
                        <button className="btn btn-sm btn-secondary" onClick={addRelations}>
                           Add relation fields
                        </button>
                        }
                        {formData.relations &&
                            formData.relations.map((relation, index) => (
                                <RelationshipPhoto
                                    key={index}
                                    formData={relation}
                                    isPreview={isPreview}
                                    errors={errors}
                                    onChange={data => handleOnChangeArray("relations", index, data)}
                                />
                            ))}
                    </div>
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                errors={errors}
                isPreview={isPreview}
                onChange={handleOnChange}
                locale="en"
            />
        </div>
    )
}

export default RelationshipVerificationForm
