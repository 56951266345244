import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const CompanyRegistration = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = { ...defaultFormData, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="संस्था दर्ता सिफारिस"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त विषयमा{" "}
                    {trans(`municipalities.${staticData.municipality.name}`)}{" "}
                    वडा नं{" "}
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    <FormFieldPreviousAddress
                        errors={errors}
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}/>{" "}
                    स्थित रहेको{" "}
                    <FormFieldText
                        value={formData.company_name}
                        name="company_name"
                        isPreview={isPreview}
                        placeholder={trans("general.organization_name")}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    नामक संस्था दर्ता गर्नुपर्ने भएकोले सोको लागि "सिफारिस गरी
                    पाऊँ" भनी यस वडा कार्यालयमा पर्न आएको निवेदन सम्बन्धमा त्यस
                    कार्यालयको नियमानुसार गरी दिनुहुन सिफारिस गरिन्छ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                isPreview={isPreview}
                value={formData.footer}
                onChange={handleOnChange}
                errors={errors}
            />
        </div>
    )
}

export default CompanyRegistration
