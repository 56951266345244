import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldChildType,
    FormFieldDate,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const BirthDateCertifiedForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { municipality, ward } = staticData
    const { trans } = useTrans()

    formData = {
        ...defaultFormData,
        current_ward: ward,
        ...formData,
    }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="जन्म मिति प्रमाणित"
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="form-text-container">
                    निवेदक श्री{" "}
                    <FormFieldText
                        value={formData.father}
                        name="father"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        placeholder={trans("general.name")}
                    /> तथा श्रीमती{" "}
                    <FormFieldText
                        value={formData.mother}
                        name="mother"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        placeholder={trans("general.name")}
                    /> को निवेदन अनुसार उहाँहरुको{" "}
                    <FormFieldChildType
                        value={formData.child_type}
                        name="child_type"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    {trans(
                        `municipalities.${municipality.name}`,
                    )} वडा नं {" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> स्थाई ठेगाना{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> भएको{" "}
                    <FormFieldName
                        value={formData.name}
                        name="name"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        placeholder={trans("general.name")}
                    /> को जन्म मिति{" "}
                    <FormFieldDate
                        value={formData.birth_date}
                        name="birth_date"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> गते{" "}
                    <FormFieldText
                        value={formData.location}
                        name="location"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.location")}
                    /> मा भएको व्यहोरा, पेश गरेको कागजातका आधारमा प्रमाणित गरिन्छ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                errors={errors}
                isPreview={isPreview}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default BirthDateCertifiedForm
