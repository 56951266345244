import { isEmpty } from "lodash"
import PropTypes   from "prop-types"
import React       from "react"

const FormFieldLandArea = ({ isPreview, value, name, onChange, errors }) => {
    if (!isEmpty(errors)) {console.error(errors)}

    return (
        isPreview ? (
            <span className="form-input-view">{value || ""}</span>
        ) : (
            <input className="form-input form-input-md" type="text" name={name} value={value || ""} placeholder="0-0-0-0" onChange={event => onChange({ name: event.target.name, value: event.target.value })}/>
        )
    )
}

FormFieldLandArea.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldLandArea
