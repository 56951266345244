export default {
    header: {},
    footer: {},
    salutation: {},

    father: {},
    mother: {},
    child_type: "",
    permanent_address: {},
    ward: "",
    current_address: "",
    former_address: "",
    applicant_name: {},
    incomeSource: [{}],
    total_annual_income: "",
}
