export default {
    header: {},
    footer: {},
    salutation: {},

    municipality: {},
    ward: "",
    previous_address: {},
    applicant_ex_ward: "",
    applicant: "",
}
