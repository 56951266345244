import { combineReducers }     from "redux"
import HttpService             from "../../services/HttpService"
import { authReducer }         from "./auth"
import { ElectronReducer }     from "./electron"
import { municipalityReducer } from "./municipality"
import { sifarishReducer }     from "./sifarish"
import { systemReducer }       from "./system"
import { SYSTEM_REBOOT }       from "./system/type"

const appReducers = combineReducers({
    electron: ElectronReducer,
    system: systemReducer,
    sifarish: sifarishReducer,
    auth: authReducer,
    municipality: municipalityReducer,
})

const rootReducer = (state, action) => {
    if (action.type === SYSTEM_REBOOT) {
        HttpService.clearCache()
        state = undefined
    }

    return appReducers(state, action)
}

export default rootReducer
