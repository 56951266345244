import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldChildType,
    FormFieldLocalGovernment,
    FormFieldName,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const TaxClearanceCertificateForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { ward } = staticData
    const { trans } = useTrans()

    formData = {
        ...defaultFormData,
        current_ward: ward,
        ...formData,
    }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="Tax Clearance Certificate"
                onChange={handleOnChange}
                locale="en"
            />

            <div className="body-content">
                <div className="form-text-container">
                    This is to certify that{" "}
                    <FormFieldName
                        value={formData.applicant_name}
                        name="applicant_name"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={trans("general.name", { locale: "en" })}
                    />{" "}
                    <FormFieldChildType
                        value={formData.child_type}
                        name="child_type"
                        errors={errors}
                        locale="en"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    of{" "}
                    <FormFieldName
                        value={formData.father}
                        name="father"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={trans("general.name", { locale: "en" })}
                    />{" "}
                    and{" "}
                    <FormFieldName
                        value={formData.mother}
                        name="mother"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={trans("general.name", { locale: "en" })}
                    />{" "}
                    permanent resident of{" "}
                    <FormFieldLocalGovernment
                        value={formData.permanent_address}
                        name="permanent_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder={"permanent address"}
                    /> Ward No.
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                    />, (Former{" "}
                    <FormFieldText
                        value={formData.former_address}
                        name="former_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder="address"
                    />
                    ){" "}
                    <FormFieldText
                        value={formData.current_address}
                        name="current_address"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        locale="en"
                        placeholder="address"
                    />, Nepal has paid all the income and property tax in accordance with Nepalese Law. Our observation
                    of the record shows no pending dues till date.
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                errors={errors}
                isPreview={isPreview}
                onChange={handleOnChange}
                locale="en"
            />
        </div>
    )
}

export default TaxClearanceCertificateForm
