import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
    FormTable,
}                      from "../../fields"
import defaultFormData from "./formData"

const RelationshipCertificateAliveForm = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { municipality, ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    const PhotoComponent = () => {
        return <div className="photo-field">Photo</div>
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="नाता प्रमाणित प्रमाणपत्र ।"
                onChange={handleOnChange}
                errors={errors}
            />

            <div className="body-content">
                <div className="form-text-container">
                    देहायका व्यक्तिसँग देहाय बमोमिको नाता सम्बन्ध रहेकोले सो
                    नाता सम्बन्ध प्रमाणित गरी पाउँ भनी{" "}
                    {trans(`municipalities.${municipality.name}`)} वडा नं.{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    वडा कार्यालयमा मिति{" "}
                    <FormFieldDate
                        value={formData.submit_date}
                        name={"submit_date"}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    मा दिनुभएको दरखास्त बमोजिम यस कार्यालयबाट आवश्यक जाँचबुझ गरी
                    बुझ्दा तपाईको देहाय बमोजिमको व्यक्तिसँग देहाय बमोजिमको नाता
                    सम्बन्ध कायम रहेको देखिएकोले नाता प्रमाणित गरी यो प्रमाणपत्र
                    दिइएको छ ।
                </div>
                <br/>

                <FormTable
                    title=" "
                    name="relationshipDetail"
                    structure={[
                        {
                            name: "name",
                            width: "25%",
                            label: "नाता सम्बन्ध कायम गरेको व्यक्तिको नाम",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "relation",
                            width: "30%",
                            label: "नाता",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                    ]}
                    value={formData.relationshipDetail}
                    errors={errors}
                    isPreview={isPreview}
                    onChange={handleOnChange}
                />
            </div>
            <div className="section-fingerprint">
                <div>
                    <p>दरखास्तवालाको दस्तखतः–</p>
                    <p>ल्याप्चे सहिछाप</p>
                    <div className="form-fingerprint">
                        <div className="fingerprint-right">
                            <span>दायाँ</span>
                        </div>
                        <div className="fingerprint-left">
                            <span>बायाँ</span>
                        </div>
                    </div>
                </div>
                <FormFooter
                    staticData={staticData}
                    isPreview={isPreview}
                    value={formData.footer}
                    onChange={handleOnChange}
                    errors={errors}
                />
            </div>

            <div className="body-content table-with-photo print-page-break">
                <FormTable
                    title=" "
                    name="relationshipDetail"
                    structure={[
                        {
                            name: "name",
                            width: "25%",
                            label: "नाता सम्बन्ध कायम गरेको व्यक्तिको नाम",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "relation",
                            width: "30%",
                            label: "नाता",
                            component: FormFieldText,
                            defaultValue: "",
                        },
                        {
                            name: "photo",
                            width: "25%",
                            label: "फोटो",
                            component: PhotoComponent,
                        },
                    ]}
                    value={formData.relationshipDetail}
                    errors={errors}
                    isPreview={true}
                    onChange={handleOnChange}
                />
            </div>
        </div>
    )
}

export default RelationshipCertificateAliveForm
