import { get }             from "lodash"
import React, { Fragment } from "react"
import { useTrans }        from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldKitta,
    FormFieldLandArea,
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldSelect,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                          from "../../fields"
import defaultFormData     from "./formData"

const LandOwnershipLost = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = {
        ...defaultFormData,
        current_ward: get(staticData, "ward"),
        ...formData,
    }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                errors={errors}
                isPreview={isPreview}
                subject="जग्गाधनी प्रमाण पत्रको प्रतिलिपि सिफारिस सम्बन्धमा"
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="form-text-container">
                    मेरो{" "}
                    {formData.ownership_type && formData.ownership_type === "single" ? (
                        "नाममा "
                    ) : (
                        <Fragment>
                            तथा{" "}
                            <FormFieldName
                                value={formData.partner_name}
                                name="partner_name"
                                isPreview={isPreview}
                                errors={errors}
                                onChange={handleOnChange}
                                placeholder={trans("general.name")}
                            />
                            को नाममा समेत{" "}
                        </Fragment>
                    )}
                    <FormFieldSelect
                        name={"ownership_type"}
                        options={{
                            single: trans("general.single_owner"),
                            combined: trans("general.combined_owner"),
                        }}
                        isPreview={isPreview}
                        value={formData.ownership_type}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    दर्ता श्रेस्ता भएको{" "}
                    {trans(
                        `municipalities.${staticData.municipality.name}`,
                    )}{" "}
                    वडा नं{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    कित्ता नं.{" "}
                    <FormFieldKitta
                        value={formData.kitta}
                        name="kitta"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    को क्षे.फ.{" "}
                    <FormFieldLandArea
                        value={formData.area}
                        name="area"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    भएको जग्गाको जग्गाधनी लाल पुर्जा हराएकोले सोको प्रतिलिपिको
                    सिफारिस गरी पाउँ भनी वडा नं.
                    <FormFieldWard
                        value={formData.ward}
                        name="ward"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    (साविकको वडा नं.{" "}
                    <FormFieldWard
                        value={formData.previous_ward}
                        name="previous_ward"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />
                    ) बस्ने{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />
                    ले यस वडा कार्यालयमा निवेदन दिनुभएको हुँदा सो सम्बन्धमा
                    त्यहाँको नियमानुसार जग्गाधनी प्रमाण पुर्जाको प्रतिलिपि
                    उपलब्ध गराई दिनुहुन सिफारिस गरिन्छ।
                </div>
                <div className="sifarish-info-lists">
                    <div className="sifarish-info-list">
                        <span><span className="sifarish-info-list-label">निवेदक:</span> <FormFieldName
                            value={formData.applicant}
                            name="applicant"
                            isPreview={true}
                            errors={errors}
                            onChange={handleOnChange}
                            placeholder={trans("general.name")}
                        /></span>
                    </div>
                    <div className="sifarish-info-list">
                        <span>
                        <span className="sifarish-info-list-label">ना.प्र.नं. </span>{" "}
                            <FormFieldText
                                value={formData.citizenship_no}
                                name="citizenship_no"
                                placeholder={trans("general.citizenship_no")}
                                isPreview={isPreview}
                                errors={errors}
                                onChange={handleOnChange}
                            />
                        </span>
                    </div>
                    <div className="sifarish-info-list">
                        <span>
                            <span className="sifarish-info-list-label">जारी मिति</span>{" "}
                            <FormFieldDate
                                value={formData.issued_date}
                                name="issued_date"
                                placeholder={trans("general.issued_date")}
                                isPreview={isPreview}
                                errors={errors}
                                onChange={handleOnChange}
                            />
                        </span>
                    </div>
                    <div className="sifarish-info-list">
                        <span>
                            <span className="sifarish-info-list-label">पिता</span>{" "}
                            <FormFieldText
                                value={formData.father_name}
                                name="father_name"
                                placeholder={trans("general.father_name")}
                                isPreview={isPreview}
                                errors={errors}
                                onChange={handleOnChange}
                            />
                        </span>
                    </div>
                    <div className="sifarish-info-list">
                        <span>
                            <span className="sifarish-info-list-label">बाजे</span>{" "}
                            <FormFieldText
                                value={formData.grand_father_name}
                                name="grand_father_name"
                                placeholder={trans("general.grand_father_name")}
                                isPreview={isPreview}
                                errors={errors}
                                onChange={handleOnChange}
                            />
                        </span>
                    </div>
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                errors={errors}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default LandOwnershipLost
