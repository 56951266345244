import PropTypes from "prop-types"
import React, {
    Children,
    cloneElement,
    Fragment,
    useState,
}                from "react"

const ConfirmDialog = (props) => {
    const [showDialog, setShowDialog] = useState(false)

    const children = Children.map(props.children, child => cloneElement(child, { onClick: () => {setShowDialog(true)} }))

    const handleOnReject = event => {
        event.preventDefault()

        setShowDialog(false)
        props.onReject()
    }

    const handleOnConfirm = event => {
        event.preventDefault()

        setShowDialog(false)
        props.onConfirm()
    }

    return (
        <Fragment>
            {children}

            {showDialog && (
                <div className="confirm-dialog">
                    <div className="confirm-dialog__container">
                        <p>{props.confirmText}</p>

                        <div className="actions">
                            <button className="btn-outline btn-danger" onClick={handleOnConfirm}>
                                {props.positiveText}
                            </button>

                            <button className="btn-outline btn-secondary" onClick={handleOnReject}>
                                {props.negativeText}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Fragment>
    )
}

ConfirmDialog.propTypes = {
    confirmText: PropTypes.string,
    positiveText: PropTypes.string,
    negativeText: PropTypes.string,
    onConfirm: PropTypes.func,
    onReject: PropTypes.func,
}

ConfirmDialog.defaultProps = {
    confirmText: "Are you sure?",
    positiveText: "Confirm",
    negativeText: "Cancel",
    onConfirm: () => {},
    onReject: () => {},
}

export default ConfirmDialog
