export default {
    header: {},
    footer: {},
    salutation: {},

    first_party_grand_father: "",
    first_party_grand_child_type: "",
    first_party_father: "",
    first_party_mother: "",
    first_party_child_type: "",
    first_party_address: {},
    first_party_previous_address: {},
    first_party: {},
    second_party_grand_father: "",
    second_party_grand_child_type: "",
    second_party_father: "",
    second_party_mother: "",
    second_party_child_type: "",
    second_party_current_address: {},
    second_party_previous_address: {},
    second_party: {},
    marriage_date: "",
}
