import React from "react"
import FabricCanvas from "../../../components/canvas/FabricCanvas"
import FabricExport from "../../../components/canvas/FabricExport"
import FabricToolbar from "../../../components/canvas/FabricToolbar"
import {NoResult} from "../../../components/empty-state"
import {ACTIONS} from "../../../config/PermissionConfig"
import {FabricContextProvider} from "../../../context/FabricContext"
import {usePermission} from "../../../hooks"

const HeaderLayoutManager = ({headerData}) => {
    const {check} = usePermission()
    const canEdit = check(ACTIONS.LAYOUT.EDIT)
    const canView = check(ACTIONS.LAYOUT.VIEW)

    return (
        <div>
            {
                canEdit &&
                <FabricContextProvider>
                    <div className="main-container rounded" style={{"alignItems": "stretch"}}>
                        <div className="sidebar">
                            <FabricToolbar/>
                        </div>
                        <div className="content rounded" style={{"flex": "1", "overflowX": "auto"}}>
                            <div>
                                <FabricCanvas width='816' height='144' layoutType='header' data={headerData}/>
                            </div>
                        </div>
                    </div>
                    <FabricExport layoutType='header'/>
                </FabricContextProvider>
            }
            {
                !canEdit && canView && !headerData &&
                <NoResult text={"No Header Layout is set. Please contact admin."}/>
            }
            {
                !canEdit && canView && headerData &&
                <img src={headerData.dataURL} alt="header layout"/>
            }
        </div>
    )
}

export default HeaderLayoutManager
