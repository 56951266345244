import io         from "socket.io-client"
import { config } from "../config"

const endpoint = config("app.baseUrl")

export default class SocketService {
    socketIO = null

    constructor() {
        this.socketIO = io(endpoint)
    }

    socket() {
        return this.socketIO
    }
}

export const socket = (new SocketService()).socket()
