export default {
    header: {},
    footer: {},
    salutation: {},

    current_ward: "",
    previous_address: {},
    applicant: {},
    document_1: "",
    dob_date_1: "",
    document_2: "",
    dob_date_2: "",
}
