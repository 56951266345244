import React, {
    useState,
    useContext,
    useEffect,
} from "react"
import {fabric} from "fabric"
import {FabricContext} from "../../../context/FabricContext"
import {
    getActiveStyle,
    setActiveProp,
    setActiveStyle,
} from "../libs/utils"

import Icon from './../../icon/Icon'

const initialOptions = {
    width: 200,
    top: 10,
    left: 10,
    fontSize: 24,
    fontWeight: "normal",
    fontStyle: "normal",
    textAlign: "left",
    fontFamily: "arial",
    textDecoration: "normal",
    fill: "#000000",
}

const FabricTextBox = (props) => {
    const {canvas, activeObject} = useContext(FabricContext)
    const [showTools, setShowTools] = useState(false)
    const [options, setOptions] = useState(initialOptions)

    useEffect(() => {
        setShowTools(activeObject ? activeObject.get("type") === "textbox" : false)
        if (activeObject) {
            const activeOptions = {
                fontSize: getActiveStyle("fontSize", activeObject),
                fontWeight: getActiveStyle("fontWeight", activeObject),
                fontStyle: getActiveStyle("fontStyle", activeObject),
                textAlign: activeObject["textAlign"],
                fontFamily: getActiveStyle("fontFamily", activeObject),
                textDecoration: getActiveStyle("textDecoration", activeObject),
                fill: getActiveStyle("fill", activeObject),
            }
            setOptions({...options, ...activeOptions})
        }
    }, [activeObject])

    const addTextBox = () => {
        const textBox = new fabric.Textbox("Add your text here", initialOptions)
        canvas.add(textBox)
        canvas.setActiveObject(textBox);
    }

    const updateFontSize = (e) => {
        setOptions({
            ...options,
            fontSize: e.target.value,
        })
        setActiveStyle("fontSize", parseInt(e.target.value, 10), activeObject)
    }

    const updateFill = (e) => {
        setOptions({
            ...options,
            fill: e.target.value,
        })
        setActiveProp("fill", e.target.value, activeObject)
    }

    const updateTextAlign = (e) => {
        setOptions({
            ...options,
            textAlign: e.currentTarget.value,
        })
        setActiveStyle("textAlign", e.currentTarget.value.toLowerCase(), activeObject)
    }

    const updateBold = (e) => {
        const value = options.fontWeight === "bold" ? "normal" : "bold"
        setOptions({
            ...options,
            fontWeight: value,
        })
        setActiveStyle("fontWeight", value, activeObject)
    }

    const updateItalic = (e) => {
        const value = options.fontStyle === "italic" ? "normal" : "italic"
        setOptions({
            ...options,
            fontStyle: value,
        })
        setActiveStyle("fontStyle", value, activeObject)
    }

    const updateUnderline = (e) => {
        const value = options.textDecoration === "underline" ? "" : "underline"

        setOptions({
            ...options,
            textDecoration: value,
        })
        setActiveStyle("textDecoration", value, activeObject)
        setActiveStyle("underline", !!value.length, activeObject)
    }

    const numberInputRef = React.createRef();

    const  handleNumber = (type) => {
        const fontSizeInput = numberInputRef.current;
        if(type === 'increment') {
            fontSizeInput.stepUp();
        }else{
            fontSizeInput.stepDown();
        }
        setOptions({
            ...options,
            fontSize: fontSizeInput.value,
        })
        setActiveStyle("fontSize", parseInt(fontSizeInput.value, 10), activeObject)
    }

    return (
        <>
            <button className={showTools ? "btn-canvas btn-active" : "btn-canvas"} onClick={addTextBox}>
               <Icon icon="text"/>
                Text Box
            </button>
            {
                showTools &&
                <div className={"tools"}>
                     <div  className="btn-canvas" >
                             <Icon icon="text"/>
                                Text Box
                        </div>
                    <div className="tools-body">

                    <div className="text-block-item num-control">
                        <label htmlFor="fontSize">Font Size </label>
                        <input type="number"
                               className="btn-object-action num-control-input"
                               name="fontSize"
                               min="10"
                               value={options.fontSize}
                               onChange={updateFontSize}
                               ref = {numberInputRef}
                        />
                        <div className="num-control-trigger-wrap">
                            <span className="num-control-trigger num-control-trigger--up"  onClick={() => handleNumber('increment')}>
                              <Icon icon="caret"/>
                            </span>
                            <span className="num-control-trigger num-control-trigger--down" onClick={() => handleNumber('decrement')}>
                               <Icon icon="caret" />
                            </span>
                        </div>
                    </div>

                    <div className="text-block-item font-type-control" id="text-controls-additional">
                        <label htmlFor="fontSize">Font Style </label>
                        <div>

                        <button type="button"
                                style={{'background': options.fontWeight === "bold" ? 'white' : '#eee'}}
                                onMouseUp={updateBold}
                                className="btn btn-object-action">
                            <strong>B</strong>
                        </button>
                        <button type="button"
                                style={{'background': options.fontStyle === "italic" ? 'white' : '#eee'}}
                                onMouseUp={updateItalic}
                                className="btn btn-object-action" id="text-cmd-italic">
                            <em>I</em>
                        </button>
                        <button type="button"
                                style={{'background': options.textDecoration === "underline" ? 'white' : '#eee'}}
                                onMouseUp={updateUnderline}
                                className="btn btn-object-action" id="text-cmd-underline">
                            <u>U</u>
                        </button>
                        </div>

                    </div>

                        <div className="text-block-item font-align-control">
                            <label htmlFor="text-align" style={{"display": "inline-block"}}>Text Align </label>
                            <select name="textAlign"
                                    className="btn-object-action"
                                    onChange={updateTextAlign}
                                    value={options.textAlign}
                            >
                                <option value="left">Left</option>
                                <option value="center">Center</option>
                                <option value="right">Right</option>
                            </select>
                            <Icon icon="caret"/>
                        </div>
                        <div className="text-block-item color-control">
                            <label htmlFor="color">Fill</label>
                            <div className="color-control-input-wrap">
                                <input type="color" name="fill" style={{"width": "50px"}}
                                       value={options.fill}
                                       onChange={updateFill}
                                       className="btn-object-action color-control-input"/>
                            </div>
                        </div>

                    </div>
                </div>
            }

        </>
    )
}

export default FabricTextBox
