import {get} from "lodash";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {Link} from "react-router-dom";
import {auth} from "../../../helpers";
import {useTrans} from "../../../hooks";
import {fetchUserRoles} from "../../../store/actions/UserActions";
import {range} from "../../../helpers";
import {
    MUNICIPALITY_ADMIN,
    WARD_ADMIN,
    OFFICERS
} from "../../../config/Constants/UserRoles";

const UserForm = ({staticData, userData, onSubmit, errors, isEdit}) => {
    const [formData, setFormData] = useState({});
    const [userRoles, setUserRoles] = useState([]);

    const currentMunicipality = useSelector(auth.getCurrentMunicipality);
    const currentWard = useSelector(auth.getCurrentWard);
    const municipalities = useSelector(
        state => state.municipality.municipalities
    );
    const {trans, numberTrans} = useTrans();
    const dispatch = useDispatch();

    useEffect(() => {
        setFormData({
            ...userData,
            first_name: get(userData, "full_name.first_name", ""),
            last_name: get(userData, "full_name.last_name", ""),
            username: get(userData, "username", ""),
            email: get(userData, "email", ""),
            password: get(userData, "password", ""),
            role: get(userData, "role", "")
        });
    }, [userData]);

    useEffect(() => {
        dispatch(
            fetchUserRoles(roles => {
                setUserRoles(roles);
            })
        );
    }, [dispatch]);

    const handleChange = event => {
        const name = event.target.name;
        const value = event.target.value;

        setFormData({...formData, [name]: value});
    };

    const handleSubmit = event => {
        event.preventDefault();

        const data = {
            municipality_name: null,
            ward: null,
            full_name: {
                first_name: formData.first_name,
                last_name: formData.last_name
            },
            username: formData.username,
            email: formData.email,
            password: formData.password,
            role: formData.role
        };

        if (formData.role === MUNICIPALITY_ADMIN) {
            data["municipality_name"] = currentMunicipality
                ? currentMunicipality.name
                : formData.municipality;

            data["ward"] = null;
        } else {
            data["municipality_name"] = currentMunicipality
                ? currentMunicipality.name
                : formData.municipality;

            data["ward"] = currentWard || formData.ward;
        }

        onSubmit(data);
    };

    const getNumberOfWard = municipalityName => {
        const selectedMunicipality = municipalities.find(
            municipality => municipality.name === municipalityName
        );

        if (!selectedMunicipality) {
            return [];
        }

        return range(1, selectedMunicipality.no_of_wards);
    };

    return (
        <div className="form-container-small">
            <form className="form" onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="first_name" className="form-label">
                        {trans("general.name")}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="first_name"
                        name="first_name"
                        value={get(formData, "first_name", "")}
                        onChange={handleChange}
                    />
                    {errors.first_name && (
                        <p className="error-message">{errors.first_name}</p>
                    )}
                </div>

                <div className="form-group">
                    <label htmlFor="last_name" className="form-label">
                        {trans("general.last_name")}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="last_name"
                        name="last_name"
                        value={get(formData, "last_name", "")}
                        onChange={handleChange}
                    />
                    {errors.last_name && (
                        <p className="error-message">{errors.last_name}</p>
                    )}
                </div>

                <div className={`form-group ${errors.username && "has-error"}`}>
                    <label htmlFor="username" className="form-label required">
                        {trans("general.user_name")}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="username"
                        name="username"
                        value={get(formData, "username", "")}
                        onChange={handleChange}
                    />
                    {errors.username && (
                        <p className="error-message">{errors.username}</p>
                    )}
                </div>

                <div className={`form-group ${errors.email && "has-error"}`}>
                    <label htmlFor="email" className="form-label required">
                        {trans("general.email")}
                    </label>
                    <input
                        type="text"
                        className="form-control"
                        id="email"
                        name="email"
                        value={get(formData, "email", "")}
                        onChange={handleChange}
                    />
                    {errors.email && (
                        <p className="error-message">{errors.email}</p>
                    )}
                </div>

                {!isEdit && (
                    <div className={`form-group ${errors.password && "has-error"}`}>
                        <label htmlFor="password" className="form-label required">
                            {trans("general.password")}
                        </label>
                        <input
                            type="password"
                            className="form-control"
                            id="password"
                            name="password"
                            value={get(formData, "password", "")}
                            onChange={handleChange}
                        />
                        {errors.password && (
                            <p className="error-message">{errors.password}</p>
                        )}
                    </div>
                )}

                <div className={`form-group ${errors.role && "has-error"}`}>
                    <label htmlFor="role" className="form-label required">
                        {trans("general.role")}
                    </label>
                    <select
                        className={'select-dropdown'}
                        id="role"
                        name="role"
                        value={get(formData, "role", "")}
                        onChange={handleChange}
                    >
                        <option></option>
                        {userRoles.map((userRole, userRoleKey) => (
                            <option selected
                                 key={`userRole-${userRoleKey}`}
                                value={userRole}
                            >
                                {trans(`general.roles.${userRole}`)}
                            </option>
                        ))}
                    </select>
                    {errors.role && (
                        <p className="error-message">{errors.role}</p>
                    )}
                </div>

                {!currentMunicipality && formData.role && (
                    <div className="form-group">
                        <label htmlFor="municipality" className="form-label">
                            {trans(
                                "municipalities.administrative_division.municipality"
                            )}
                        </label>
                        <select
                            id="municipality"
                            name="municipality"
                            value={get(formData, "municipality", "")}
                            onChange={handleChange}
                        >
                            <option></option>
                            {municipalities.map(
                                (municipality, municipalityKey) => (
                                    <option
                                        key={`municipality-${municipalityKey}`}
                                        value={municipality.name}
                                    >
                                        {trans(
                                            `municipalities.${municipality.name}`
                                        )}
                                    </option>
                                )
                            )}
                        </select>
                        {errors.municipality && (
                            <p className="error-message">
                                {errors.municipality}
                            </p>
                        )}
                    </div>
                )}

                {!currentWard &&
                [WARD_ADMIN, OFFICERS].includes(formData.role) &&
                (currentMunicipality || formData.municipality) && (
                    <div className="form-group">
                        <label htmlFor="ward" className="form-label">
                            {trans("general.ward_no")}
                        </label>
                        <select
                            id="ward"
                            name="ward"
                            value={get(formData, "ward", "")}
                            onChange={handleChange}
                        >
                            <option></option>
                            {getNumberOfWard(
                                currentMunicipality
                                    ? currentMunicipality.name
                                    : formData.municipality
                            ).map(ward => (
                                <option key={ward} value={ward}>
                                    {numberTrans(ward)}
                                </option>
                            ))}
                        </select>
                        {errors.municipality && (
                            <p className="error-message">
                                {errors.municipality}
                            </p>
                        )}
                    </div>
                )}

                <div className="form-actions">
                    <button type="submit" className="btn-outline btn-primary">
                        {isEdit
                            ? trans("general.update")
                            : trans("general.add")}
                    </button>

                    <Link
                        to={
                            isEdit
                                ? `/admin/users/${formData.id}`
                                : "/admin/users"
                        }
                        className="btn-link"
                    >
                        {trans("general.cancel")}
                    </Link>
                </div>
            </form>
        </div>
    );
};

export default UserForm;
