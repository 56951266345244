import { capitalize } from "lodash"

// Core Functions
export const getActiveStyle = (styleName, activeObject) => {
    if (!activeObject) {
        return ""
    }
    return (activeObject.getSelectionStyles && activeObject.isEditing)
        ? (activeObject.getSelectionStyles()[styleName] || "")
        : (activeObject[styleName] || "")
}

export const setActiveStyle = (styleName, value, activeObject) => {
    if (!activeObject) {return}

    if (activeObject.setSelectionStyles && activeObject.isEditing) {
        let style = {}
        style[styleName] = value
        activeObject.setSelectionStyles(style)
        activeObject.setCoords()
    } else {
        activeObject.set(styleName, value)
    }

    activeObject.setCoords()
    activeObject.canvas.renderAll()
}

export const getActiveProp = (name, activeObject) => {
    if (!activeObject) {return ""}
    return activeObject[name] || ""
}

export const setActiveProp = (name, value, activeObject) => {
    if (!activeObject) {
        return
    }
    activeObject.set(name, value).setCoords()
    activeObject.canvas.renderAll()
}

// Getters and Setters
export const getFontSize = (canvas) => {
    return getActiveStyle("fontSize", canvas)
}

export const setFontSize = (canvas, value) => {
    setActiveStyle("fontSize", parseInt(value, 10), canvas)
}

export const getFill = (canvas) => {
    return getActiveStyle("fill", canvas)
}

export const setFill = (canvas, value) => {
    setActiveStyle("fill", value, canvas)
}

export const getTextAlign = (canvas) => {
    return capitalize(getActiveProp("textAlign"))
}

export const setTextAlign = (canvas, value) => {
    setActiveProp("textAlign", value.toLowerCase())
}

export const isBold = (canvas) => {
    return getActiveStyle("fontWeight", canvas) === "bold"
}

export const toggleBold = (canvas) => {
    setActiveStyle("fontWeight", isBold(canvas) ? "" : "bold", canvas)
}

export const isItalic = (canvas) => {
    return getActiveStyle("fontStyle", canvas) === "italic"
}

export const toggleItalic = (canvas) => {
    setActiveStyle("fontStyle", isItalic(canvas) ? "" : "italic", canvas)
}

export const isUnderline = (canvas) => {
    return getActiveStyle("textDecoration", canvas).indexOf("underline") > -1 || getActiveStyle("underline", canvas)
}

export const toggleUnderline = (canvas) => {
    let value = isUnderline()
        ? getActiveStyle("textDecoration", canvas).replace("underline", "")
        : (getActiveStyle("textDecoration", canvas) + " underline")

    setActiveStyle("textDecoration", value, canvas)
    setActiveStyle("underline", !getActiveStyle("underline", canvas), canvas)
}
