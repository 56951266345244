import { get }                       from "lodash"
import React, {
    Fragment,
    useCallback,
    useEffect,
    useLayoutEffect,
    useState,
}                                    from "react"
import {
    useDispatch,
    useSelector,
}                                    from "react-redux"
import { Link }                      from "react-router-dom"
import { Loading }                   from "../../components/loading"
import { SifarishForms }             from "../../components/sifarish-forms"
import { PermissionDeniedException } from "../../exceptions"
import { auth }                      from "../../helpers"
import { useTrans }                  from "../../hooks"
import LayoutDBService               from "../../services/layout/LayoutDBService"
import {
    createSifarishInDB,
    fetchSifarishById,
}                                    from "../../store/actions/SifarishActions"
import { notify }                    from "../../store/actions/SystemActions"
import CitizenInfoForm               from "./partials/CitizenInfoForm"

const SifarishEditForm = ({ formName, history, sifarishId }) => {
    const municipality = useSelector(auth.getCurrentMunicipality)
    const ward = useSelector(auth.getCurrentWard)
    const wardProfile = useSelector(state => state.municipality.wardProfile)

    const [formData, setFormData] = useState({})
    const [citizenData, setCitizenData] = useState({})
    const [isPreview, setIsPreview] = useState(false)
    const [validationErrors, setValidationErrors] = useState({})
    const [sifarish, setSifarish] = useState({})
    const sifarishForms = useSelector(state => state.sifarish.forms)
    const [isLoaded, setIsLoaded] = useState(false)
    const [layoutData, setLayoutData] = useState({
        header: null,
        footer: null,
    })

    const dispatch = useDispatch()
    const { trans } = useTrans()

    const fetchLayoutData = async () => {
        const header = await LayoutDBService.getLayout("header", municipality.name, ward)
        const footer = await LayoutDBService.getLayout("footer", municipality.name, ward)

        // if (!header || !footer) {
        //     dispatch(notify("Form Header and/or Footer layout has not been properly set. Please set layouts first.", 'error'))
        //     history.push('/admin/layout-builder')
        // }

        setLayoutData({ ...layoutData, header: get(header, "dataURL", null), footer: get(footer, "dataURL", null) })

        return layoutData
    }

    // eslint-disable-next-line
    useEffect(() => { const data = fetchLayoutData()}, [municipality, ward])

    useEffect(() => {
        dispatch(
            fetchSifarishById(
                sifarishId,
                sifarishData => {
                    const form = sifarishForms.find(sifarishForm => sifarishForm.name === sifarishData.form_name)
                    sifarishData.citizen && setCitizenData({
                        ...sifarishData.citizen,
                        email: sifarishData.citizen?.metadata.email,
                        contact: sifarishData.citizen?.metadata.contact,
                        address: sifarishData.citizen?.metadata.address,
                        documents: sifarishData.citizen?.metadata.documents || [],
                    })
                    setSifarish({ ...sifarishData, sifarishForm: form })
                    setFormData(sifarishData.form_data)
                    setIsLoaded(true)
                },
                error => {
                    if (error instanceof PermissionDeniedException) {
                        history.push(`/admin/permission-denied`)
                    }
                },
            ),
        )

    }, [dispatch, municipality, ward, sifarishId, sifarishForms, history])

    const handleOnSubmit = useCallback(event => {
        event.preventDefault()

        dispatch(createSifarishInDB({
            municipality,
            ward,
            form_name: sifarish.form_name,
            form_data: formData,
            citizen: citizenData,
            parent_sifarish_id: sifarish.parent_sifarish_id || sifarishId,

        }, sifarish => {
            dispatch(notify("Form successfully submitted."))

            return history.push(`/admin/sifarish/${sifarish.app_index}`)
        }, errors => {
            setValidationErrors(errors)
        }))
    }, [
        dispatch,
        municipality,
        ward,
        sifarish.form_name,
        sifarish.parent_sifarish_id,
        formData,
        citizenData,
        sifarishId,
        history])

    const handleOnSifarishFormChange = useCallback(data => {
        setFormData((prevFormData) => ({ ...prevFormData, ...data }))
    }, [])

    const handleOnCitizenFormChange = useCallback(data => {
        setCitizenData(data)
    }, [])

    useLayoutEffect(() => {
        if (isLoaded) {
            const header = document.querySelector(".nav")
            const stickyElement = document.querySelector(".sub-header")
            const doc = document.documentElement
            window.onscroll = () => {
                const top = (window.pageYOffset || doc.scrollTop) - (doc.clientTop || 0)
                if (top > header.clientHeight) {
                    stickyElement.classList.add("sticky")
                } else {
                    stickyElement.classList.remove("sticky")
                }
            }
        }
    }, [isLoaded])

    if (!isLoaded) {
        return <Loading/>
    }

    return (
        <>
            <div className="sub-header sifarish-header">
                <div className="container">
                    <Link to="/admin/sifarish" className="btn btn-link">{trans("general.back")}</Link>

                    {isPreview ? (
                        <Fragment>
                            <div className="">
                                <button className="btn-outline btn-secondary btn-submit"
                                        onClick={() => setIsPreview(false)}>{trans("general.back_to_edit")}</button>
                                <button className="btn-outline btn-success btn-submit" onClick={handleOnSubmit}>{trans(
                                    "general.confirm")}</button>
                            </div>
                        </Fragment>
                    ) : (
                        <button className="btn-outline btn-primary btn-submit" onClick={() => setIsPreview(true)}>अगाडी
                                                                                                                  बढ्नुहाेस्</button>
                    )}
                </div>
            </div>
            <div>

                <SifarishForms
                    formName={sifarish.form_name}
                    staticData={{ municipality, ward, wardProfile, layoutData }}
                    onChange={handleOnSifarishFormChange}
                    type={"edit"}
                    formData={formData}
                    isPreview={isPreview}
                    errors={validationErrors}
                />

                <CitizenInfoForm
                    formData={citizenData}
                    onChange={handleOnCitizenFormChange}
                    isPreview={isPreview}
                    errors={validationErrors}
                />

            </div>
        </>
    )
}

export default SifarishEditForm
