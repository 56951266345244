import { isEmpty }  from "lodash"
import PropTypes    from "prop-types"
import React        from "react"
import { useTrans } from "../../../hooks"

const FormFieldRelation = ({ value: inputData, onChange, name, isPreview, errors }) => {
    const relations = ["father_in_law", "mother_in_law", "daughter_in_law", "husband", "wife", "son", "daughter", "grandson", "granddaughter"]
    const { trans } = useTrans()

    if (!isEmpty(errors)) {console.error(errors)}

    const handleOnChange = event => {
        onChange({ name: name, value: event.target.value })
    }

    return (
        isPreview ? (
            <span className="form-input-view">{inputData && trans(`general.relation.${inputData}`)}</span>
        ) : (
            <select name={name} value={inputData} onChange={handleOnChange}>
                <option value=""/>
                {relations.map((relation, relationIndex) => (
                    <option key={`relation-${relationIndex}`} value={relation}>
                        {trans(`general.relation.${relation}`)}
                    </option>
                ))}
            </select>
        )
    )
}

FormFieldRelation.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldRelation
