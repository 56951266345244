export default {
    header: {},
    footer: {},
    salutation: {},

    current_ward: "",
    applicant: {},
    document_1: "",
    name_1: "",
    document_2: "",
    name_2: "",
    date: "",
}
