import {
    apiCall,
    call,
    dbCall,
}                          from "../../helpers"
import {
    StaticDataDBService,
    StaticDataService,
}                          from "../../services/static-data"
import { setMunicipality } from "../reducers/municipality/action"
import {
    setCategories,
    setForms,
}                          from "../reducers/sifarish/action"

export const fetchStaticDataFromApi = (onSuccess, onError) => dispatch => {
    dispatch(apiCall({
        onRequest: () => StaticDataService.getStaticData(),

        onSuccess: staticData => {
            const { sifarishForms, municipalities } = staticData
            const categories = [
                ...new Set(sifarishForms.map(form => form.category)),
            ]

            dispatch(setCategories(categories))
            dispatch(setForms(sifarishForms))
            dispatch(setMunicipality(municipalities))

            dispatch(saveStaticDataToDB(staticData, onSuccess))
        },

        onError: error => {
            call(onError, error)
        },
    }))
}

export const fetchStaticDataFromDB = (onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => StaticDataDBService.getStaticData(),

        onSuccess: staticData => {
            const { sifarishForms, municipalities } = staticData
            const categories = [
                ...new Set(sifarishForms.map(form => form.category)),
            ]

            dispatch(setCategories(categories))
            dispatch(setForms(sifarishForms))
            dispatch(setMunicipality(municipalities))

            call(onSuccess)
        },

        onError: error => {
            call(onError, error)
        },
    }))
}

export const saveStaticDataToDB = (staticData, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => StaticDataDBService.setStaticData(staticData),
        onSuccess, onError,
    }))
}
