import React           from "react"
import { useSelector } from "react-redux"
import { useTrans }    from "../../../hooks"

const FormCategory = ({ selectedCategory, onChange }) => {
    const categories = useSelector(state => state.sifarish.categories)
    const { trans } = useTrans()
    const activeClass = category => category === selectedCategory ? "active" : ""

    return (
        <div className="sidebar">
            <ul className="sidebar-menu">
                <li className="sidebar-menu-item">
                    <p className={`form-categories category-all ${activeClass("all")}`} onClick={() => onChange("all")}>
                        {trans("general.all")}
                    </p>
                </li>

                {categories && categories.map((category, index) => (
                    <li key={index} className="sidebar-menu-item">
                        <p className={`form-categories ${category} ${activeClass(category)}`} onClick={() => onChange(category)}>
                            {trans(`sifarish.categories.${category}`)}{" "}
                        </p>
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default FormCategory
