import { isEmpty }  from "lodash"
import PropTypes    from "prop-types"
import React        from "react"
import { useTrans } from "../../../hooks"

const FormFieldNumber = ({ isPreview, value, name, onChange, placeholder = "", errors }) => {
    const { numberTrans } = useTrans()

    if (!isEmpty(errors)) {console.error(errors)}

    return (
        isPreview ? (
            <span className="form-input-view">{numberTrans(value || "")}</span>
        ) : (
            <input className="form-input form-input-sm" type="text" placeholder={placeholder} name={name} value={value || ""} onChange={event => onChange({ name: event.target.name, value: event.target.value })}/>
        )
    )
}

FormFieldNumber.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldNumber
