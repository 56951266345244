import PropTypes         from "prop-types"
import { usePermission } from "../../hooks"

const Can = ({ action, condition, yes, no, children }) => {
    const { check } = usePermission()

    return check(action, condition) ? (yes ? yes() : children) : (no ? no() : "")
}

Can.protoTypes = {
    action: PropTypes.string,
    data: PropTypes.any,
    yes: PropTypes.func,
    no: PropTypes.func,
}

export default Can
