import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldName,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const IdentityByName = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { trans } = useTrans()
    const { municipality, ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="फरक फरक नाम प्रमाणित।"
                errors={errors}
                onChange={handleOnChange}/>

            <div className="body-content">
                <div className="form-text-container">
                    उपरोक्त सम्बन्धमा{" "}
                    {trans(`municipalities.${municipality.name}`)}{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    नं. वडा,{" "}
                    {trans(`municipalities.city.${municipality.city}`)},{" "}
                    {trans(`municipalities.district.${municipality.district}`)},{" "}
                    {trans(`municipalities.province_${municipality.province}`)} बस्ने{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    /> ले मेरो{" "}
                    <FormFieldText
                        placeholder={trans("general.document")}
                        value={formData.document_1}
                        name="document_1"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />मा नाम थर{" "}
                    <FormFieldText
                        placeholder={trans("general.name")}
                        value={formData.name_1}
                        name="name_1"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />{" "}
                    भएको र{" "}
                    <FormFieldText
                        placeholder={trans("general.document")}
                        value={formData.document_2}
                        name="document_2"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    />मा{" "}
                    <FormFieldText
                        placeholder={trans("general.name")}
                        value={formData.name_2}
                        name="name_2"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                    /> हुन गर्ई नाम फरक पर्न गएकोले उक्त दुईटै नाम भएको व्यक्ति म निवेदक{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={true}
                        onChange={handleOnChange}
                        errors={errors}
                    /> नै{" "}
                    भएकोले सोही व्यहोरा प्रमाणित गरी पाउँ भनी मिति{" "}
                    <FormFieldDate
                        placeholder={trans("general.date")}
                        value={formData.date}
                        name="date"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> गते यस कार्यालयमा निवेदन दिनु भएको हुँदा निवेदकले पेश गरेका कागजातहरुकोे आधारमा निवेदक{" "}
                    <FormFieldText
                        placeholder={trans("general.name")}
                        value={formData.name_1}
                        name="name_1"
                        isPreview={true}
                        onChange={handleOnChange}
                        errors={errors}
                    /> र{" "}
                    <FormFieldText
                        placeholder={trans("general.name")}
                        value={formData.name_2}
                        errors={errors}
                        name="name_2"
                        isPreview={true}
                        onChange={handleOnChange}
                    /> अन्य कोही नभई{" "}
                    <FormFieldName
                        value={formData.applicant}
                        name="applicant"
                        isPreview={true}
                        onChange={handleOnChange}
                        errors={errors}
                    /> नै भएकोले सोही व्यहोरा प्रमाणित गरिन्छ ।
                </div>

            </div>

            <FormFooter staticData={staticData} errors={errors} value={formData.footer} isPreview={isPreview} onChange={handleOnChange}/>
        </div>
    )
}

export default IdentityByName
