import {
    get,
    has,
    isEmpty,
}                         from "lodash"
import React, {
    useCallback,
    useEffect,
    useMemo,
    useState,
}                         from "react"
import {
    useDispatch,
    useSelector,
}                         from "react-redux"
import {
    checkIfSystemLoading,
    electron,
    isOnline,
}                         from "../../helpers"
import { FormValidation } from "../../lib/FormValidation"
import {
    completingSystemInstallation,
    initSystemInstallation,
    registerApp,
    saveAppInstalledInDB,
}                         from "../../store/actions/InstallationActions"
import { stopLoader }     from "../../store/reducers/system/action"
import { Loading }        from "../loading"
import InstallationFailed from "./partials/InstallationFailed"
import SystemOffline      from "./partials/SystemOffline"

const Installation = () => {
    const isElectronApp = useMemo(() => electron.isElectronApp(), [])
    const [installationFailed, setInstallationFailed] = useState(false)
    const [completingInstallation, setCompletingInstallation] = useState(false)
    const isLoading = useSelector(checkIfSystemLoading)
    const isSystemOnline = useSelector(isOnline)
    const dispatch = useDispatch()

    const [installationCode, setInstallationCode] = useState("")
    const [validationErrors, setValidationErrors] = useState({})
    const validationRules = useMemo(
        () => ({
            installation_code: "required",
        }),
        [],
    )

    useEffect(() => {
        if (isSystemOnline) {
            dispatch(
                initSystemInstallation(
                    () => {
                        if (!isElectronApp) {
                            dispatch(saveAppInstalledInDB())
                        }

                        dispatch(stopLoader())
                    },
                    () => {
                        setInstallationFailed(true)
                    },
                ),
            )
        }
    }, [isSystemOnline, dispatch, isElectronApp])

    useEffect(() => {
        if (completingInstallation) {
            dispatch(
                completingSystemInstallation(() => {
                    dispatch(saveAppInstalledInDB())
                }),
            )
        }
    }, [completingInstallation, dispatch])

    const handleSubmit = useCallback(
        event => {
            event.preventDefault()

            setValidationErrors({})
            const data = { installation_code: installationCode }

            const errors = new FormValidation(data, validationRules).validate()
            if (!isEmpty(errors)) {
                setValidationErrors(errors)
                return
            }

            dispatch(
                registerApp(
                    data,
                    () => {
                        setTimeout(
                            () => {
                                setCompletingInstallation(true)
                            }, 500,
                        )
                    },
                    () => {
                        setInstallationFailed(true)
                    },
                ),
            )
        },
        [installationCode, validationRules, dispatch],
    )

    const validationClass = useCallback(name => (validationErrors && has(validationErrors, name) ? "has-error" : ""), [
        validationErrors,
    ])

    if (installationFailed) {
        return <InstallationFailed/>
    }

    if (!isSystemOnline) {
        return <SystemOffline/>
    }

    if (isLoading) {
        return <Loading/>
    }

    return (
        <div className="installation-form">
            <div className="login-form-container">
                <div className="login-form-header header">
                    <h1 className="login-form-title">Installation</h1>
                </div>
                <div className="login-form-body">
                    <form onSubmit={handleSubmit}>
                        <div className={`form-group ${validationClass("installation_code")}`}>
                            <label htmlFor="username" className="mb-1">
                                Installation Code
                            </label>
                            <input
                                type="text"
                                className="form-control"
                                id="username"
                                name="installation_code"
                                value={installationCode}
                                onChange={event => setInstallationCode(event.target.value)}
                            />
                            <span className="form-error">{get(validationErrors, "installation_code")}</span>
                        </div>

                        <div className="login-action">
                            <button type="submit" value="Install" className="btn-outline btn-primary">
                                Install
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default Installation
