import React          from "react"
import { Provider }   from "react-redux"
import pkg            from "./../package.json"
import "./assets/sass/app.scss"
import { MainLayout } from "./components/layouts"
import { Router }     from "./components/router"
// import "./electron.preload"
import store          from "./store"

if (window.ELECTRON_APP) {
    document.title += ` | v${pkg.version}`
} else {
    console.info(`%cVersion: v${pkg.version}`, "color:#46c0b8;font-family:system-ui;font-size:4rem;-webkit-text-stroke: 1px black;font-weight:bold")
}

const App = () => (
    <Provider store={store}>
        <MainLayout>
            <Router/>
        </MainLayout>
    </Provider>
)

export default App
