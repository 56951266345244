import { get }         from "lodash"
import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldName,
    FormFieldPreviousAddress,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const DisabilityProof = ({ staticData, formData, onChange, isPreview, errors }) => {
    formData = {
        ...defaultFormData,
        current_ward: get(staticData, "ward"),
        ...formData,
    }
    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                errors={errors}
                subject="अपांगता सिफारिस सम्बन्धमा ।"
                onChange={handleOnChange}
            />

            <div className="body-content">
                <div className="form-text-container">
                    उपराेक्त सम्बन्धमा{" "}
                    {trans(`municipalities.${staticData.municipality.name}`)}{" "}
                    वडा नं.{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    />{" "}
                    <FormFieldPreviousAddress
                        value={formData.previous_address}
                        name="previous_address"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                    /> बस्ने{" "}
                    <FormFieldName
                        value={formData.name}
                        name="name"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />{" "}
                    <FormFieldText
                        value={formData.disability_type}
                        name="disability_type"
                        errors={errors}
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        placeholder={trans("general.disability_type")}
                    /> अपाङ्ग भएकाेले अपाङ्ग परिचय पत्र बनाउनका लागि “सिफारिस गरी
                    पाउँ” भनी यस वडा कार्यालयमा पर्न आएकाे निवेदन सम्बन्धमा
                    तहाँकाे नियमानुसार अपाङ्ग परिचय पत्रका लागि सिफारिस गरिन्छ ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                errors={errors}
                isPreview={isPreview}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default DisabilityProof
