import React           from "react"
import { useTrans }    from "../../../../hooks"
import {
    FormFieldDate,
    FormFieldLocalGovernment,
    FormFieldSelect,
    FormFieldText,
    FormFieldWard,
    FormFooter,
    FormHeader,
}                      from "../../fields"
import defaultFormData from "./formData"

const CasteMultiple = ({ staticData, formData, onChange, isPreview, errors }) => {
    const { municipality, ward } = staticData
    formData = { ...defaultFormData, current_ward: ward, ...formData }

    const { trans } = useTrans()

    const handleOnChange = ({ name, value }) => {
        onChange({ ...formData, [name]: value })
    }

    const relations = {
        father: trans("general.father"),
        mother: trans("general.mother"),
        husband: trans("general.relation.husband"),
        wife: trans("general.relation.wife"),
        son: trans("general.relation.son"),
        daughter: trans("general.relation.daughter"),
    }

    return (
        <div className="form-style">
            <FormHeader
                staticData={staticData}
                value={formData.header}
                isPreview={isPreview}
                subject="जातीगत सिफारिस"
                errors={errors}
                onChange={handleOnChange}
            />
            <div className="body-content">
                <div className="form-text-container">
                    प्रस्तुत सन्दर्भमा स्थायी ठेगाना{" "}
                    <FormFieldText
                        value={formData.permanentDistrict}
                        name="permanentDistrict"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.district_name")}
                    /> जिल्ला{" "}
                    <FormFieldLocalGovernment
                        isPreview={isPreview}
                        value={formData.permanentLocalGovernmentName}
                        name="permanentLocalGovernmentName"
                        errors={errors}
                        onChange={handleOnChange}
                    /> वडा नं.{" "}
                    <FormFieldWard
                        value={formData.permanentWard}
                        name="permanentWard"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.Ward")}
                    /> घर भई हाल {trans(`municipalities.${municipality.name}`)},
                    नगर कार्यपालिकाको{" "}
                    <FormFieldWard
                        name="current_ward"
                        value={formData.current_ward}
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> नं. वडा,{" "}
                    {trans(`municipalities.city.${municipality.city}`)},{" "}
                    {trans(`municipalities.district.${municipality.district}`)},{" "}
                    {trans(`municipalities.province_${municipality.province}`)},{" "}
                    {trans(`municipalities.country_${municipality.country}`)},{" "}
                    बस्ने{" "}
                    <FormFieldText
                        value={formData.applicant}
                        name="applicant"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    /> ले म निवेदक सहित मेराे{" "}
                    <FormFieldSelect
                        name={"relation"}
                        options={relations}
                        isPreview={isPreview}
                        value={formData.relation}
                        errors={errors}
                        onChange={handleOnChange}
                    />{" "}
                    <FormFieldText
                        value={formData.relative}
                        name="relative"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                        placeholder={trans("general.name")}
                    />{" "}
                    <FormFieldText
                        value={formData.ethnicity}
                        name="ethnicity"
                        isPreview={isPreview}
                        onChange={handleOnChange}
                        errors={errors}
                        placeholder={trans("general.ethnicity")}
                    /> कोे व्यक्ति भएकाले सोही व्यहोरा प्रमाणितका लागि सिफारिस गरी
                    पाउँ भनि मिति{" "}
                    <FormFieldDate
                        value={formData.date}
                        name="date"
                        isPreview={isPreview}
                        errors={errors}
                        onChange={handleOnChange}
                    /> गते यस कार्यालयमा निवेदन दिनु भएकोले निवेदक{" "}
                    <FormFieldText
                        value={formData.applicant}
                        name="applicant"
                        isPreview={true}
                        errors={errors}
                        onChange={() => {}}
                    /> सहित नीजकाे{" "}
                    <FormFieldSelect
                        name={"relation"}
                        options={relations}
                        isPreview={true}
                        errors={errors}
                        value={formData.relation}
                        onChange={() => {}}
                    />{" "}
                    <FormFieldText
                        value={formData.relative}
                        name="relative"
                        isPreview={true}
                        errors={errors}
                        onChange={() => {}}
                    /> नेपाल सरकारले सुचिकृत गरेको{" "}
                    <FormFieldText
                        value={formData.ethnicity}
                        name="ethnicity"
                        isPreview={true}
                        errors={errors}
                        onChange={() => {}}
                    /> को व्यक्ति भएको व्यहोरा सिफारिस साथ
                    अनुरोध गरिन्छ ।
                </div>
            </div>

            <FormFooter
                staticData={staticData}
                value={formData.footer}
                isPreview={isPreview}
                errors={errors}
                onChange={handleOnChange}
            />
        </div>
    )
}

export default CasteMultiple
