import React from "react"

const InlineLoader = () => (
    <div className="inline-loader">
        <div className="item-1"></div>
        <div className="item-2"></div>
        <div className="item-3"></div>
        <div className="item-4"></div>
        <div className="item-5"></div>
    </div>
)

export default InlineLoader
