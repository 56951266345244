import HttpService from "../HttpService"

class StaticDataService {
    pingServer = async () => {
        await HttpService.get("/utilities/ping")
    }

    getStaticData = async () => {
        const { body } = await HttpService.get("/static-data")

        return body.data
    }

    getTranslation = async () => {
        const { body } = await HttpService.get("/utilities/trans.json")

        return body
    }

    getUserRoles = async () => {
        const { body } = await HttpService.get("/static-data/role-list")

        return body.data
    }
}

export default (new StaticDataService())
