import React                  from "react"
import {
    useDispatch,
    useSelector,
}                             from "react-redux"
import { removeNotification } from "../../store/reducers/system/action"
import Toaster                from "./Toaster"

const Notifications = () => {
    const notifications = useSelector(state => state.system.notifications)
    const dispatch = useDispatch()

    const sortByTime = (current, next) => next.time - current.time

    return (
        <div className="no-print notification-container">
            {notifications.sort(sortByTime)
                          .map((notification, index) => (
                              <Toaster key={index} {...notification} onRemove={() => dispatch(removeNotification(index))}/>
                          ))}
        </div>
    )
}

export default Notifications
