import {
    apiCall,
    call,
    log,
}                                  from "../../helpers"
import { DataDumperService }       from "../../services/data-dumper"
import { fetchWardProfileFromDB }  from "./MunicipalityActions"
import { fetchSifarishListFromDB } from "./SifarishActions"
import { fetchLayoutFromDB }       from "./LayoutAction"

export const dumpData = (municipalityData, messageLogger, onSuccess, onError) => async (dispatch, getState) => {
    await new Promise(resolve => {
        dispatch(syncSifarish(municipalityData, (message, status) => {
            messageLogger({
                label: message,
                status,
            }, "forms")
        }, resolve, resolve))
    })

    await new Promise(resolve => {
        dispatch(syncWardProfile(municipalityData, (message, status) => {
            messageLogger({
                label: message,
                status,
            }, "profile")
        }, resolve, resolve))
    })

    call(onSuccess)
}

export const syncSifarish = ({ municipality, ward }, messageLogger, onSuccess, onError) => async dispatch => {
    try {
        messageLogger("Reading Sifarish Forms", "pending")
        const sifarishListInDB = await new Promise(resolve => {
            dispatch(fetchSifarishListFromDB({}, resolve, resolve))
        })

        messageLogger("Sending Sifarish Forms to Server", "pending")
        for (let sifarish of sifarishListInDB) {
            const data = {
                municipality_name: municipality.name,
                ward: ward,
                type: "sifarish",
                dumped_data: { ...sifarish },
            }
            await new Promise((resolve, reject) => {
                dispatch(dumpDataToApi(data, resolve, error => {
                    dispatch(log(error))
                    reject(error)
                }))
            })
        }
        messageLogger("Sifarish Forms Sync Completed", "success")

        call(onSuccess)
    } catch (error) {
        dispatch(log(error))
        messageLogger("Sifarish Forms Sync Failed", "error")
        call(onError)
    }
}

export const syncWardProfile = ({ municipality, ward }, messageLogger, onSuccess, onError) => async dispatch => {
    try {
        messageLogger("Reading Ward Profile", "pending")
        const wardProfileInDB = await new Promise(resolve => {
            dispatch(fetchWardProfileFromDB(municipality.name, ward, resolve, resolve))
        })

        messageLogger("Sending Ward Profile to Server", "pending")
        const data = {
            municipality_name: municipality.name,
            ward: ward,
            type: "ward_profile",
            dumped_data: { ...wardProfileInDB },
        }
        await new Promise((resolve, reject) => {
            dispatch(dumpDataToApi(data, resolve, error => {
                dispatch(log(error))
                reject(error)
            }))
        })
        messageLogger("Ward Profile Sync Completed", "success")

        call(onSuccess)
    } catch (error) {
        dispatch(log(error))
        messageLogger("Ward Profile Sync Failed", "error")
        call(onError)
    }
}

export const dumpDataToApi = (data, onSuccess, onError) => dispatch => {
    dispatch(apiCall({
        onRequest: () => DataDumperService.upload(data),
        onSuccess, onError,
    }))
}

export const dumpNewData = (municipalityData, messageLogger, onSuccess, onError) => async (dispatch, getState) => {
    await new Promise(resolve => {
        dispatch(syncAllData(municipalityData, (message, status) => {
            messageLogger({
                label: message,
                status,
            }, "forms")
        }, resolve, resolve))
    })

    call(onSuccess)
}

export const dumpNewDataToApi = (data, onSuccess, onError) => dispatch => {
    dispatch(apiCall({
        onRequest: () => DataDumperService.uploadNewData(data),
        onSuccess, onError,
    }))
}

export const syncAllData = ({ municipality, ward }, messageLogger, onSuccess, onError) => async dispatch => {
    try {
        messageLogger("Reading Sifarish Forms,WardProfile,Layout", "pending")
        const sifarishListInDB = await new Promise(resolve => {
            dispatch(fetchSifarishListFromDB({}, resolve, resolve))
        })
        const wardProfileInDB = await new Promise(resolve => {
            dispatch(fetchWardProfileFromDB(municipality.name, ward, resolve, resolve))
        })
        const layoutInDb = await new Promise(resolve => {
            dispatch(fetchLayoutFromDB(municipality.name, ward, resolve, resolve))
        })


        messageLogger("Sending Sifarish Forms,Ward,Layout to Server", "pending")
        const data = {
            municipality_name: municipality.name,
            ward: ward,
            dumped_data: { "sifarish_form": sifarishListInDB, "ward_profile": wardProfileInDB, "layout": layoutInDb },
        }

        await new Promise((resolve, reject) => {
            dispatch(dumpNewDataToApi(data, resolve, error => {
                dispatch(log(error))
                reject(error)
            }))

        })
        messageLogger("Sifarish Forms,Ward,Layout Sync Completed", "success")

        call(onSuccess)
    } catch (error) {
        dispatch(log(error))
        messageLogger("Sifarish Forms,Ward,Layout Sync Failed", "error")
        call(onError)
    }
}
