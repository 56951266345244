import axios from "axios"
import React, { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { apiCall, auth } from "../../helpers"
import { clearLoginValidationErrors, loginStart, loginStop, setCurrentUser } from "../../store/reducers/auth/action"
import { setToken } from "../../helpers/AuthHelper"
import { setCurrentMunicipality, setCurrentWard } from "../../store/reducers/municipality/action"
import { UsersDBService } from "../../services/users"

const Callback = () => {
    const { search } = useLocation()
    const queryParams = new URLSearchParams(search)
    const dispatch = useDispatch()

    useEffect(() => {
        const endpoint = `${process.env.REACT_APP_API_URL}/api/sso/sso-login`
        const instance = axios.create({
            withCredentials: true,
        })
        const makeAuth = async dispatch => {
            try {
                const res = await instance.post(endpoint, {
                    code: queryParams.get("code"),
                    state: queryParams.get("state"),
                })
                dispatch(loginStart())
                dispatch(
                    apiCall({
                        onRequest: () => {
                            return res?.data?.data?.user
                        },
                        onSuccess: async user => {
                            dispatch(setCurrentUser(user))
                            dispatch(setToken(user.token))
                            dispatch(setCurrentMunicipality(user.municipality))
                            dispatch(setCurrentWard(user.ward))
                            await auth.setToken(user.token)
                            await UsersDBService.setCurrentUser(user)
                            dispatch(clearLoginValidationErrors())
                            dispatch(loginStop())
                            window.location = res?.data?.data?.target_url ?? "/admin/users/add"
                        },
                        onError: err => {
                            console.log(err)
                        },
                    }),
                )
            } catch (error) {
                console.log(error)
            }
        }
        makeAuth(dispatch)
    }, [])

    return (
        <>
            {" "}
            <h4>Redirecting...</h4>
        </>
    )
}

export default Callback
