import {
    useCallback,
    useEffect,
    useMemo,
    useReducer,
    useRef,
} from "react"

const defaultOptions = {
    fireOnMount: true,
    takeEvery: false,
}

const dataFetchReducer = () => {
    return (state, action) => {
        switch (action.type) {
            case "DATA_FETCH_INIT":
                if (state.loading === true) {
                    return state
                }

                return { ...state, loading: true }

            case "DATA_FETCH_SUCCESS":
                return { ...state, loading: false, error: null, data: action.payload }

            case "DATA_FETCH_FAILURE":
                return { ...state, loading: false, error: action.payload }

            case "CHANGE_DATA":
                return {
                    ...state,
                    loading: action.payload.stopLoading ? false : state.loading,
                    error: null,
                    data: action.payload.data,
                }

            default:
                throw new Error(`Invalid reducer type`)
        }
    }
}

const useData = (asyncFetch, initialData = {}, options = {}) => {
    const { fireOnMount, takeEvery } = { ...defaultOptions, ...options }

    const fnStartTime = useRef(0)

    // https://overreacted.io/making-setinterval-declarative-with-react-hooks/#refs-to-the-rescue
    const savedAsyncFunc = useRef(asyncFetch)
    useEffect(() => {
        savedAsyncFunc.current = asyncFetch
    })

    const [state, dispatch] = useReducer(dataFetchReducer(), {
        loading: fireOnMount,
        error: null,
        data: initialData || null,
    })

    const fetchData = useCallback(
        async newAsyncFetch => {
            const startTime = new Date().getTime()
            fnStartTime.current = startTime
            dispatch({ type: "DATA_FETCH_INIT" })

            try {
                let data

                if (newAsyncFetch) {
                    data = await newAsyncFetch()
                } else {
                    data = await savedAsyncFunc.current()
                }

                if (takeEvery || fnStartTime.current === startTime) {
                    dispatch({ type: "DATA_FETCH_SUCCESS", payload: data })
                }
            } catch (error) {
                dispatch({ type: "DATA_FETCH_FAILURE", payload: error })
            }
        }, [takeEvery])

    useEffect(() => {
        fireOnMount && fetchData()
    }, [fireOnMount, fetchData])

    const fireFetch = useCallback(newAsyncFetch => {
        fetchData(newAsyncFetch)
    }, [fetchData])

    const setData = useCallback((newData, stopLoading = false) => {
        let data

        if (typeof newData === "function") {
            data = newData(state.data)
        } else {
            data = newData
        }

        dispatch({ type: "CHANGE_DATA", payload: { data, stopLoading } })
    }, [state.data])

    return useMemo(() => ({ ...state, fireFetch, setData }), [state, fireFetch, setData])
}

export default useData
