import PropTypes           from "prop-types"
import React, { Fragment } from "react"
import { useTrans }        from "../../../hooks"
import {
    FormFieldLocalGovernment,
    FormFieldText,
    FormFieldWard,
}                          from "./index"

// noinspection FunctionWithMoreThanThreeNegationsJS
const FormFieldPreviousAddress = ({ isPreview, value, name, onChange, errors, locale }) => {
    const { trans } = useTrans()

    const handleOnChange = (event) => {
        onChange({ name, value: { ...value, [event.name]: event.value } })
    }

    // noinspection OverlyComplexBooleanExpressionJS
    if (isPreview && !value.address && !value.local_government_level && !value.ward) {
        return ""
    }

    return (
        <Fragment>
            ({trans("general.Previous Address", { locale })}{" "}
            {!isPreview || value.address ? (
                <FormFieldText
                    value={value.address || ""}
                    name={"address"}
                    isPreview={isPreview}
                    onChange={handleOnChange}
                    placeholder={trans("general.Previous Address", { locale })}
                    errors={errors}
                />
            ) : ""}
            {" "}
            {!isPreview || value.local_government_level ? (
                <FormFieldLocalGovernment
                    value={value.local_government_level || {}}
                    name={"local_government_level"}
                    isPreview={isPreview}
                    onChange={handleOnChange}
                    placeholder="test"
                    locale={locale}
                    errors={errors}
                    isPrevious={true}
                />
            ) : ""}{" "}
            {!isPreview || value.ward ? (
                <Fragment>
                    {trans("general.ward_no", { locale })}{" "}
                    <FormFieldWard
                        value={value.ward || ""}
                        name={"ward"}
                        errors={errors}
                        isPreview={isPreview}
                        locale={locale}
                        onChange={handleOnChange}
                    />
                </Fragment>
            ) : ""})
        </Fragment>
    )
}

FormFieldPreviousAddress.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.object.isRequired,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormFieldPreviousAddress
