import React, { useState, useContext, useEffect } from "react"
import { fabric }            from "fabric"
import { FabricContext }          from "../../../context/FabricContext"
import {
    getActiveStyle,
    setActiveProp,
    setActiveStyle,
} from "../libs/utils"

import Icon from './../../icon/Icon'

const initialOptions = {
    left: 10,
    top: 10,
    stroke: "black",
    hoverCursor: 'default',
    strokeWidth: 2,
}

const FabricLine = () => {
    const { canvas, activeObject } = useContext(FabricContext)
    const [showTools, setShowTools] = useState(false)
    const [options, setOptions] = useState(initialOptions)

    const addLine = () => {
        const line = new fabric.Line([100, 100, 300, 100], initialOptions)
        canvas.add(line)
        canvas.setActiveObject(line)
    }

    const updateStroke = (e) => {
        setOptions({
            ...options,
            stroke: e.target.value,
        })
        setActiveProp("stroke", e.target.value, activeObject)
    }

    const updateStrokeWidth = (e) => {
        setOptions({
            ...options,
            strokeWidth: e.target.value,
        })
        setActiveStyle('strokeWidth', parseInt(e.target.value, 10), activeObject);
    }

    useEffect(() => {
        setShowTools(activeObject ? activeObject.get("type") === "line" : false)
        if (activeObject) {
            const activeOptions = {
                stroke: getActiveStyle("stroke", activeObject),
                strokeWidth: getActiveStyle("strokeWidth", activeObject),
            }
            setOptions({ ...options, ...activeOptions })
        }
    }, [activeObject])

    const strokeWidthRef = React.createRef();

    const  handleNumber = (type) => {
        const strokeWidthInput = strokeWidthRef.current;
        if(type === 'increment') {
            strokeWidthInput.stepUp();
        }else{
            strokeWidthInput.stepDown();
        }
        setOptions({
            ...options,
            strokeWidth:strokeWidthInput.value,
        })
        setActiveStyle('strokeWidth', parseInt(strokeWidthInput.value, 10), activeObject);
    }


    return (
        <>
            <button className={showTools ? "btn-canvas btn-active" : "btn-canvas"} onClick={addLine}>
                <Icon icon={"line"}/> Line</button>
            {
                showTools &&
                <div className="tools">
                    <span className={"btn-canvas"}>
                          <Icon icon={"line"}/> Line
                    </span>
                    <div className="tools-body">
                        <div className="text-block-item num-control">
                            <label htmlFor="strokeWidth">Stroke Width </label>
                            <input type="number"
                                   className="btn-object-action num-control-input"
                                   name="strokeWidth"
                                   min="1"
                                   value={options.strokeWidth}
                                   onChange={updateStrokeWidth}
                                   ref={strokeWidthRef}
                            />
                            <div className="num-control-trigger-wrap">
                                <span className="num-control-trigger num-control-trigger--up" onClick={() => handleNumber('increment')}>
                                  <Icon icon="caret"/>
                                </span>
                                    <span className="num-control-trigger num-control-trigger--down" onClick={() => handleNumber('decrement')}>
                                   <Icon icon="caret" />
                                </span>
                            </div>
                        </div>
                        <div className="text-block-item color-control">
                            <label htmlFor="color">Stroke </label>
                            <div className="color-control-wrap">
                                <input type="color" name="stroke" style={{ "width": "50px" }}
                                       value={options.stroke}
                                       onChange={updateStroke}
                                       className="btn-object-action color-control-input"/>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
}

export default FabricLine
