import PouchDB from "pouchdb"

class MunicipalityDBService {
    constructor() {
        this.db = new PouchDB("municipality", { adapter: "idb" })
    }

    async getWardProfile(municipality, ward) {
        return await this.db.get(`ward_profile_${municipality}_ward_${ward}`)
    }

    async setWardProfile(municipality, ward, profile) {
        const data = {
            ...profile,
            _id: `ward_profile_${municipality}_ward_${ward}`,
        }

        try {
            const oldProfile = await this.getWardProfile(municipality, ward)
            data["_rev"] = oldProfile._rev
        } catch (error) {
            console.error(`No ward profile in database for ${municipality} ${ward}.`, error)
        }

        return await this.db.put(data)
    }
}

export default (new MunicipalityDBService())
