import React from "react"

const OfflineState = () => {
    return (
        <div className="state-container">
            <span className="offline-state-icon"></span>
            <p className="message">You need to be online.</p>
        </div>
    )
}

export default OfflineState
