import Bcrypt                   from "bcryptjs"
import PouchDB                  from "pouchdb"
import { LoginFailedException } from "../../exceptions"
import { generateJWT }          from "../../helpers"

class AuthDBService {
    constructor() {
        this.db = new PouchDB("auth_users", { adapter: "idb" })
    }

    async login({ username, password }) {
        const user = await this.getAuthUserByUsername(username)

        if (!user) {
            throw new LoginFailedException("Invalid login.")
        }

        const passwordMatched = await Bcrypt.compare(password, user.password)
        if (!passwordMatched) {
            throw new LoginFailedException("Invalid login.")
        }

        const token = generateJWT({
            id: user.id,
            email: user.email,
        })

        return { ...user, token }
    }

    /**
     * TODO: Need refactoring
     * @param username
     * @return {Promise<array>}
     */
    async getAuthUserByUsername(username) {
        const users = await this.getAllAuthUsers()

        return users.find(user => user.username === username)
    }

    async getAllAuthUsers() {
        const data = await this.db.allDocs({ include_docs: true })

        if (!data) {
            return []
        }

        return data.rows.map(row => row.doc || null)
    }

    async getAuthUserById(id) {
        return await this.db.get(`auth_user_${id}`)
    }

    async saveAuthUsers(users) {
        const usersToSave = []

        for (const user of users) {
            let userInDB = null
            const dbId = `auth_user_${user.id}`

            try {
                userInDB = await this.getAuthUserById(user.id)
            } catch (error) {
                console.log(error)
            }

            usersToSave.push({
                ...user,
                _rev: userInDB ? userInDB._rev : null,
                _id: dbId,
            })
        }

        return await this.db.bulkDocs(usersToSave)
    }
}

export default (new AuthDBService())
