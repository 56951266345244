import PouchDB from "pouchdb"

class UsersDBService {
    constructor() {
        this.db = new PouchDB("users", { adapter: "idb" })
    }

    async getCurrentUser() {
        return await this.db.get("current_logged_in_user")
    }

    async removeCurrentUser() {
        let currentUser = null

        try {
            currentUser = await this.getCurrentUser()
        } catch (error) {
            console.error("No current user found in DB.", error)
            return
        }

        this.db.remove(currentUser._id, currentUser._rev)
    }

    async setCurrentUser(user) {
        const data = {
            ...user,
            _id: "current_logged_in_user",
        }

        try {
            const currentUser = await this.getCurrentUser()
            data["_rev"] = currentUser._rev
        } catch (error) {
            console.error("No current user found in DB.", error)
        }

        return await this.db.put(data, { force: true })
    }
}

export default (new UsersDBService())
