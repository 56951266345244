import HttpService from "../HttpService"

class AuthService {
    login = async credentials => {
        const { body } = await HttpService.post("/auth/login", credentials)

        return body.data
    }

    fetchCurrentUser = async () => {
        const { body } = await HttpService.get("/auth/current-user")

        return body.data
    }
}

export default (new AuthService())
