import {ChangePassword} from './../../pages/profile'

export default prefix => ([
    {
        auth: true,
        path: `${prefix}/change-password`,
        exact: true,
        component: ChangePassword,
        hasSubHeader: true,
    }
])