import { createAction } from "redux-actions"
import * as types       from "./type"

export const setCurrentUser = createAction(types.AUTH_SET_USER)
export const setToken = createAction(types.AUTH_SET_TOKEN)

export const loginStart = createAction(types.AUTH_LOGIN_START)
export const loginStop = createAction(types.AUTH_LOGIN_STOP)
export const setLoginValidationErrors = createAction(types.AUTH_LOGIN_SET_VALIDATIONS_ERRORS)
export const clearLoginValidationErrors = createAction(types.AUTH_LOGIN_CLEAR_VALIDATIONS_ERRORS)
