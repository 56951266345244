export default {
    header: {},
    footer: {},
    salutation: {},

    current_ward: "",
    address: "",
    applicant: {},
    date: "",
    self_citizenship_no: "",
    self_citizenship_issued_date: "",
    self_district: "",
    father_name: "",
    father_citizenship_no: "",
    father_citizenship_issued_date: "",
    father_district: "",
    grand_father_name: "",
    grand_father_citizenship_no: "",
    grand_father_citizenship_issued_date: "",
    grand_father_district: "",
}
