import {distanceInWordsToNow} from "date-fns"
import {get} from "lodash"
import React, {useState} from "react"
import {Link} from "react-router-dom"
import {Can} from "../../components/permissions"
import {
    MUNICIPALITY_ADMIN,
    SUPER_ADMIN,
} from "../../config/Constants/UserRoles"
import {ACTIONS} from "../../config/PermissionConfig"
import {useTrans} from "../../hooks"
import SifarishHistory from "./SifarishHistory"
import Icon from "../../components/icon/Icon"

const SifarishListRow = (props) => {
    const {sifarish, pagination, index, currentUser} = props
    const {trans, numberTrans} = useTrans()
    const [showSifarishHistory, toggleSifarishHistory] = useState(false)

    const sn = (((pagination.page) - 1) * pagination.per_page) + (index)

    const toggleHistory = () => {
        toggleSifarishHistory(!showSifarishHistory)
    }

    return (
        <>
            <tr className={showSifarishHistory ? 'tr-main-row' : ' '}>
                < td> {numberTrans(sn)}.</td>
                <td>{numberTrans(get(sifarish, "form_data.header.date", ""))}</td>
                <td>
                    <div className="items-center">
                        <div>
                            <span style={{display: "block"}}>{trans(`sifarish.forms.${sifarish.form_name}`)}</span>
                            <em style={{display: "block"}}>({trans("general.latest_update")})</em>
                        </div>
                        {
                            sifarish.children.length > 0 && (
                                <button
                                    onClick={toggleHistory}


                                    className={showSifarishHistory ? 'btn-link btn-tooltip btn-link--active' : 'btn-link btn-tooltip'}
                                >
                                    <span className="btn-tooltip-text">
                                           {trans("general.view_old_versions")}
                                    </span>
                                    <Icon icon="history"/>
                                </button>
                            )
                        }
                    </div>

                </td>
                <td>{get(sifarish, "form_data.applicant.name", "")}</td>
                {currentUser.role === SUPER_ADMIN && (
                    <td>
                        {sifarish.municipality_name
                            ? trans(`municipalities.${sifarish.municipality_name}`)
                            : "-"}
                    </td>
                )}
                {(currentUser.role === SUPER_ADMIN ||
                    currentUser.role === MUNICIPALITY_ADMIN) && (
                    <td>{sifarish.ward ? numberTrans(sifarish.ward) : "-"}</td>
                )}
                <td>{distanceInWordsToNow(new Date(sifarish.created_at))} ago{sifarish.by_citizen ? ' (By Citizen)' : '' }</td>
                <td>
                    <Can action={ACTIONS.SIFARISH.VIEW}>
                        <Link
                            style={{display: "block"}}
                            to={`/admin/sifarish/${sifarish.app_index}`}
                            className="btn-link btn-view-form"
                        >
                            {trans("general.view_form")}
                        </Link>
                    </Can>
                </td>
            </tr>
            {
                showSifarishHistory &&
                (
                    sifarish.children.map((sifarishHistory, index) => {
                        return (
                            <SifarishHistory
                                length={sifarish.children.length}
                                key={index}
                                index={index}
                                sn={sn}
                                sifarish={sifarishHistory}
                                currentUser={currentUser}
                            />
                        )
                    })
                )
            }
        </>
    )
}

export default SifarishListRow


