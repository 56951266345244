import {
    apiCall,
    call,
    dbCall,
    dispatcherWithLoader,
    log,
}                              from "../../helpers"
import {
    ElectronApiService,
    ElectronDBService,
}                              from "../../services/electron"
import {
    setAppInfo,
    setAppInstalled,
}                              from "../reducers/electron/action"
import { clearLoaderMessages } from "../reducers/system/action"
import {
    syncAuthUserList,
    syncSifarishListsForElectron,
    syncStaticData,
    syncTranslations,
    syncWardProfileForElectron,
}                              from "./DatabaseSyncActions"

export const registerApp = (data, onSuccess, onFailed) => async dispatch => {
    dispatch(apiCall({
        onRequest: () => ElectronApiService.registerApp(data),
        onSuccess: async response => {
            const { municipality_name: municipalityName, ward } = response

            dispatch(setAppInfo({ municipalityName, ward }))
            dispatch(saveAppInfoInDB({ municipalityName, ward }))

            call(onSuccess, response)
        },
        onError: error => {
            dispatch(log("Error occurred while registering app with installation code:", data, error))
            call(onFailed, error)
        },
    }))
}

export const saveAppInfoInDB = ({ municipalityName, ward }, onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => ElectronDBService.setKey("app_info_municipality", municipalityName),
    }))

    dispatch(dbCall({
        onRequest: () => ElectronDBService.setKey("app_info_ward", ward),
    }))

    call(onSuccess)
}

export const fetchAppInstallationInfo = (onSuccess, onFailed) => async dispatch => {
    dispatch(dbCall({
        onRequest: () => ElectronDBService.getByKey("is_installed"),
        onSuccess: ({ value }) => {
            dispatch(setAppInstalled(value))
            call(onSuccess, value)
        },
        onError: error => {
            dispatch(log("Error occurred while fetching installation information.", error))
            dispatch(setAppInstalled(false))
            call(onSuccess, false)
        },
    }))
}

export const initSystemInstallation = (onSuccess, onFailed) => async dispatch => {
    const loadingMessages = {
        translationMessage: "Downloading translations.",
        staticDataMessage: "Downloading static data.",
    }

    const clearAllLoaderMessages = () => {
        dispatch(clearLoaderMessages(Object.values(loadingMessages)))
    }

    try {
        await Promise.all([
            dispatcherWithLoader(dispatch, loadingMessages.translationMessage, syncTranslations),
            dispatcherWithLoader(dispatch, loadingMessages.staticDataMessage, syncStaticData),
        ])

        clearAllLoaderMessages()
        call(onSuccess)
    } catch (error) {
        dispatch(log("System installation initialization failed.", error))
        clearAllLoaderMessages()
        call(onFailed, error)
    }
}

export const completingSystemInstallation = (onSuccess, onError) => async dispatch => {
    const loadingMessages = {
        syncAuthUsersMessage: "Downloading users list.",
        syncWardProfileMessage: "Downloading ward profile.",
        syncSifarishMessage: "Downloading sifarish.",
    }

    try {
        await Promise.all([
            dispatcherWithLoader(dispatch, loadingMessages.syncAuthUsersMessage, syncAuthUserList),
            dispatcherWithLoader(dispatch, loadingMessages.syncWardProfileMessage, syncWardProfileForElectron),
            dispatcherWithLoader(dispatch, loadingMessages.syncSifarishMessage, syncSifarishListsForElectron),
        ])

        dispatch(clearLoaderMessages(Object.values(loadingMessages)))
        call(onSuccess)
    } catch (error) {
        dispatch(log("Error occurred while completing installation.", error))
        dispatch(clearLoaderMessages(Object.values(loadingMessages)))
        call(onError, error)
    }
}

export const saveAppInstalledInDB = (onSuccess, onError) => dispatch => {
    dispatch(dbCall({
        onRequest: () => ElectronDBService.setKey("is_installed", true),
        onSuccess: () => {
            dispatch(setAppInstalled(true))
            call(onSuccess)
        }, onError,
    }))
}
