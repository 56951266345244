import { isEmpty } from "lodash"
import PropTypes   from "prop-types"
import React       from "react"

const FormObligation = ({ value: inputData, onChange, isPreview, errors }) => {
    inputData = inputData || ""

    if (!isEmpty(errors)) {console.error(errors)}

    const handleOnChange = event => {
        onChange({ name: "obligation", value: event.target.value })
    }

    return (
        <p style={{ marginTop: "40px" }}>
            <span>बाेधार्थः</span>

            <span className="d-block">{isPreview ? (
                <span className="form-input-view form-input-view-lg">{inputData || " "}</span>) : (
                <input className="form-input from-input-lg" type="text" name="{name}" value={inputData} onChange={handleOnChange}/>
            )} - जानकारीको लागि |</span>
        </p>
    )
}

FormObligation.propTypes = {
    isPreview: PropTypes.bool.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
}

export default FormObligation
