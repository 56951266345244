export default {
    header: {},
    salutation: {},
    footer: {},

    current_ward: "",
    submit_date: "",
    relationshipDetail: [{}],
    village: "",
    ward: "",
    city: "",
    district: "",
    province: "",
}
