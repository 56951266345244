import HttpService from "../HttpService"

class LayoutService {
    fetchLayout = async (municipality, ward) => {
        const { body } = await HttpService.get(`/municipalities/${municipality}/wards/${ward}/layout`)    
        return body.data
    }
    
    saveLayout = async (layout, { municipality, ward }) => {
        const { body } = await HttpService.post(`/municipalities/${municipality}/wards/${ward}/layout`, layout)
    
        return body
    }
    
}

export default (new LayoutService())
